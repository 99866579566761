import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import MainPage from "../pages/IndividualForms/Main/Main";
import EntityPage from "../pages/EntityPage/EntityPage";
import DigilockerDetails from "../pages/DigilockerDetails/DigilockerDetails";
import MainNonIndividual from "../pages/NonIndividualPages/Main/MainNonIndividual";

const OnboardingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/onboarding" />} />
      <Route path="/onboarding" element={<MainPage />} />
      {/* <Route path="/entity" element={<EntityPage />} /> */}
      <Route path="/digilocker-webhook" element={<DigilockerDetails />} />
      <Route path="/nonIndividual" element={<MainNonIndividual />} />
    </Routes>
  );
};

export default OnboardingRoutes;
