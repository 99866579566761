import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { getWeekDateRange } from "../../utils/getWeekDateRange";

interface HistoricalData {
  [key: string]: {
    [category: string]: number;
  };
}

interface Props {
  data: {
    historicalData: HistoricalData | undefined;
  };
  type: "Weekly" | "Yearly";
}

const BarChartData: React.FC<Props> = ({ data, type }) => {
  // Extract and validate `historicalData`
  const historicalData = data?.historicalData || {};

  // Extract weeks or months based on `type`
  const timePeriods = Object.keys(historicalData || {});
  if (!timePeriods.length) {
    console.error("No data found in historicalData:", historicalData);
    return <div>No data available for the chart.</div>;
  }

  const categories = [
    "BSE SENSEX",
    "OverAll Change",
    "MUTUAL FUND",
    "AIF",
    "PMS",
    "STOCK",
    "BOND",
    "OTHERS",
  ];

  // Format labels for the X-axis based on `type`
  const formatLabel = (timeString: string) => {
    if (type === "Weekly") {
      const weekRegex = /(\d{4})-W(\d{2})/;
      const match = timeString.match(weekRegex);
      if(match){
        return getWeekDateRange(timeString);
      }
      else{
        return;
      }
    } else {
      const [month] = timeString.split("-");
      return month || "Unknown";
    }
  };
  const labels = timePeriods.map(formatLabel);

  // Ensure `labels` is valid
  if (!labels.length) {
    console.error(
      "Labels are empty, possibly due to invalid time parsing:",
      timePeriods
    );
    return <div>Invalid chart data.</div>;
  }

  // Prepare datasets
  const colors = [
    "#34495E",
    "#E67E22",
    "#36A2EB",
    "#808000",
    "#FFCE56",
    "#E74C3C",
    "#2ECC71",
    "#9B59B6",
    "#F1C40F",
  ];

  const datasets = categories.map((category, index) => ({
    label: category === "OverAll Change" ? "AUMSAMPANN" : category,
    data: timePeriods.map((period) => {
      const value = historicalData?.[period]?.[category];
      return typeof value === "number" ? Math.max(-10, Math.min(10, value)) : 0;
    }),
    color: colors[index % colors.length],
  }));

  // Define custom order for categories
  const customOrder = [
    "BSE SENSEX",
    "AUMSAMPANN", // Rename "OverAll Change"
    "MUTUAL FUND",
    "AIF",
    "PMS",
    "STOCK",
    "BOND",
    "OTHERS",
  ];

  // Reorder datasets according to `customOrder`
  const orderedDatasets = customOrder
    .map((label) => datasets.find((dataset) => dataset.label === label))
    .filter(
      (dataset): dataset is { label: string; data: number[]; color: string } =>
        !!dataset
    );

  if (!orderedDatasets.length) {
    console.error("Ordered datasets are empty:", datasets);
    return <div>No valid data available for the chart.</div>;
  }

  return (
    <div className="w-full h-full p-4 bg-white shadow-lg rounded-md">
      <BarChart
        xAxis={[
          {
            data: labels,
            label: type === "Weekly" ? "Weeks" : "Months",
            scaleType: "band",
            position: "bottom",
          },
        ]}
        yAxis={[
          {
            label: "Change in percentage ( % )",
            min: -10,
            max: 10,
            tickSize: 2,
          },
        ]}
        series={orderedDatasets}
        width={1300}
        height={500}
        margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
        grid={{ horizontal: true }}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
      />
      {/* Custom Legend */}
      <div className="flex justify-center mt-4 space-x-4">
        {orderedDatasets.map((dataset, index) => (
          <div key={index} className="flex items-center space-x-2">
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: dataset.color,
              }}
              className="rounded"
            ></div>
            <span className="text-sm text-gray-700">{dataset.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChartData;
