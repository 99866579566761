import React from "react";
import { Box, Typography, Container, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

const CutOffTime: React.FC = () => {
  return (
    <Container maxWidth="lg" className="py-10">
      <Paper elevation={3} className="p-6">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Cut-Off Time for Mutual Fund Transactions
        </Typography>
        <TableContainer component={Paper} sx={{marginY:"20px"}}>
      <Table>
        {/* Table Head */}
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Scheme Type</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Type of Transaction</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Cut-off Time</TableCell>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          <TableRow>
            <TableCell rowSpan={2}>Liquid/ Overnight Funds</TableCell>
            <TableCell sx={{borderLeft:"1px solid rgba(224, 224, 224, 1)",borderRight:"1px solid rgba(224, 224, 224, 1)"}}>Purchase including switch-ins from other schemes</TableCell>
            <TableCell>1:30 PM</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{borderLeft:"1px solid rgba(224, 224, 224, 1)",borderRight:"1px solid rgba(224, 224, 224, 1)"}}>Redemption including switch-outs.</TableCell>
            <TableCell>3:00 PM</TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={2}>All other schemes</TableCell>
            <TableCell sx={{borderLeft:"1px solid rgba(224, 224, 224, 1)",borderRight:"1px solid rgba(224, 224, 224, 1)"}}>Purchase including switch-ins from other schemes</TableCell>
            <TableCell>3:00 PM</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{borderLeft:"1px solid rgba(224, 224, 224, 1)",borderRight:"1px solid rgba(224, 224, 224, 1)"}}>Redemption including switch-outs.</TableCell>
            <TableCell>3:00 PM</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
        <Box className="space-y-6">
          {/* Section 1 */}
          <Box>
            {/* <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              1. USER ACCOUNT REPRESENTS AND WARRANTS
            </Typography> */}
            <Typography className="text-justify">
              Since the Mutual Fund NAV cut-off time decides the NAV for a
              transaction it was a very vital aspect of investing. However, SEBI
              circular no. SEBI/HO/IMD/DF2/CIR/P/2020/175 (September 17th,
              2020), and SEBI/HO/IMD/DF2/CIR/P/2020/253 (December 31st, 2020)
              implemented on February 1st, 2021 brought a significant change in
              the applicability of NAV across various schemes. Now the NAV will
              apply upon the realisation of funds. Hence, only when the
              application and realisation are before the cut-off time, the same-
              day NAV will apply, the only exception being liquid and overnight
              funds (where previous day NAV would be applicable if application
              and funds realised before 1.30 p.m). This will be applicable to
              the following transactions :
            </Typography>
            {/* <Typography className="text-justify">
              The Platform User is responsible for maintaining the security and
              confidentiality of their Account and login details. They are
              solely responsible for any activity conducted through their
              Account and for sharing login credentials with designated
              individuals or third parties, ensuring such parties maintain
              confidentiality.
            </Typography> */}
            <ul className="list-disc pl-6">
              <li>
                All purchase transactions:
                <br />
                This will be applicable to all the transactions, including the
                first-time purchase and additional purchase of units. This
                applies to both Systematic Investment Plans (SIP) and lump sum
                investments.
              </li>
              <li>
                Inter-scheme switching of Investments:
                <br />
                This change will also apply to switch transactions including the
                Systematic Transfer Plan (STP).
              </li>
            </ul>
            {/* <Typography className="text-justify">
              Failure to comply with these requirements may result in account
              suspension or termination.
            </Typography> */}
          </Box>

          {/* Section 2 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Importance of Mutual Fund Cut-off Time India
            </Typography>
            <Typography className="text-justify">
              The NAV of a fund determines the allotment of units to an
              investor. So, say you want to invest Rs. 10000 in a fund with the
              following NAV
            </Typography>
            <TableContainer component={Paper} sx={{maxWidth:"500px",marginX:"auto",marginY:"20px"}}>
      <Table>
        {/* Table Head */}
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>NAV</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Units Allotted</TableCell>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          <TableRow>
            <TableCell>1.05.23</TableCell>
            <TableCell>90</TableCell>
            <TableCell>111.11</TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={2}>2.05.23</TableCell>
            <TableCell>110</TableCell>
            <TableCell>90.90</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
            <Typography className="text-justify">
              So, if you had a choice which day would you invest? On the 1st of
              May right? Because you will be allotted more units. Now the only
              prerequisite to this is both application and fund realisation
              should be within cut-off timing. To avail the NAV of a particular
              day, you must invest before 3.00 p.m for all schemes other than
              liquid/overnight funds (where previous day NAV would be applicable
              if invested before 1.30 p.m). Similarly, for redemption same day
              NAV will be applied for all schemes if applied within cut-off
              time.
            </Typography>
            <Typography className="text-justify">
              So, if you submit the application after the cut-off time, the
              mutual fund will accept your application and apply the NAV the
              next day, except in case of liquid / overnight funds. This is why
              the cut-off time in mutual funds is so important to investors.
            </Typography>
          </Box>

          {/* Section 3 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Applicable NAV for Mutual Funds Transactions
            </Typography>
            <Typography className="text-justify">
              The following table will clearly display the applicable NAV for
              all purchase for all funds except liquid/ overnight funds.
            </Typography>

            <Typography className="text-justify">
              Similarly, with the new rule, the units will be allotted on the
              said date only if the payment hits the Mutual Fund’s bank account
              before 3.00 p.m on that day.
            </Typography>
            <Typography className="text-justify">
              Note: For Liquid and Overnight funds, please read cut-off time as
              1.30 PM and accordingly previous day NAV will be applied if time
              of receipt of application and funds realisation is before 1.30 PM.
            </Typography>
          </Box>

          {/* Section 4 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Mutual Fund Redemption
            </Typography>
            <Typography className="text-justify">
              If your redemption application is submitted with the fund house or
              its transfer agency before 3 pm on market trading days, then the
              same day's NAV is applicable for calculating the redemption
              amount. Requests after 3 pm get the next day's NAV.
            </Typography>
          </Box>

          {/* Section 5 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              When is the Redemption Amount Credited in your Account?
            </Typography>
            <Typography className="text-justify">
              Rules vary according to nature of the funds:
            </Typography>
            <ul className="list-disc pl-6">
              <li>Liquid funds: T+1 working day</li>
              <li>Debt funds: T+1 working day</li>
              <li>Equity funds: T+2 working day</li>
            </ul>
          </Box>

          {/* Section 6 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Types of Mutual Fund Redemption
            </Typography>

            <ul className="list-disc pl-6">
              <li>
                Unit-Based Redemption: The investor specifies the number of
                units to redeem. The amount realised depends on the prevailing
                NAV.
              </li>
              <li>
                Amount-Based Redemption: The investor specifies the redemption
                amount. The number of units redeemed is calculated by dividing
                the amount by NAV.
              </li>
              <li>
                Full Redemption: The investor redeemed the entire mutual fund
                investment corpus.
              </li>
            </ul>
            <Typography className="text-justify">
              Applicable Taxes and Exit Loads: Profit made on redemption
              attracts capital gains tax. For equity funds held under 1 year, a
              15% short-term capital gains tax applies. For debt funds held
              under 3 years, tax slab rates apply. Long term capital gains over
              1 or 3 years attract 10% and 20% tax, respectively. An exit load
              of up to 1% may apply if it is redeemed before the minimum holding
              period.
            </Typography>
          </Box>

          {/* Section 7 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              **Since all our transactions are executed through the BSE Star MF
              Platform, the client is requested to consider the cut off time
              half an hour before the actual cut off time, so that the
              transaction is executed and funds get realised to the respective
              AMC within the AMFI cut off time.**
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default CutOffTime;
