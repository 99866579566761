import api from "../../config/apiConfig";

const getAllStocks = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("stocks/getAll", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data.data;
};

const getPrivateStock = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("stocks/getAll/private", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const investStock = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);
  //data={holdingId:1,amount:10,isPrivate:1}//1 for private stock and 0 for normal stock

  const response = await api.post("stock/invest", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const withdrawStock = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);
  //data={holdingId:1,amount:10,isPrivate:1}//1 for private stock and 0 for normal stock

  const response = await api.post("stock/withdraw", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};


const StockService = {
    getAllStocks,
    getPrivateStock,
    investStock,
    withdrawStock
};
export default StockService;
