const getInitials = (name: string) => {
    if (!name) {
        return;
    }
    
    // Trim and split by spaces, then take the first word
    const words = name.trim().split(' ');
    const firstWord = words[0]; // First word in the string
    const secondWord = words.length > 1 ? words[1] : ''; // Second word if exists

    // Get the initials
    const firstInitial = firstWord[0];
    const secondInitial = secondWord ? secondWord[0] : '';

    return `${firstInitial}${secondInitial}`.toUpperCase();
}

export default getInitials;
