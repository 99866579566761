import { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Card, Modal, Box } from "@material-ui/core";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { FormInput } from "../FormInput/FormInput";
import { useUserData } from "../../hooks/useUserData";
import toast from "react-hot-toast";
import FormSelect from "../FormSelect/FormSelect";
import { useDispatch } from "react-redux";
import {
  addNomineeByV2,
  nomineeUpdate,
} from "../../redux/nominee/nomineeSlice";
import { getUserById, updateProfile } from "../../redux/user/userSlice";
import TimeStamptoExp from "../../utils/timestampConverter";
import AddNominees from "./AddNominees";
import { colors } from "../../constants/colors";
import FormDatePicker from "../FormDatePicker/FormDatePicker";
import dayjs from "dayjs";

interface UpdateItem {
  updateColumn: string;
  updateValue: any; // Adjust type as necessary
  oldValue: any; // Adjust type as necessary
}

interface UpdateData {
  KYC: Record<string, UpdateItem[]>;
  USER: Record<string, UpdateItem[]>;
  NOMINEE: Record<string, UpdateItem[]>;
  BANK: Record<string, UpdateItem[]>;
}

interface NomineeData {
  id: string;
  dateOfBirth: number; // Assuming this is a timestamp
  [key: string]: any; // Additional fields
}

const NomineeForm = ({ nominee }: any) => {
  const user = useUserData();
  const nomineeData = user?.nominee;
  console.log(nomineeData, "data");
  const [nomineeIsMinor, setNomineeIsMinor] = useState<string>("0");

  const validationSchema = Yup.object().shape({
    fullName: Yup.string(),
    mobileNumber: Yup.string(),
    email: Yup.string().email("Invalid email"),
    relation: Yup.string(),
    percentage: Yup.number().min(0).max(100),
    pancardNumber: Yup.string(),
    nomineeAddress: Yup.string(),
    isMinor: Yup.boolean(),
  });

  const dispatch = useDispatch<any>();
  const [isEditable, setIsEditable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const initialValuesRef = useRef(nomineeData);

  const handleNewNomineesSubmit = async (newNominees: any) => {
    try {
      const NewNomineeData = {
        nominee: newNominees,
      };
      const response = await dispatch(addNomineeByV2(NewNomineeData));
      if (
        response.payload?.success === 200 &&
        response.payload?.message === "Nominee added successfully."
      ) {
        toast.success("Nominee added successfully");
        dispatch(getUserById({}));
        return { success: true };
      } else if (
        response.payload?.success === 400 &&
        response.payload?.message === "Nominee already exists."
      ) {
        toast.error("Nominee already exists.");
      } else {
        toast.error("Failed to add nominee. Please try again later.");
      }
    } catch (error) {
      console.error("Error adding nominee:", error);
      toast.error("Failed to add nominee. Please try again later.");
    }
  };

  const handleNomineeAgeChange = (newValue: string) => {
    setNomineeIsMinor(newValue); // Update state when user selects Yes or No
  };

  const NomineeAge = ({ value, onChange, isEditable }: any) => {
    return (
      <div>
        <Typography variant="subtitle1">Is the nominee a minor?</Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="isMinor"
          value={value} // Bind to the current nominee's state
          onChange={(e) => {
            onChange(Number(e.target.value)); // Ensure `value` updates with `1` or `0`
          }}
        >
          <FormControlLabel
            value={1}
            control={<Radio disabled={!isEditable} />} // Disable the radio button if not editable
            label="Yes"
          />
          <FormControlLabel
            value={0}
            control={<Radio disabled={!isEditable} />} // Disable the radio button if not editable
            label="No"
          />
        </RadioGroup>
      </div>
    );
  };

  const handleSave = async (nomineesData: NomineeData[]) => {
    try {
      // Initialize the updateData object
      const updateData: UpdateData = {
        KYC: {},
        USER: {},
        NOMINEE: {},
        BANK: {},
      };

      // Iterate through all nominees and format the data
      for (const nominee of nomineesData) {
        // Retrieve the old values for comparison
        const oldNominee = initialValuesRef.current.find(
          (n: any) => n.id === nominee.id
        );

        if (!oldNominee) continue; // Skip if the old nominee does not exist

        // Construct new guardian details
        const newGuardianDetails = {
          name: nominee.guardianDetails
            ? JSON.parse(nominee.guardianDetails).name
            : "",
          mobileNumber: nominee.guardianDetails
            ? JSON.parse(nominee.guardianDetails).mobileNumber
            : "",
          relation: nominee.guardianDetails
            ? JSON.parse(nominee.guardianDetails).relation
            : "",
          pancardNumber: nominee.guardianDetails
            ? JSON.parse(nominee.guardianDetails).pancardNumber
            : "",
        };

        // Construct old guardian details for comparison
        const oldGuardianDetails = {
          name: oldNominee.guardianDetails
            ? JSON.parse(oldNominee.guardianDetails).name
            : "",
          mobileNumber: oldNominee.guardianDetails
            ? JSON.parse(oldNominee.guardianDetails).mobileNumber
            : "",
          relation: oldNominee.guardianDetails
            ? JSON.parse(oldNominee.guardianDetails).relation
            : "",
          pancardNumber: oldNominee.guardianDetails
            ? JSON.parse(oldNominee.guardianDetails).pancardNumber
            : "",
        };

        // Check if the guardian details have changed
        if (
          JSON.stringify(newGuardianDetails) !==
          JSON.stringify(oldGuardianDetails)
        ) {
          const updatedNomineeData: UpdateItem = {
            updateColumn: "guardianDetails",
            updateValue: JSON.stringify(newGuardianDetails), // Stringify the entire object
            oldValue: JSON.stringify(oldGuardianDetails),
          };

          // Ensure the NOMINEE field is correctly indexed and populated
          if (!updateData["NOMINEE"][nominee.id]) {
            updateData["NOMINEE"][nominee.id] = [];
          }
          updateData["NOMINEE"][nominee.id].push(updatedNomineeData);
        }

        // Now iterate through all nominee fields excluding 'id'
        for (const [key, value] of Object.entries(nominee)) {
          if (key !== "id" && key !== "guardianDetails") {
            // Exclude 'id' and 'guardianDetails' from being updated
            const oldValue = oldNominee[key];

            // Only update if the value has changed
            if (value !== oldValue) {
              const updatedNomineeData: UpdateItem = {
                updateColumn: key,
                updateValue: value, // New value (changed value)
                oldValue: oldValue, // Current value (old value)
              };

              // Ensure NOMINEE field is correctly indexed and populated
              if (!updateData["NOMINEE"][nominee.id]) {
                updateData["NOMINEE"][nominee.id] = [];
              }
              updateData["NOMINEE"][nominee.id].push(updatedNomineeData);
            }
          }
        }
      }

      // Dispatch the action with formatted data only if there's any change
      if (Object.keys(updateData.NOMINEE).length > 0) {
        console.log(updateData);
        const response = await dispatch(updateProfile(updateData));

        if (!(response.payload && response.payload.success === 200)) {
          toast.error("Error updating nominee data");
          return;
        }

        dispatch(getUserById({}));
        toast.success("Nominee data saved successfully");
      } else {
        toast.error("No changes detected, nothing to save.");
      }
    } catch (error) {
      console.error("Error during save:", error);
      toast.error("Error saving nominee data");
    }
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      // Validate the total percentage before submitting
      if (!validateTotalPercentage(values.nominees)) {
        setSubmitting(false);
        return; // Prevent submission
      }

      // If validation passes, proceed with saving
      await handleSave(values.nominees);
    } catch (error) {
      toast.error("Error submitting nominee data");
    } finally {
      setSubmitting(false);
    }
  };

  const relationshipOptions = [
    { label: "AUNT", value: 1 },
    { label: "BROTHER-IN-LAW", value: 2 },
    { label: "BROTHER", value: 3 },
    { label: "DAUGHTER", value: 4 },
    { label: "DAUGHTER-IN-LAW", value: 5 },
    { label: "FATHER", value: 6 },
    { label: "FATHER-IN-LAW", value: 7 },
    { label: "GRAND DAUGHTER", value: 8 },
    { label: "GRAND FATHER", value: 9 },
    { label: "GRAND MOTHER", value: 10 },
    { label: "GRAND SON", value: 11 },
    { label: "MOTHER-IN-LAW", value: 12 },
    { label: "MOTHER", value: 13 },
    { label: "NEPHEW", value: 14 },
    { label: "NIECE", value: 15 },
    { label: "SISTER", value: 16 },
    { label: "SISTER-IN-LAW", value: 17 },
    { label: "SON", value: 18 },
    { label: "SON-IN-LAW", value: 19 },
    { label: "SPOUSE", value: 20 },
    { label: "UNCLE", value: 21 },
    { label: "OTHERS", value: 22 },
    { label: "COURT APPOINTED LEGAL GUARDIAN", value: 23 },
  ];

  const boxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxWidth: 500,
    width: "100%",
  };

  const ulStyles = {
    listStyleType: "disc", // Removes default bullet points
    paddingLeft: "0",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    "& li": {
      position: "relative",
      paddingLeft: "2rem", // Space for custom bullet points
      marginBottom: "0.5rem", // Space between items
      color: "text-gray-700", // Tailwind's text-gray-700
    },
    "& li::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#f39a45", // Custom color for bullets
    },
  };

  const validateTotalPercentage = (nominees: any) => {
    // Ensure nominees is an array before calling .reduce()
    if (!Array.isArray(nominees)) {
      return false; // or handle the case where nominees is not an array
    }

    const total = nominees.reduce(
      (sum: number, nominee: any) =>
        sum + (parseFloat(nominee.percentage) || 0),
      0
    );

    return total <= 100;
  };

  return (
    <>
      <div className="mb-6 ">
        <Card
          style={{
            backgroundColor: colors.lightBg,
            minHeight: "60px",
            display: "flex",
            alignItems: "center",
            padding: "0",
          }}
          className="rounded-md h-8 w-full"
        >
          <Typography
            variant="h5"
            marginLeft={2}
            color={colors.darkGrey}
            fontWeight="bold"
          >
            Nominee Details
          </Typography>
        </Card>
        <Formik
          initialValues={{ nominees: nomineeData, nomineeIsMinor: "0" }}
          validationSchema={Yup.object().shape({
            nominees: Yup.array().of(validationSchema),
            nomineeIsMinor: Yup.string().required("This field is required."),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, dirty, setFieldValue, setFieldTouched }) => (
            <Form className="relative flex flex-col justify-center mx-auto mt-4 w-full mb-4">
              {/* Delete Icon */}
              {values.nominees.map((nominee: any, index: number) => (
                <div
                  key={index}
                  className=" bg-white p-4 mb-4 shadow-md  border border-lightGrey rounded-lg"
                >
                  <div className="flex flex-wrap justify-between">
                    {/* Full Name */}
                    <div className="flex flex-grow w-full md:w-full lg:w-1/3 p-2 mb-4">
                      {isEditable ? (
                        <Field
                          name={`nominees[${index}].name`}
                          type="text"
                          as={FormInput}
                          label="Full Name as per PAN Card *"
                          disabled={!isEditable}
                          labelClass="w-full md:w-48 lg:w-40 xl:w-32"
                        />
                      ) : (
                        <div>
                          <span className="text-textGrey text-sm md:text-base">
                            Full Name as per PAN Card *
                          </span>
                          <Typography
                            variant="h6"
                            color={colors.darkGrey}
                            className="font-bold text-sm md:text-base w-full md:w-48 lg:w-40 xl:w-32"
                          >
                            {nominee.name}
                          </Typography>
                        </div>
                      )}
                      <ErrorMessage
                        name={`nominees[${index}].name`}
                        component="div"
                        className="error text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Relationship */}
                    {/* Relationship */}
                    <div className="flex flex-grow w-full md:w-full lg:w-1/3 p-2 mb-4">
                      {isEditable ? (
                        <Field
                          name={`nominees[${index}].relation`}
                          as={FormSelect}
                          label="Relationship"
                          options={relationshipOptions}
                          disabled={!isEditable}
                          labelClass="w-full md:w-48 lg:w-40 xl:w-32"
                          className="w-full" // Ensuring the width matches other fields
                        />
                      ) : (
                        <div>
                          <span className="text-textGrey text-sm md:text-base">
                            Relationship *
                          </span>
                          <Typography
                            variant="h6"
                            color={colors.darkGrey}
                            className="font-bold text-sm md:text-base w-full md:w-48 lg:w-40 xl:w-32" // Apply consistent width
                          >
                            {relationshipOptions.find(
                              (option) => option.value === nominee.relation
                            )?.label || "N/A"}
                          </Typography>
                        </div>
                      )}
                      <ErrorMessage
                        name={`nominees[${index}].relation`}
                        component="div"
                        className="error text-red-500 text-sm mt-1"
                      />
                    </div>
                    <div className="flex flex-grow w-full md:w-full lg:w-1/3 p-2 mb-4">
                      {isEditable ? (
                        <FormDatePicker
                          label="Date of Birth *"
                          name={`nominees[${index}].dateOfBirth`}
                          inputFormat="DD/MM/YYYY"
                          defaultValue={dayjs(nominee.dateOfBirth)}
                          className="mb-4"
                          disabled={!isEditable}
                          onChange={(date) => {
                            // Convert date to timestamp
                            const timestamp = date
                              ? dayjs(date).valueOf()
                              : null; // Use valueOf to get the timestamp
                            setFieldValue(
                              `nominees[${index}].dateOfBirth`,
                              timestamp
                            );
                            setFieldTouched(
                              `nominees[${index}].dateOfBirth`,
                              true
                            );
                          }}
                        />
                      ) : (
                        <div>
                          <span className="text-textGrey text-sm md:text-base">
                            Date Of Birth
                          </span>
                          <Typography
                            variant="h6"
                            color={colors.darkGrey}
                            className="font-bold text-sm md:text-base w-full md:w-48 lg:w-40 xl:w-32"
                          >
                            {nominee.dateOfBirth
                              ? dayjs(nominee.dateOfBirth).format("DD/MM/YYYY")
                              : "N/A"}
                          </Typography>
                        </div>
                      )}
                      <ErrorMessage
                        name={`nominees[${index}].dateOfBirth`} // Updated from name for error message
                        component="div"
                        className="error text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Share Percentage */}
                    <div className="flex flex-col w-full md:w-full lg:w-1/3 p-2 mb-4">
                      {isEditable ? (
                        <>
                          <Field
                            name={`nominees[${index}].percentage`}
                            type="text"
                            as={FormInput}
                            label="Share (in Percentage) *"
                            disabled={!isEditable}
                            labelClass="w-full md:w-48 lg:w-40 xl:w-32"
                            validate={() => {
                              const nominees = values.nominees;
                              return validateTotalPercentage(nominees)
                                ? undefined
                                : "Total share percentage cannot exceed 100%";
                            }}
                          />
                          <ErrorMessage
                            name={`nominees[${index}].percentage`}
                            component="div"
                            className="error text-red-500 text-sm" // Adjust margin-top to reduce gap
                          />
                        </>
                      ) : (
                        <div>
                          <span className="text-textGrey text-sm md:text-base">
                            Share (in Percentage) *
                          </span>
                          <Typography
                            variant="h6"
                            color={colors.darkGrey}
                            className="font-bold text-sm md:text-base"
                          >
                            {nominee.percentage}%
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Is Minor */}
                  <div className="flex flex-grow w-full md:w-full lg:w-1/3 p-2 mb-4">
                    <NomineeAge
                      value={nominee.isMinor}
                      onChange={(val: any) =>
                        setFieldValue(`nominees[${index}].isMinor`, val)
                      }
                      isEditable={isEditable}
                    />
                    <ErrorMessage
                      name={`nominees[${index}].isMinor`}
                      component="div"
                      className="error text-red-500 text-sm mt-1"
                    />
                  </div>
                  {nominee.isMinor === 1 && (
                    <>
                      <div className="flex flex-wrap justify-between">
                        {/* Name Input */}
                        <div className="flex flex-grow w-full md:w-full lg:w-1/4 p-2 mb-4">
                          {isEditable ? ( // Check if form is editable
                            <Field
                              name={`nominees[${index}].guardianDetails.name`}
                              type="text"
                              as={FormInput}
                              label="Guardian Name *"
                              disabled={!isEditable}
                              labelClass="w-full md:w-48 lg:w-40 xl:w-32"
                              value={
                                values.nominees[index].guardianDetails
                                  ? JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ).name
                                  : ""
                              }
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldValue(
                                  `nominees[${index}].guardianDetails`,
                                  JSON.stringify({
                                    ...JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ),
                                    name: value,
                                  })
                                );
                              }}
                            />
                          ) : (
                            <div>
                              <span className="text-textGrey text-sm md:text-base">
                                Guardian Name
                              </span>
                              <Typography
                                variant="h6"
                                color={colors.darkGrey}
                                className="font-bold text-sm md:text-base"
                              >
                                {nominee.guardianDetails
                                  ? JSON.parse(nominee.guardianDetails).name
                                  : ""}
                              </Typography>
                            </div>
                          )}
                          <ErrorMessage
                            name={`nominees[${index}].guardianDetails.name`}
                            component="div"
                            className="error text-red-500 text-sm mt-1"
                          />
                        </div>

                        {/* Guardian Mobile Number Input */}
                        <div className="flex flex-grow w-full md:w-full lg:w-1/4 p-2 mb-4">
                          {isEditable ? (
                            <Field
                              name={`nominees[${index}].guardianDetails.mobileNumber`}
                              type="text"
                              as={FormInput}
                              label="Guardian Mobile Number *"
                              disabled={!isEditable}
                              labelClass="w-full md:w-48 lg:w-40 xl:w-32"
                              value={
                                values.nominees[index].guardianDetails
                                  ? JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ).mobileNumber
                                  : ""
                              }
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldValue(
                                  `nominees[${index}].guardianDetails`,
                                  JSON.stringify({
                                    ...JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ),
                                    mobileNumber: value,
                                  })
                                );
                              }}
                            />
                          ) : (
                            <div>
                              <span className="text-textGrey text-sm md:text-base">
                                Guardian Mobile Number
                              </span>
                              <Typography
                                variant="h6"
                                color={colors.darkGrey}
                                className="font-bold text-sm md:text-base"
                              >
                                {nominee.guardianDetails
                                  ? JSON.parse(nominee.guardianDetails)
                                      .mobileNumber
                                  : ""}
                              </Typography>
                            </div>
                          )}
                          <ErrorMessage
                            name={`nominees[${index}].guardianDetails.mobileNumber`}
                            component="div"
                            className="error text-red-500 text-sm mt-1"
                          />
                        </div>
                      </div>

                      <div className="flex flex-wrap justify-between">
                        <div className="flex flex-grow w-full md:w-full lg:w-1/4 p-2 mb-4">
                          {isEditable ? (
                            <Field
                              name={`nominees[${index}].guardianDetails.relation`}
                              as={FormSelect}
                              label="Guardian Relationship"
                              options={relationshipOptions}
                              disabled={!isEditable}
                              labelClass="w-full md:w-48 lg:w-40 xl:w-32"
                              className="w-full"
                              value={
                                values.nominees[index].guardianDetails
                                  ? JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ).relation
                                  : ""
                              }
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldValue(
                                  `nominees[${index}].guardianDetails`,
                                  JSON.stringify({
                                    ...JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ),
                                    relation: value,
                                  })
                                );
                              }}
                            />
                          ) : (
                            <div>
                              <span className="text-textGrey text-sm md:text-base">
                                Guardian Relationship
                              </span>
                              <Typography
                                variant="h6"
                                color={colors.darkGrey}
                                className="font-bold text-sm md:text-base"
                              >
                                {nominee.guardianDetails
                                  ? typeof nominee.guardianDetails === "string"
                                    ? relationshipOptions.find(
                                        (option) =>
                                          option.value ===
                                          Number(
                                            JSON.parse(nominee.guardianDetails)
                                              .relation
                                          )
                                      )?.label || "N/A"
                                    : relationshipOptions.find(
                                        (option) =>
                                          option.value ===
                                          nominee.guardianDetails.relation
                                      )?.label || "N/A"
                                  : "N/A"}
                              </Typography>
                            </div>
                          )}
                          <ErrorMessage
                            name={`nominees[${index}].guardianDetails.relation`}
                            component="div"
                            className="error text-red-500 text-sm mt-1"
                          />
                        </div>
                        {/* Pancard Number Input */}
                        <div className="flex flex-grow w-full md:w-full lg:w-1/4 p-2 mb-4">
                          {isEditable ? (
                            <Field
                              name={`nominees[${index}].pancardNumber`}
                              type="text"
                              as={FormInput}
                              label="Guardian Pancard Number *"
                              disabled={!isEditable}
                              labelClass="w-full md:w-48 lg:w-40 xl:w-32"
                              value={
                                values.nominees[index].guardianDetails
                                  ? JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ).pancardNumber
                                  : ""
                              }
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldValue(
                                  `nominees[${index}].guardianDetails`,
                                  JSON.stringify({
                                    ...JSON.parse(
                                      values.nominees[index].guardianDetails
                                    ),
                                    pancardNumber: value,
                                  })
                                );
                              }}
                            />
                          ) : (
                            <div>
                              <span className="text-textGrey text-sm md:text-base">
                                Pancard Number
                              </span>
                              <Typography
                                variant="h6"
                                color={colors.darkGrey}
                                className="font-bold text-sm md:text-base"
                              >
                                {nominee.guardianDetails
                                  ? JSON.parse(nominee.guardianDetails)
                                      .pancardNumber
                                  : ""}
                              </Typography>
                            </div>
                          )}
                          <ErrorMessage
                            name={`nominees[${index}].pancardNumber`}
                            component="div"
                            className="error text-red-500 text-sm mt-1"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
              {dirty && isEditable && (
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="w-18 h-12 text-md border rounded-md border-darkGrey border-solid bg-darkGrey text-white hover:bg-primary cursor-pointer font-semibold"
                  >
                    Save All Nominees
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
        {/* {!isEditable && nomineeData && nomineeData.length > 0 && (
          <div className="flex justify-end mt-4 mr-8">
            <button
              className="w-18 h-12 text-md border rounded-md border-darkGrey border-solid bg-darkGrey text-white hover:bg-primary cursor-pointer font-semibold"
              onClick={() => setOpenModal(true)}
            >
              Modify
            </button>
          </div>
        )} */}

        <div className="mx-6 mt-5 mb-5">
          <AddNominees
            onSubmit={handleNewNomineesSubmit}
            data={nomineeData}
            xs={6}
            initialNomineeCount={0}
            removeParent={true}
          />
        </div>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={boxStyles}
          className="p-6 bg-white border border-gray-200 rounded-md shadow-lg"
        >
          <Typography
            variant="h6"
            component="h2"
            className="text-xl font-semibold text-gray-800 mb-4"
          >
            Modify Nominee Details?
          </Typography>
          <Typography variant="body1" className="text-gray-700 mb-2">
            Are you sure you want to update nominee details?
          </Typography>
          <Typography variant="body2" className="text-gray-600">
            <ul style={ulStyles}>
              <li>All existing nominee(s) details will be deleted.</li>
              <li>New nominee details should be entered again.</li>
              <li>
                Aadhaar-based eSign will be required again for modification.
              </li>
            </ul>
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <button
              className="w-18 h-12 text-md border rounded-md border-darkGrey border-solid bg-darkGrey text-white hover:bg-primary cursor-pointer font-semibold"
              onClick={() => {
                setIsEditable(true);
                setOpenModal(false);
              }}
            >
              Modify
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NomineeForm;
