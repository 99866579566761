import React, { FC, useEffect, useRef, useState } from "react";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { InvestRequest } from "../../redux/AIF/AIFSlice";
import MFDetailCard from "../MFTransaction/MFDetailCard";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../FormInput/FormInput";
import AmountButtons from "../MFTransaction/AmountButtons";
import APIloader from "../AppLoader/APIloader";
import Dialog from "@mui/material/Dialog";
import { Button as Btn } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import { showInterest } from "../../redux/user/userSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import UnlistedDetailCard from "./UnlistedStockDetailCard";
import { investStock } from "../../redux/Stocks/StockSlice";
import { useUserData } from "../../hooks/useUserData";

interface MFProps {
  fundId: number;
  fundInfo: any;
  onClose?: any;
}

const InvestmentPage: FC<MFProps> = ({ fundId, fundInfo, onClose }) => {
  const ref = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const userData=useUserData();

  const [investmentPlan, setInvestmentPlan] =
    useState<string>("New investment");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [investmentPeriod, setInvestmentPeriod] = useState<number | null>(null);
  const [lumpsumValues, setLumpsumValues] = useState({
    aifId: 0,
    amount: 0,
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const handleDialogClose = () => setShowSummary(false);
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);

  const handleConfirm = async () => {
    if (summaryData) {
      try {
        const { amount, fundId } = summaryData;

        const updatedBodyData = {
            stockId: fundInfo?.id,
            amount:  parseInt(amount),
            isPrivate: 1 // 1 for private stocks, 0 for normal stocks
        };

        console.log(fundInfo, "bodyData");
        setLoading(true);
        const res = await dispatch(investStock(updatedBodyData));
        if (res?.payload?.data?.success === 200) {
          setLoading(false);
          setOpen(true);
          setIsSuccessfull(true);
          setMessage("Investment Request is submitted successfully");
        } else {
          setLoading(false);
          setOpen(true);
          setErrorMessage(
            "Error in executing your order."
          );
        }
      } catch (error) {
        setOpen(true);
        setErrorMessage("Investment Failed");
      } finally {
        dispatch(hideLoading());
        handleDialogClose();
      }
    }
  };



  const handleShowInterestClick = () => {
    setModalOpen(true);
  };

  const showingInterest = async () => {
    try {

      const updatedBodyData = {
        stockId: fundInfo?.id,
        amount:  20,
        isPrivate: 1 // 1 for private stocks, 0 for normal stocks
      };
      setLoading(true);
      const res = await dispatch(investStock(updatedBodyData));
      if (res?.payload?.data?.success === 200) {
        setLoading(false);
        setOpen(true);
        setIsSuccessfull(true);
        setMessage(`Your interest has been successfully submitted to your RM. You can also reach out to your RM ${userData?.relationManager?.firstName} at ${userData?.relationManager?.mobile}.`);
      } else {
        setLoading(false);
        setOpen(true);
        setErrorMessage(
          "Your Interest is not submitted.Somthing wents wrong"
        );
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Investment Failed");
    } finally {
      dispatch(hideLoading());
      handleDialogClose();
    }
    // try {
    //   const response = await dispatch(showInterest({ id:fundId, holdingType:5 }));

    //   if (response?.payload?.statusCode === 200) {
    //     toast.success("Your Interest is submitted successfully");
    //   } else {
    //     toast.error("Something wents wrong.Please try again later!");
    //     console.warn('Unexpected response structure:', response);
    //   }
    // } catch (error) {
    //   toast.error("Something wents wrong.Please try again later!");
    //   console.error("Error fetching users:", error);
    // }
  };
  const handleInvestClick = () => {
    // Validate selectedAmount and investmentPeriod
    if (!selectedAmount) {
      setOpen(true);
      setErrorMessage('Please enter the amount to invest'); // Show error if selectedAmount is missing
      return;
    }
    
    // if (!investmentPeriod) {
    //   toast.error('Please enter the investment period'); // Show error if investmentPeriod is missing
    //   return;
    // }    
  };

  const handleClose=()=>{
    onClose();
    if(isSuccessfull){
      setOpen(false);
    navigate("/unlistedEquity-listing");
    setIsSuccessfull(false);
    }
  }

  return (
    <>
      <div className="bg-lightBg pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white">
              <div className="md:px-8 py-10">
                <UnlistedDetailCard fundInfo={fundInfo} />
                <div className="my-5">
                  <Formik
                    innerRef={ref}
                    initialValues={lumpsumValues}
                    onSubmit={async () => {
                      handleInvestClick();
                      if (!selectedAmount) {
                        return; // Do nothing if values are missing
                      }

                      // Show summary dialog
                      setSummaryData({
                        amount: selectedAmount,
                        fundId: fundInfo?.id,
                      });
                      setShowSummary(true);
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="mt-5">
                        <AmountButtons
                          label="Amount"
                          // setFieldValue={setFieldValue}
                          selectedAmount={selectedAmount}
                          setSelectedAmount={setSelectedAmount}
                          fieldName="amount"
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                        <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                                <div className="flex gap-5 justify-between lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                                  <Button
                                    type="submit"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    tabIndex={0}
                                    style={{ cursor: !selectedAmount?'not-allowed':'pointer' }}
                                  >
                                    Invest
                                    {/* Proceed to payment */}
                                  </Button>
                                  <Button
                                  type="button"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    onClick={handleShowInterestClick}
                                  >
                                    Show Interest
                                    {/* Proceed to payment */}
                                  </Button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIloader loadingModal={loading} message={""} />

      {/* Summary Dialog */}
      <Dialog
        open={showSummary}
        onClose={handleDialogClose}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      >
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
            <Typography variant="h4" className="font-bold mb-4">
              Summary
            </Typography>
            <DialogContent className="flex flex-col">
              {summaryData ? (
                <>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Investment Plan:
                    </span>
                    <span>{fundInfo?.stockName}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Amount:</span>
                    <span>Rs. {summaryData.amount}</span>
                  </div>
                  {/* <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Approx. Units:
                    </span>
                    <span>{calculateUnits(fundInfo?.AUM, fundInfo?.NAV)}</span>
                  </div> */}
                  {/* <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Investment Period:</span>
                    <span>{investmentPeriod && (
                          <span>
                            {investmentPeriod} year
                            {investmentPeriod > 1 ? "s" : ""}
                          </span>
                        )}</span>
                  </div> */}
                  {/* <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Return Since Inception:
                    </span>
                    <span>{fundInfo?.ReturnSinceInception===""||fundInfo?.ReturnSinceInception==="-"?"-":fundInfo?.ReturnSinceInception+"%"}</span>
                  </div> */}
                </>
              ) : (
                <div className="flex justify-center items-center">
                  <span>Loading...</span>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Btn
                onClick={handleConfirm}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
                onClick={handleDialogClose}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
            {/* <Typography variant="body2" className="mb-4" sx={{ marginTop: 4 }}>
              Once confirmed, you will be redirected to the banking payment page
              for transaction
            </Typography> */}
          </div>
        </div>
      </Dialog>
      {/* Confirmation Modal for show interest*/}
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)} // Close modal on cancel or outside click
      >
        <DialogContent>
          <Typography variant="h6" className="font-bold my-3 text-center">
            Are you sure you want to submit your interest?
          </Typography>
        </DialogContent>
        <DialogActions className="w-[97%] mb-3">
              <Btn
               onClick={showingInterest}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
               onClick={() => setModalOpen(false)}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
      </Dialog>
      <SuccessfullModal
        open={Open}
        message={message!==""?message:errormessage}
        handleClose={handleClose}
      />
    </>
  );
};

export default InvestmentPage;
