import api from "../../config/apiConfig";

const getAllFetchedEmail = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/getAllFetchedEMail", {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const allowUserToAutoFetch = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/allowAutoFetch", {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const addPasswordForProtectedMail = async (data:any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/addPdfPassword",data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const FetchedEmailService = {
  getAllFetchedEmail,allowUserToAutoFetch,addPasswordForProtectedMail
};
export default FetchedEmailService;
