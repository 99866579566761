import React, { FC } from "react";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";

const Details: FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className="max-w-[800px]">
    <div className="pl-5 py-2 flex flex-col gap-y-2 rounded-md bg-opacity-80 min-w-[150px] md:max-w-[400px]">
      <div className="text-lg font-medium">{label}</div>
      <div className="text-xl font-bold" style={{ color: colors.textGrey }}>
        {value}
      </div>
    </div>
  </div>
);


const UnlistedDetailCard = ({ fundInfo }: any) => {
  console.log(fundInfo,"fundInfo");
  // const pmsDetails = [
  //   {
  //     label: "Fund Manager",
  //     value: fundInfo?.FundManager,
  //   },
  //   {
  //     label: "AUM (in Cr)",
  //     value: fundInfo?.AumInCr===""||fundInfo?.AumInCr==="-"?"-":fundInfo?.AumInCr+"Cr",
  //   },
  //   {
  //     label: "Return Rate",
  //     value: fundInfo?.ReturnSinceInception===""||fundInfo?.ReturnSinceInception==="-"?"-":fundInfo?.ReturnSinceInception+"%",
  //   }
  // ];
  return (
    <div className="flex flex-col md:flex-row gap-x-6 md:p-10 bg-lightBg">
      <div className="w-14 h-14 mb-3 md:mb-0 md:w-32 md:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-4xl font-bold">
        {getInitials(fundInfo?.stockName)}
      </div>
      <div className="flex flex-col gap-y-3 flex-1 max-w-[1200px]">
        <h3
          className="text-2xl pl-4 font-medium"
          style={{ color: colors.primary }}
        >
          {fundInfo?.stockName|| "-"}
        </h3>
        <h3
          className="text-2xl pl-4 font-medium"
          style={{ color: colors.primary }}
        >
          Sector : {fundInfo?.stockSector|| "-"}
        </h3>
        {/* <div className="flex flex-wrap gap-5 pt-5 justify-center md:justify-start">
          {pmsDetails.map((fd: any) => (
            fd.value && <Details label={fd.label} value={fd.value} />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default UnlistedDetailCard;
