import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchedEmailService from "./FetchedEmailService";

export const getAllFetchedEmail = createAsyncThunk(
  "user/getAllFetchedEMail",
  async (_, thunkAPI) => {
    try {
      const response = await FetchedEmailService.getAllFetchedEmail();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const allowUserToAutoFetch = createAsyncThunk(
  "user/allowAutoFetch",
  async (_, thunkAPI) => {
    try {
      const response = await FetchedEmailService.allowUserToAutoFetch();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const addPasswordForProtectedMail = createAsyncThunk(
  "user/addPdfPassword",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await FetchedEmailService.addPasswordForProtectedMail(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  EmailData: [],
  paymentStatus: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const FetchedEmailSlice = createSlice({
  name: "Fetched Email History",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFetchedEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFetchedEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.EmailData = action.payload.data;
      })
      .addCase(getAllFetchedEmail.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(allowUserToAutoFetch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allowUserToAutoFetch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.EmailData = action.payload.data;
      })
      .addCase(allowUserToAutoFetch.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(addPasswordForProtectedMail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPasswordForProtectedMail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.EmailData = action.payload.data;
      })
      .addCase(addPasswordForProtectedMail.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
  },
});

export const { resetState } = FetchedEmailSlice.actions;
export const { reducer: FetchedEmailSliceReducer } = FetchedEmailSlice;
