import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StockService from "./StockService";


export const getAllStocks = createAsyncThunk(
  "stocks/getAll",
  async (data: any, thunkAPI: any) => {
    try {
      if (!data) {
        throw new Error("No refresh token provided");
      }
      const response = await StockService.getAllStocks(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getPrivateStock = createAsyncThunk(
  "stocks/getAll/private",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await StockService.getPrivateStock(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const investStock = createAsyncThunk(
  "stock/invest",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await StockService.investStock(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const withdrawStock = createAsyncThunk(
  "stock/withdraw",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await StockService.withdrawStock(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
    funds: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const StockSlice = createSlice({
  name: "stocks",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStocks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStocks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.funds = action.payload;
      })
      .addCase(getAllStocks.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getPrivateStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrivateStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.funds = action.payload.data;
      })
      .addCase(getPrivateStock.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(withdrawStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(withdrawStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.funds = action.payload.data;
      })
      .addCase(withdrawStock.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(investStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(investStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.funds = action.payload.data;
      })
      .addCase(investStock.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = StockSlice.actions;
export const { reducer: StockSliceReducer } = StockSlice;
