import React, { useEffect, useState } from "react";
import { RadioGroup, FormControlLabel, Radio, Typography,  Button } from "@mui/material";
import { colors } from "../../../constants/colors";
import { getUserById, skipOnboarding } from "../../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";

interface PreferenceDetailsProps {
  allowToProceed: () => void;
  onSelectToAadhar:()=>void;
  userData: any;
}

const SelectType: React.FC<PreferenceDetailsProps> = ({
  allowToProceed,onSelectToAadhar,
  userData
}) => {
  const dispatch=useDispatch<any>();
  const navigate=useNavigate();
  const [investmentPlan, setInvestmentPlan] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInvestmentPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvestmentPlan(event.target.value);
  };

  const handleButtonClick = async() => {
    if (investmentPlan === "Yes") {
      await dispatch(skipOnboarding());
      await dispatch(getUserById({}));
      navigate("/");
    } else {
      onSelectToAadhar();
    }
  };
  return (    
    // <div className="mx-auto sm:w-full md:px-8 lg:px-14 mt-5">
    //   {(userData?.kyc?.skipAadhaar===0 || userData?.kyc?.skipAadhaar===1  ||userData?.kyc?.isZeroDoc===1)?(
    //     <>
    //     <div className="flex flex-col gap-5 items-center">
    //         <CheckCircle className="text-green-600 text-3xl" fontSize="large" />
    //         <p className="text-lg font-semibold font-inter">
    //         Please proceed for BSE registration.
    //         </p>
    //         <Button
    //           type="button"
    //           sx={{
    //             px: 4,
    //             py: 2,
    //             borderRadius: "8px",
    //             backgroundColor: colors.darkGrey,
    //             color: "white",
    //             "&:hover": {
    //               backgroundColor: colors.primary,
    //             },
    //             transition: "all 0.3s ease-in-out",
    //             maxWidth: "250px",
    //             width: "100%",
    //             mt: 3,
    //           }}
    //           onClick={onSelectToAadhar}
    //         >
    //           Proceed
    //         </Button>
    //       </div>
    //   </>):(
    //   <div className="flex flex-col gap-1.5 items-center justify-center w-full">
    //     <div className="mt-5 mx-12">
    //       <Typography
    //         variant="body1"
    //         fontSize="18px"
    //         gutterBottom
    //         style={{ color: colors.darkGrey }}
    //       >
    //         Do you wish to continue the onboarding process for Mutual Fund Investment from Aum Sampann? <span className="text-red-500">*</span>
    //       </Typography>
    //       <RadioGroup
    //         row
    //         value={investmentPlan}
    //         onChange={handleInvestmentPlanChange}
    //       >
    //         <FormControlLabel
    //           value="Yes"
    //           control={<Radio />}
    //           label="Yes"
    //         />
    //         <FormControlLabel
    //           value="No"
    //           control={<Radio />}
    //           label="No"
    //         />
    //       </RadioGroup>
    //     </div>
    //     <div className="flex flex-col gap-1.5 items-center justify-center w-[80%]">
    //       <p className="text-lg mt-2 text-gray-500 text-center">
    //         Note: By clicking "No", you will be redirected to the dashboard. While you will no longer be able to transact in mutual funds, you can still view all your purchased mutual funds in one place.
    //       </p>
    //     </div>
    //     <div className="mt-10 mb-10">
    //     <button
    //       type="button"
    //       className="px-8 py-4 rounded-md bg-primary text-white hover:bg-white hover:text-primary border border-primary transition-all"
    //       onClick={handleButtonClick}
    //     >
    //       Save and Proceed
    //     </button>
    //   </div>
    //   </div>)}
      
    // </div>
    <div className="mx-auto sm:w-full md:px-8 lg:px-14 mt-5">
      {(userData?.organization?.companyName)?(
        <>
        <div className="flex flex-col gap-5 items-center">
            <CheckCircle className="text-green-600 text-3xl" fontSize="large" />
            <p className="text-lg font-semibold font-inter">
            Please proceed for BSE registration.
            </p>
            <button
              type="button"
              className="form-button"
              onClick={onSelectToAadhar}
            >
              Proceed
            </button>
          </div>
      </>):(
      <div className="flex flex-col  gap-1.5 items-center justify-center w-full">
        <div className="flex flex-col mt-5 w-full px-[19%]">
          <RadioGroup
            row
            value={investmentPlan}
            onChange={handleInvestmentPlanChange}
          >
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="I only want to view holdings, including Mutual Funds (MF), Fixed Deposits (FD), Equity, Bonds, PMS and AIF."
            />
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="I want to view holdings and perform Mutual Fund (MF) transactions."
            />
          </RadioGroup>
        </div>
        <div className="mt-10 mb-10">
        <button
          type="button"
          className={`px-8 py-4 rounded-md ${
            investmentPlan
              ? "bg-primary text-white hover:bg-secondary border"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          } transition-all`}
          onClick={handleButtonClick}
          disabled={!investmentPlan}
        >
          Save and Proceed
        </button>
      </div>
      </div>)}
      
    </div>
  );
};

export default SelectType;

