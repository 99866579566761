export function getWeekDateRange(weekString: string): string {
  // Updated regex to match the format "YYYY-WXX"
  const weekRegex = /(\d{4})-W(\d{2})/;
  const match = weekString.match(weekRegex);

  if (!match) {
    throw new Error(`Invalid week format: '${weekString}'. Expected format: 'YYYY-WXX'`);
  }

  const year = parseInt(match[1], 10);
  const weekNumber = parseInt(match[2], 10);

  // Calculate the first day of the year (January 1st)
  const firstDayOfYear = new Date(year, 0, 1);

  // Find the day of the week for January 1st (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = firstDayOfYear.getDay();

  // Adjust the first day of the week to be Monday
  const daysToAdd = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Ensure the start is Monday
  firstDayOfYear.setDate(firstDayOfYear.getDate() + daysToAdd);

  // Calculate the start of the week
  const startOfWeek = new Date(firstDayOfYear);
  startOfWeek.setDate(startOfWeek.getDate() + (weekNumber - 1) * 7);

  // Calculate the end of the week (6 days after the start)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  // Format the dates as "25 Nov" instead of "Nov 25"
  const formatDate = (date: Date): string =>
    date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
    });

  const startDate = formatDate(startOfWeek);
  const endDate = formatDate(endOfWeek);

  return `${startDate}-${endDate}`;
}
