import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";

const TermsOfUsePage: React.FC = () => {
  return (
    <Container maxWidth="lg" className="py-10">
      <Paper elevation={3} className="p-6">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold",textAlign:"center" }}>
          Terms of Use for availing MF Central’s CAS API services provided by
          AUM Sampann
        </Typography>
        <Box className="space-y-6">
          {/* Section 1 */}
          <Box>
            <Typography className="text-justify">
              Below are the terms of use ("Terms") governing the data fetching
              services from MFCentral (“Services”) offered by AUM Sampann in
              partnership with, a wholly owned subsidiary of AUM Capital Market
              Pvt Ltd. By accepting these Terms, you acknowledge and agree to
              the following:
            </Typography>
            <ul className="list-disc pl-6">
              <li>
                As a user, you commit to utilising the AUM Sampann Platform in
                accordance with AUM Sampann’s Terms of Use.
              </li>
              <li>
                AUM Sampann, functioning as the third-party technology partner,
                facilitates the seamless retrieval of data from MF Central on
                behalf of AUM Capital Market Pvt Ltd, an AMFI registered Mutual
                Fund Distributor (ARN-64606) that offers the Services.
              </li>
              <li>
                You provide AUM Sampann explicit consent to fetch your requested
                Mutual Fund transaction details from MFCentral via the CAS API
                and add these details to your AUM Sampann account. You further
                provide AUM Capital Market Pvt Ltd explicit consent to retain
                the data fetched from MFCentral in your AUM Sampann account for
                your usage, until you yourself permanently delete the data or
                the AUM Sampann account.
              </li>
              <li>
                You acknowledge that to successfully complete your request to
                fetch the requested data from MFCentral, you will need to
                accurately provide the data required to fulfil such request, to
                AUM Capital Market Pvt Ltd including but not limited to
                Permanent Account Number (PAN), Email Address, Contact Number
                and One Time Password (OTP).
              </li>
              <li>
                In the transaction data retrieved from MFCentral, certain
                transaction types may be normalised as per AUM Sampann
                standards, maintaining the fundamental nature of the data.
                Further, any discrepancies observed in the retrieved data should
                be promptly reported to the AUM Sampann team for resolution by
                writing an email to sampann@aumcap.com.
              </li>
              <li>
                AUM Sampann puts in its best possible efforts to ensure the
                security of your portfolio data.{" "}
              </li>
              <li>
                You retain the option to edit or delete the data you have added
                in your MProfit account at any time.Your consent to the above
                Terms grants you access to avail the Services.
              </li>
            </ul>
            <Typography className="text-justify">
              It is important to note that these Terms are subject to updates
              and any modifications will be communicated. You are responsible
              for staying informed about the latest version of these Terms. By
              providing your acceptance of the Terms in your AUM Sampann account
              before accessing the Services for the first time, you confirm your
              understanding of these Terms and provide your explicit and
              recorded consent to AUM Capital Market Pvt Ltd, for as long as you
              use the Services, to fetch your data from MFCentral in accordance
              with the above Terms. If you do not wish to accept these Terms, it
              is advised that you refrain from using the Services.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsOfUsePage;
