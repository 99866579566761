import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Field {
  name: string;
  label: string;
  type: "text" | "number";
}

interface ModalFormProps {
  fields: Field[];
  values: Record<string, string | number>;
  id: string;
  holdingType: string;
  heading: string;
  handleOpen: () => void;
  handleClose: () => void;
  onSubmit: (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => void;
}

const HoldingsModalForm: React.FC<ModalFormProps> = ({
  fields,
  values,
  id,
  holdingType,
  heading,
  handleOpen,
  handleClose,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<Record<string, string | number>>({
    ...values,
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = (field: string, value: string | number) => {
    setFormData({ ...formData, [field]: value });
    if (value) {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const validateFields = () => {
    const newErrors: Record<string, string> = {};
    fields.forEach((field) => {
      if (!formData[field.name]) {
        newErrors[field.name] = `${field.label} is required.`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit(formData, id, holdingType);
    }
  };

  return (
    <Modal open onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 4,
          py:2,
          borderRadius: 2,
          overflowY:"auto"
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" component="h2">
            {heading}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" flexDirection="column" gap={1} mb={2}>
          {fields.map((field) => (
            <Box
              key={field.name}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ flex: 1, textAlign: "left", marginRight: 2 }}
              >
                {field.label}
              </Typography>
              <TextField
                type={field.type}
                value={formData[field.name] || ""}
                onChange={(e) => handleChange(field.name, e.target.value)}
                error={!!errors[field.name]}
                helperText={errors[field.name] || ""}
                variant="outlined"
                sx={{
                  flex: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: !!errors[field.name]
                        ? "rgb(241, 122, 33) !important"
                        : "rgba(0, 0, 0, 0.23)!important",
                    },
                    "&:hover fieldset": {
                      borderColor: !!errors[field.name]
                        ? "rgb(241, 122, 33)"
                        : undefined,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: !!errors[field.name]
                        ? "rgb(241, 122, 33)"
                        : undefined,
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    color: errors[field.name]
                      ? "rgb(241, 122, 33)!important"
                      : undefined,
                    fontSize: "0.9rem !important",
                  },
                }}
              />
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="center">
        <button
            className="px-4 py-2 text-darkGrey bg-white hover:bg-secondary hover:text-white border border-darkGrey hover:border-secondary rounded mr-4"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-primary hover:bg-secondary text-white border rounded"
            onClick={handleSubmit}
          >
            Save
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default HoldingsModalForm;

