export class DateUtils  {
  private static monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  /**
   * Formats a date string into "DD-MMM-YYYY" format.
   * @param dateString The date string to format (e.g., "22/01/2025", "01/22/2025", "2024-10-15T00:00:00.000Z", etc.).
   * @returns The formatted date string in "DD-MMM-YYYY" format.
   */
  static formatDate(dateString: string): string {
    // Check if the input is an ISO 8601 date string
    if (dateString.includes("T") && dateString.includes("Z")) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = this.monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    }

    // Handle other date formats by normalizing separators to "-"
    const normalizedDateString = dateString.replace(/[/]/g, "-");
    const parts = normalizedDateString.split("-");

    let day: string, month: string, year: string;

    if (parts[0]?.length === 4) {
      // ISO format (YYYY-MM-DD without time)
      year = parts[0];
      month = parts[1];
      day = parts[2];
    } else if (parts[2]?.length === 4) {
      // Check if it's DD-MM-YYYY or MM-DD-YYYY
      if (parseInt(parts[0], 10) > 12) {
        // First part is the day
        day = parts[0];
        month = parts[1];
        year = parts[2];
      } else {
        // First part is the month
        month = parts[0];
        day = parts[1];
        year = parts[2];
      }
    } else {
      throw new Error("Invalid date format");
    }

    const monthName = this.monthNames[parseInt(month, 10) - 1]; // Convert month to month name
    return `${day.padStart(2, "0")}-${monthName}-${year}`;
  }
}

// Optional: If you want to export individual methods or constants
// export default DateUtils; // Uncomment this line if needed
