export class DateUtils {
  private static monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  /**
   * Formats a date string into "DD-MMM-YYYY" format.
   * @param dateString The date string to format (e.g., "22/01/2025", "2024-10-15T00:00:00.000Z").
   * @returns The formatted date string in "DD-MMM-YYYY" format.
   */
  static formatDate(dateString: string): string {
    // Check if the input is an ISO 8601 date string (e.g., "2024-10-15T00:00:00.000Z")
    if (dateString.includes("T") && dateString.includes("Z")) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = this.monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    }

    // Normalize separators to "-" for "DD/MM/YYYY" or "DD-MM-YYYY"
    const normalizedDateString = dateString.replace(/[/]/g, "-");
    const parts = normalizedDateString.split("-");

    let day: string, month: string, year: string;

    if (parts.length === 3) {
      // Assuming DD-MM-YYYY format
      day = parts[0];
      month = parts[1];
      year = parts[2];
    } else {
      throw new Error("Invalid date format. The format should be DD-MM-YYYY or DD/MM/YYYY.");
    }

    // Convert month to month name
    const monthName = this.monthNames[parseInt(month, 10) - 1];
    return `${day.padStart(2, "0")}-${monthName}-${year}`;
  }
}

// Optional: If you want to export individual methods or constants
// export default DateUtils; // Uncomment this line if needed
