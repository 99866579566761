import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import kycService from "../../redux/kyc/kycService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { zeroKyc } from "../../redux/kyc/kycSlice";
import { Formik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import { FormInput } from "../../components/FormInput/FormInput";
import VerifyButton from "../../components/VerifyButton/VerifyButton";
import { IndianStates } from "../../enums/states";
import { updateUser } from "../../redux/user/userSlice";
import toast,{LoaderIcon} from "react-hot-toast";

const aadharValues = {
  aadharNumber: "",
  aadharMobileNumber: "",
  fullName: "",
};

const aadharSchema = yup.object({
  aadharNumber: yup
    .string()
    .required("Aadhar Number is required.")
    .matches(/^([0-9_.])+$/, "Must contain only number"),
  aadharMobileNumber: yup
    .string()
    .required("Mobile Number registered with Pan Card is required.")
    .max(13, "Mobile Number should be 10 digit")
    .min(13, "Mobile Number should be 10 digit")
    .matches(/^([0-9_.+])+$/, "Must contain only number"),
  fullName: yup.string().required(),
});

const DigilockerDetails = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const aadharFormRef = useRef<any>(null);
  const [aadharCard, setAadharCard] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    fetchDigilockerData();
  }, []);


  const fetchDigilockerData = async () => {
    setLoading(true);
    const digilocker_uid = new URLSearchParams(search).get("digilocker_uid");
    const access_token = new URLSearchParams(search).get("access_token");
    console.log(access_token, "digilocker_uid");
    if (access_token !== null) {
      const response = await dispatch(zeroKyc(access_token));
      console.log(response, "response");
      const data = response.payload.data;

      const splitAddress = (address: string) => {
        const chunks = [];
        for (let i = 0; i < address.length; i += 28) {
          chunks.push(address.slice(i, i + 28));
        }
        return chunks;
      };
  
      // Process address fields
      const combinedAddress = `${data?.aadhaar_house}, ${data?.aadhaar_street}, ${data?.aadhaar_location}`;
      const [address1, address2, address3] = splitAddress(combinedAddress);

      const payload = {
        dateOfBirth: data?.aadhaar_dob?.replaceAll("-", "/"),
        address1: address1||"",
        address2: address2||"",
        address3: address3||"",
        city: data?.aadhaar_district,
        state: IndianStates[data?.aadhaar_state],
        pincode: data?.aadhaar_post_code,
        gender: data?.aadhaar_gender === 'M' ? "male" : "female",
        isZeroDoc: 1
      }
      const res = await dispatch(updateUser(payload));
      if(res?.error?.message) {
        toast.error("Something went wrong!");
        return;
      }
      setLoading(false);
      navigate("/onboarding", {
        state: { activeSection: "general", sectionIndex: 2 },
      });
    }
  };
  return (
    <>{
    loading &&(
        <div className="flex justify-center items-center h-[80vh] w-full">
        <LoaderIcon style={{ width: "60px", height: "60px" }}/>
      </div>)}
    </>
  );
};

export default DigilockerDetails;
