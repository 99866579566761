import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { Button, Heading, Input, Text } from "../../components";
import { colors } from "../../constants/colors";
import * as yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import Footer from "../../components/Footer/Footer";
import { IconButton } from "@mui/material";
import {
  Check,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/user/userSlice";

const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required("Password is required.")
    .min(
      8,
      "Password is too short - should be 8 chars minimum with one uppercase, one lowercase and one special character"
    )
    .matches(
    //  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,  //CH11/5
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character" //CH11/5
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm Password is required."),
});

const resetPasswordValues = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [search] = useSearchParams();
  const [token, setToken] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const searchToken = search.get("token");
    if (searchToken) {
      setToken(searchToken);
    }
  }, [search]);

  return (
    <div className="flex flex-col items-center justify-start w-full bg-white">
      <div className="flex flex-col items-center justify-start w-full px-5 py-8 md:px-14 md:py-12 bg-primary shadow-xs min-h-screen">
        <div className="flex flex-col items-center justify-center w-full gap-[31px] max-w-[962px]">
          {!isUpdated ? (
            <>
              <Heading as="h1" className=" text-white text-lg md:text-3xl">
                Reset Password
              </Heading>
              <Formik
                initialValues={resetPasswordValues}
                validationSchema={resetPasswordSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  if (token) {
                    const response = await dispatch(
                      resetPassword({
                        password: values.password,
                        token,
                      })
                    );
                    if (response?.error?.message) {
                      toast.error(response?.payload?.message);
                      return;
                    }
                    setIsUpdated(true);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!isValid && errors.password) {
                        toast.error(errors.password);
                        return;
                      }
                      if (!isValid && errors.confirmPassword) {
                        toast.error(errors.confirmPassword);
                        return;
                      }
                      handleSubmit();
                    }}
                    className="w-full flex flex-col gap-[31px]"
                  >
                    <>
                      <div className="flex flex-col items-start justify-start w-[48%] mx-auto gap-2">
                        <Text
                          size="s"
                          as="p"
                          className="text-center text-white text-sm md:text-lg"
                        >
                          Password
                        </Text>
                        <Input
                          size="sm"
                          name="password"
                          type={passwordVisible ? "text" : "password"}
                          onChange={handleChange("password")}
                          suffix={
                            <IconButton
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              {passwordVisible ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          }
                          value={values.password}
                          className="w-full bg-white"
                        />
                      </div>
                      <div className="flex flex-col justify-start items-center w-full gap-[42px]">
                        <div className="flex flex-col items-start justify-start w-[48%] mx-auto gap-2">
                          <Text
                            size="s"
                            as="p"
                            className="text-center text-white text-sm md:text-lg"
                          >
                            Confirm Password
                          </Text>
                          <Input
                            size="sm"
                            name="confirmPassword"
                            type="password"
                            onChange={handleChange("confirmPassword")}
                            value={values.confirmPassword}
                            className="w-full bg-white"
                          />
                        </div>
                        <Text
                          as="xs"
                          className="text-lightGrey text-center text-sm max-w-[460px] leading-5"
                        >
                          A combination of uppercase letters, lowercase
                          letters,numbers, and symbols. At least 12 characters
                          long but 14 or more is better.
                        </Text>
                      </div>
                      <Button
                        className="w-full md:w-48 font-semibold text-white bg-darkGrey hover:bg-secondary transition-all mx-auto mt-5 py-2"
                        color={colors.darkGrey}
                        variant="fill"
                        type="submit"
                      >
                        <span className="text-sm md:text-lg">Proceed</span>
                      </Button>
                    </>
                  </form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-y-2 mt-5">
                <CheckCircleOutline
                  style={{ fontSize: "8rem", color: "lightgreen" }}
                />
              </div>
              <div className="flex flex-col gap-y-8 items-center justify-center">
                <p className="text-white font-inter text-2xl">
                  Your password has been updated successfully{" "}
                </p>
                <Button
                  className="w-full font-semibold mt-5 text-white bg-darkGrey hover:bg-secondary transition-all"
                  color={colors.darkGrey}
                  variant="fill"
                  onClick={() => navigate("/login")}
                  type="submit"
                >
                  <span className="text-sm md:text-lg">Login to Continue</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
