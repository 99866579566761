import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../../constants/colors";
import upload from "../../../assets/icons/UploadBtn.svg";
import { Button, Img, Text } from "../../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { addBank, verifyBankDetails } from "../../../redux/bank/bankSlice";
import { useDispatch } from "react-redux";
import { useUserData } from "../../../hooks/useUserData";
import toast from "react-hot-toast";
import { FormInput } from "../../../components/FormInput/FormInput";
import FormSelect from "../../../components/FormSelect/FormSelect";
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import { hideLoading, showLoading } from "../../../redux/loader/loaderSlice";
import FileInput from "../../../components/FileInput/FileInput";
import AmountButtons from "../../../components/MFTransaction/AmountButtons";
import { Grid } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../../components/Loader/Loader";

const bankValues = {
  accountType: "",
  accountNumber: "",
  confirmAccountNumber: "",
  ifscCode: "",
  fullName: "",
  bankName: "",
  // bankBranch: "",
  // bankPinCode: "",
  amountMandate: "",
  // bankMICR: "",
  // bankAddress: "",
};

const bankSchema = yup.object({
  accountType: yup.string().required("Account type is required."),
  accountNumber: yup.string().required("Account Number is required."),
  ifscCode: yup.string().required("IFSC code is required."),
  confirmAccountNumber: yup
    .string()
    .oneOf([yup.ref("accountNumber"), ""], "Account number must match")
    .required("Confirm account number is required."),
  fullName: yup.string().required("Full name is required."),
  bankName: yup.string().required("Bank name is required."),
  // bankBranch: yup.string().required(),
  // bankPinCode: yup.string().required(),
  amountMandate: yup.number().required("Mandate amount is required."),
  // bankMICR: yup.string().required(),
  // bankAddress: yup.string().required(),
});

interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  onChange?: any;
  value?: any;
  name?: string;
  disabled?: boolean;
}

interface BankDetailsFormProps {
  onBankToNominee: () => void;
  // Prop to handle transition to Nominee Details
  allowToProceed: () => void;
  userData: any;
}


const accountTypeOptions = [
  { value: "SB", label: "Savings" },
  { value: "NE", label: "NRE" },
  { value: "N0", label: "NRO" },
];

const accountTypeOptions2 = [  
  { value: "CB", label: "Current" },
];

const BankDetailsForm: React.FC<BankDetailsFormProps> = ({
  onBankToNominee,
  allowToProceed,
  userData,
}) => {
  const [cancelCheque, setCancelCheque] = useState<File>();
  const dispatch = useDispatch<any>();
  const formikRef = useRef<any>();
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nameDisabled, setNameDisabled] = useState(true);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("manual");
  const [loading, setLoading] = useState(false);
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [showConfirmAccountNumber, setShowConfirmAccountNumber] = useState(false);


  const toggleAccountNumberVisibility = () => {
    setShowAccountNumber(!showAccountNumber);
  };

  const toggleConfirmAccountNumberVisibility = () => {
    setShowConfirmAccountNumber(!showConfirmAccountNumber);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    formikRef.current?.setFieldValue("fullName", userData?.firstName);
    if (userData?.bank && userData?.bank?.length > 0) {
      setFieldDisabled(true);
      formikRef.current.setValues(userData.bank[0]);
    }
  }, [userData?.bank]);

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal after confirmation
    addBankData();
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = () => {
    if (formikRef.current?.isValid && formikRef.current?.dirty) {
      setIsModalVisible(true);  // Show the modal if form is valid
    }
    else {
      return;
    }
  };

  const checkAlreadySubmitted = () => {
    if (userData?.bank && Object.keys(userData.bank).length > 0) {
      onBankToNominee(); // If bank data exists, proceed to the next step
    } else {
      handleSaveAndProceed(); // Otherwise, trigger the confirmation modal
    }
  };

  const checkBankIFSC = (ifscCode: any) => {
    if (ifscCode.length === 11) {
      setLoading(true);
      fetch(`https://ifsc.razorpay.com/${ifscCode}`)
        .then((response) => response.json())
        .then((data: any) => {
          if (data.BANK) {
            formikRef.current.setFieldValue("bankName", data.BANK);
            // formikRef.current.setFieldValue("bankBranch", data.BRANCH);
            // formikRef.current.setFieldValue("bankMICR", data.MICR);
            // formikRef.current.setFieldValue("bankAddress", data.ADDRESS);
            // formikRef.current.setFieldValue(
            //   "bankPinCode",
            //   data.ADDRESS.slice(-6)
            // );
          } else {
            toast.error("No data found for this IFSC Code");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const addBankData = async () => {
    if (formikRef.current) {
    await formikRef.current.handleSubmit();
      const values = formikRef.current.values;
    try {
      const formData = new FormData();
      formData.append("accountType", values.accountType);
      formData.append("accountNumber", values.accountNumber);
      formData.append("bankName", values.bankName);
      formData.append("amountMandate", values.amountMandate);
      formData.append("ifscCode", values.ifscCode);

      setLoading(true);


      const res = await dispatch(
        verifyBankDetails({
          accountNumber: values.accountNumber,
          ifscCode: values.ifscCode,
          fullName: values.fullName,
        })
      );

      const statusCode = res?.payload?.statusCode;
      const message = res?.payload?.message;

      // console.log('res', res);
      // console.log('message', message, 'Status code', statusCode);

      if (
        (statusCode === 200 && message === 'Bank verified Successfull.') ||
        (statusCode === 400 && message === 'This is unusual. Something went wrong on frslabs servers. Please write to support to resolve this on priority.')
      ) {
        // console.log('success or specific error detected', res);

        await dispatch(addBank(formData));
        toast.success("Bank details added successfully");
        onBankToNominee();
      } else if (statusCode === 400) {
        toast.error("Some error in verifying bank details. Please try again later");
        return;
      }
    } catch (error) {
      toast.error("Some error in adding bank details. Please try again later");
    } finally {
      setLoading(false);
    }
  }
  };


  const handleNameDisabled = () => {
    setNameDisabled(!nameDisabled);
  };

  // console.log('user', useUserData);

  // Mask all digits except the last 4 for account numbers
  const maskAccountNumber = (accountNumber: string) => {
    // If the account number is invalid or empty, return it as is
    if (accountNumber?.length <= 0) return accountNumber;
    
    // Simply return the full account number without masking
    return accountNumber;
  };

  return (
    <>
    {loading && <Loader/>}
      <Formik
        innerRef={formikRef}
        initialValues={bankValues}
        validationSchema={bankSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // console.log(values, "user values");
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <>
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-[31px]"
            >
              <div className="mt-10 sm:mt-20 w-full max-w-[1182px] mx-auto  max-md:mt-10 max-md:max-w-full flex flex-col justify-between">


                <Grid  container spacing={2} alignItems="flex-start" className="flex justify-between items-center">
                  {!fieldDisabled && (<Grid item xs={5}>

                    <FormInput
                      label="Full Name as per the bank account *"
                      type="text"
                      id="fullName"
                      name="fullName"
                      onChange={handleChange("fullName")}
                      value={values.fullName}
                      disabled={nameDisabled}
                      // error={errors.fullName}
                      // touched={touched.fullName}
                      suffix={
                        <span
                          className="text-sm text-secondary cursor-pointer font-semibold"
                          onClick={handleNameDisabled}
                        >
                          {nameDisabled ? "Edit" : "Save"}
                        </span>
                      }
                      style={{
                        marginBottom: "-6px", // Disable the default margin
                      }}   
                    />
                    {touched.fullName && errors.fullName && (
                        <span className="error-text" >
                          {errors.fullName}
                        </span>
                      )}
                  </Grid>)}
                  <Grid item xs={5}>
                    <FormSelect
                      label="Account Type *"
                      type="text"
                      name="accountType"
                      onChange={handleChange("accountType")}
                      value={values.accountType}
                      disabled={fieldDisabled}
                      options={userData?.userType===5?accountTypeOptions2:accountTypeOptions}
                      // error={errors.accountType}
                      // touched={touched.accountType}
                      style={{
                        marginBottom: 0, // Disable the default margin
                      }} 
                    />
                     {touched.accountType && errors.accountType && (
                        <span className="error-text" >
                          {errors.accountType}
                        </span>
                      )}
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      label="Bank Account Number *"
                      type={showAccountNumber ? "text" : "password"}
                      id="accountNumber"
                      name="accountNumber"
                      onChange={handleChange("accountNumber")}
                      value={
                        showAccountNumber
                          ? values.accountNumber
                          : maskAccountNumber(values.accountNumber)
                      }
                      disabled={fieldDisabled}
                      // error={errors.accountNumber}
                      // touched={touched.accountNumber}
                      suffix={
                        <span
                          onClick={toggleAccountNumberVisibility}
                          className="cursor-pointer"
                        >
                          {showAccountNumber ? (
                            <VisibilityOff color="action" />
                          ) : (
                            <Visibility color="action" />
                          )}
                        </span>
                      }
                      style={{
                        marginBottom: "-6px", // Disable the default margin
                      }}   
                    />
                    {touched.accountNumber && errors.accountNumber && (
                        <span className="error-text" >
                          {errors.accountNumber}
                        </span>
                      )}
                  </Grid>
                  {!fieldDisabled && (<Grid item xs={5}>

                    <FormInput
                      label="Confirm Bank Account Number *"
                      type={showConfirmAccountNumber ? "text" : "password"}
                      id="confirmAccountNumber"
                      name="confirmAccountNumber"
                      onChange={handleChange("confirmAccountNumber")}
                      value={showConfirmAccountNumber ? values.confirmAccountNumber : maskAccountNumber(values.confirmAccountNumber)}
                      disabled={fieldDisabled}
                      // error={errors.confirmAccountNumber}
                      // touched={touched.confirmAccountNumber}
                      suffix={
                        <span
                          onClick={toggleConfirmAccountNumberVisibility}
                          className="cursor-pointer"
                        >
                          {showConfirmAccountNumber ? (
                            <VisibilityOff color="action" />
                          ) : (
                            <Visibility color="action" />
                          )}
                        </span>
                      }
                      style={{
                        marginBottom: "-6px", // Disable the default margin
                      }}   
                    />
                    {touched.confirmAccountNumber && errors.confirmAccountNumber && (
                        <span className="error-text" >
                          {errors.confirmAccountNumber}
                        </span>
                      )}
                  </Grid>)}
                  <Grid item xs={5}>
                    <FormInput
                      label="IFSC Code *"
                      type="text"
                      id="ifscCode"
                      name="ifscCode"
                      onChange={(e: any) => {
                        checkBankIFSC(e.target.value);
                        setFieldValue("ifscCode", e.target.value.toUpperCase());
                      }}
                      value={values.ifscCode}
                      disabled={fieldDisabled}
                      // error={errors.ifscCode}
                      // touched={touched.ifscCode}
                      style={{
                        marginBottom: "-6px", // Disable the default margin
                      }}   
                    />
                    {touched.ifscCode && errors.ifscCode && (
                        <span className="error-text" >
                          {errors.ifscCode}
                        </span>
                      )}
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      label="Bank Name *"
                      type="text"
                      id="bankName"
                      name="bankName"
                      onChange={handleChange("bankName")}
                      value={values.bankName}
                      disabled={true}
                      // error={errors.bankName}
                      // touched={touched.bankName}
                      style={{
                        marginBottom: "-6px", // Disable the default margin
                      }}   
                    />
                    {touched.bankName && errors.bankName && (
                        <span className="error-text" >
                          {errors.bankName}
                        </span>
                      )}
                  </Grid>
                  {/* <Grid item xs={5}>
                    <FormInput
                      label="Bank Branch *"
                      type="text"
                      id="bankBranch"
                      name="bankBranch"
                      onChange={handleChange("bankBranch")}
                      value={values.bankBranch}
                      disabled={true}
                      error={errors.bankBranch}
                      touched={touched.bankBranch}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      label="Bank Address *"
                      type="text"
                      id="bankAddress"
                      name="bankAddress"
                      onChange={handleChange("bankAddress")}
                      value={values.bankAddress}
                      disabled={true}
                      error={errors.bankAddress}
                      touched={touched.bankAddress}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormInput
                      label="Bank Pincode *"
                      type="text"
                      id="bankPinCode"
                      name="bankPinCode"
                      onChange={handleChange("bankPinCode")}
                      value={values.bankPinCode}
                      disabled={true}
                      error={errors.bankPinCode}
                      touched={touched.bankPinCode}
                    />
                  </Grid> */}
                  {/* <Grid item xs={5}>
                    <FormInput
                      label="Bank Amount Mandate *"
                      type="number"
                      id="amountMandate"
                      name="amountMandate"
                      onChange={handleChange("amountMandate")}
                      value={values.amountMandate}
                      disabled={fieldDisabled}
                      error={errors.amountMandate}
                      touched={touched.amountMandate}
                    />
                  </Grid>  */}
                  {!fieldDisabled && <Grid item xs={5}>
                    <AmountButtons
                      label="Enter Bank Amount Mandate *"
                      setFieldValue={setFieldValue}
                      selectedAmount={selectedAmount}
                      setSelectedAmount={setSelectedAmount}
                      fieldName="amountMandate"
                      values={values}
                      errors={errors}
                      touched={touched}
                      fieldDisabled={fieldDisabled}
                      // ingnoreAmount={['100000']}

                    />
                  </Grid>}

                  {/* <Grid item xs={5}>
                    <FileInput
                      label="Original Canceled Cheque *"
                      type="file"
                      id="CanceledCheque"
                      onChange={(e: any) =>
                        setCancelCheque(e.target.files[0])
                      }
                      disabled={fieldDisabled}
                      error={!cancelCheque ? "Please upload Canceled Cheque" : ""}
                    // touched={touched.bankPinCode}
                    />
                  </Grid> */}
                </Grid>

                <div className="ml-4 sm:ml-2 mb-16">
                  <div className="mt-10 mb-5 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                      <div className="flex flex-col max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                          <div className="flex gap-5 justify-between  mt-12 text-sm max-md:mt-10">
                            {/* Enable/disable buttons based on radioSelected */}
                            {/* <button
                                style={{
                                  backgroundColor: colors.white,
                                  color: colors.darkGrey,
                                  border: `1px solid ${colors.darkGrey}`,
                                }}
                                className={`grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-solid `}
                                tabIndex={0}
                                onClick={() => {}} // Add onClick event handler // Disable button if radio is not selected
                              >
                                Skip
                              </button> */}
                            <Button
                              type="submit"
                              className="form-button"
                              tabIndex={0}
                              onClick={checkAlreadySubmitted}
                            // onClick={() => updateUserRef.current.click()} // Add onClick event handler
                            // Disable button if radio is not selected
                            >
                              Save and Proceed
                            </Button>
                            <ConfirmationModal
                              isVisible={isModalVisible} // Render conditionally based on state
                              message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                              onConfirm={handleModalConfirm} // Confirm action and submit form
                              onCancel={handleModalCancel} // Cancel action and close modal
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default BankDetailsForm;
