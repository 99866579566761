import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
  ListItem,
  MenuItem,
  Divider,
  Menu,
  Fade,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InvestModal from "../modals/investModalPMS";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DateUtils } from "../../utils/FormatDate";

const TableRowComponent = ({ data }: any) => {
  const isArray = Array.isArray(data);
  const navigate=useNavigate();
  const user = useUserData();
  const checkPMS = user?.preferences?.pms;
  const dispatch = useDispatch<any>();
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const [openRows, setOpenRows] = useState<boolean[]>(
    new Array(data.length).fill(false)
  );
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const [isInvestModalOpen, setIsInvestModalOpen] = useState<boolean>(false);
  const [Fund, setFund] = useState({
    id: 0,
    FundManager: "",
    AssetName: "",
    Category: "",
    AumInCr: "",
    NAV:0,
    ReturnSinceInception: "",
    RaisedBar:"" ,
    IsInWishlist:0
  });

  const handleToggleRow = (index: number) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const openMenuMD = Boolean(anchorMD);

  const modalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    FundManager: string,
    AssetName: string,
    Category: string,
    AumInCr: string,
    NAV:number,
    ReturnSinceInception: string,
    RaisedBar:string ,
    IsInWishlist:number
  ) => {
    setAnchorMD(event.currentTarget);
    setFund({
      id: id,
      FundManager: FundManager,
      AssetName: AssetName,
      Category: Category,
      AumInCr: AumInCr,
      NAV:NAV,
      ReturnSinceInception: ReturnSinceInception,
      RaisedBar:RaisedBar ,
      IsInWishlist:IsInWishlist
    });
  };

  const Watchlist = async () => {
    if (isinWishilist.status === 1) {
      const data = { itemId: isinWishilist.id, itemType: 6 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 0 });
        toast.success("Removed from watchlist");
      } else {
        toast.error("Failed to remove from watchlist");
      }
    } else if (isinWishilist.status === 0) {
      const data = { itemId: isinWishilist.id, itemType: 6 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 1 });
        toast.success("Added to watchlist");
      } else {
        toast.error("Failed to add to watchlist");
      }
    }
  };


  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const openInvestModal = () => {
    setIsInvestModalOpen(true);
  };
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
    // fetchData();
  };

  const isValidAssetName = (assetName: string) => {
    return assetName && assetName !== "-" && assetName.trim() !== "";
  };
  console.log(data,"PMS data");

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          return (            
            <>
            {item?.assetName && isValidAssetName(item?.assetName) && (
              <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg cursor-pointer" onClick={() => navigate(`/pms-details/${item?.id}`, { state: { schemes: item }})}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "none" }}
                  className="min-w-[150px]"
                >
                  <div className="flex gap-x-2 items-center">
                    <div className="w-3/5">
                      <span
                        className="cursor-pointer"
                        // onClick={() => handleToggleRow(index)}
                      >
                        {item?.assetName}
                      </span>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.fundManager || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {(item?.inceptionDate && item?.inceptionDate!=="-") ? DateUtils.formatDate(item.inceptionDate) : "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.aumInCr || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.abs1Month==="-" || item?.abs1Month==="--"?"-":item?.abs1Month+"%"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.ann1Year==="-"||item?.ann1Year==="--"?"-":item?.ann1Year+"%"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                  className="min-w-[150px]"
                >
                  {item?.returnSinceInception==="-"||item?.returnSinceInception==="--"?"-":item?.returnSinceInception+"%"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    border: "none",
                  }}
                  className="min-w-[100px] flex justify-between"
                >
                  {/* <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleToggleRow(index)}
                  >
                    {openRows[index] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton> */}
                  <IconButton
                    onClick={(e) => {
                      modalMenuClick(
                        e,
                        item?.id,
                        item?.fundManager,
                        item?.assetName,
                        item?.category,
                        item?.aumInCr,
                        item?.currentNAV,
                        item?.returnSinceInception,
                        item?.raisedBar,
                        item?.isInWishlist
                      );setIsinWishilist({status:item?.isInWishlist,id:item?.id});
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow sx={{ paddingBottom: "40px" }}>
                <TableCell
                  className="tableCells"
                  colSpan={12}
                  sx={{
                    padding: "0",
                  }}
                >
                  <Collapse
                    in={openRows[index]}
                    timeout="auto"
                    unmountOnExit
                    className=""
                  >
                    <Table aria-label="purchases">
                      <TableBody
                        className="border-l-[1px] border-r-[1px] border-b-[1px]"
                        sx={{
                          "&.css-1la361y-MuiTableCell-root": {
                            padding: "0!important",
                          },
                        }}
                      >
                        <TableRow sx={{ height: "5%" }}>
                          <TableCell
                            sx={{ border: "none" }}
                            colSpan={8}
                            align="center"
                          ></TableCell>
                        </TableRow>
                        <Container className="min-w-full my-2">
                          <TableRow
                            sx={{ border: "none", padding: "0" }}
                            className="cursor-pointer"
                          >
                            <div className="flex gap-x-2 items-center  justify-start subcategory px-4">
                              <ul className="list-disc text-justify">
                                <li>{item?.pmsDescription}</li>
                              </ul>
                            </div>
                          </TableRow>
                        </Container>
                        <TableRow sx={{ height: "5%" }}>
                          <TableCell
                            sx={{ border: "none" }}
                            colSpan={8}
                            align="center"
                          ></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
              {item?.assetName && isValidAssetName(item?.assetName) && (
              <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow>)}
            </React.Fragment>
        )}
            </>
          );
        })}

      {isInvestModalOpen && (
        <InvestModal
          open={isInvestModalOpen}
          onClose={closeInvestModal}
          fundInfo={Fund}
        />
      )}
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: "12%",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem
            button
            sx={{ marginLeft: "-25px"}}
          >
            <MenuItem
              className="text-xs sm:text-sm"
              onClick={Watchlist}>{isinWishilist?.status === 1 ? "Remove from watchlist" : 'Add to Watchlist'}
            </MenuItem>
          </ListItem>
          <Divider
            sx={{
              backgroundColor: "#fff",
            }}
          />
          <ListItem
            button
            sx={{ marginLeft: "-25px" }}
              onClick={checkPMS === 1 ? openInvestModal : () => {}}
          >
            <MenuItem
              className="cursor-pointer hover:bg-slate-500"
              onClick={openInvestModal}
            >
              Invest
            </MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
    </>
  );
};

export default TableRowComponent;
