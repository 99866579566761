import React, { FC, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import plus from "../../../src/assets/icons/plus-circle-outline.png";
import MfInfo from "../../components/MFInfoSection/MFInfo";
import Alert from "@mui/material/Alert";
import getInitials from "../../utils/getInitailasIMGUtils";
import MFDetailCard from "./MFDetailCard";
import { Formik } from "formik";
import * as yup from "yup";
import AmountCard from "./AmountCard";
import { FormInput } from "../FormInput/FormInput";
import dayjs from "dayjs";
import FormSelect from "../FormSelect/FormSelect";
import FormDatePicker from "../FormDatePicker/FormDatePicker";
import { getAmountInWords } from "../../utils";
import AmountButtons from "./AmountButtons";
import MFFooterCard from "./MFFooterCard";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { createPayment, InvestMF, InvestRequest } from "../../redux/MF/MFSlice";
import { showInterest } from "../../redux/user/userSlice";
import toast from "react-hot-toast";
import { Button as Btn, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import SuccessfullModal from "../modals/SuccessfullModal";
import { useUserData } from "../../hooks/useUserData";
import { BrowserPopUpModal } from "../../utils/BrowserPopUpModal";
import CustomDialog from "../modals/BsePaymentAutherizationModal";

interface MFProps {
  activeSection: string;
  fundInfo?: any;
  fundId: number;
  onClose: any;
}

interface FormInputProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  name?: string;
  onChange: any;
  value?: any;
  disabled?: boolean;
}

// const FormInput: React.FC<FormInputProps> = ({
//   label,
//   type,
//   id,
//   altText,
//   name,
//   onChange,
//   value,
//   disabled = false,
// }) => {
//   if (
//     label === "Select Bank Account" ||
//     label === "Source Scheme Name" ||
//     label === "SWP Type" ||
//     label === "SWP Frequency"
//   ) {
//     return (
//       <div
//         className="flex flex-col text-lg mb-10"
//         style={{ color: colors.darkGrey }}
//       >
//         <label htmlFor={id} className="" style={{ color: colors.darkGrey }}>
//           {label}
//         </label>
//         <select
//           id={id}
//           className="shrink-0 mt-3 rounded-lg p-4 gap-10"
//           style={{
//             color: colors.darkGrey,
//             backgroundColor: colors.white,
//             border: `1px solid ${colors.darkBg}`,
//           }}
//           aria-label={altText || label}
//           name={name}
//           onChange={onChange}
//           value={value}
//           disabled={disabled}
//         >
//           <option value="Select Account Type"></option>
//           <option value="acc1">Option 1</option>
//           <option value="acc2">Option 2</option>
//           <option value="acc3">OPtion 3</option>
//         </select>
//       </div>
//     );
//   } else {
//     return (
//       <div
//         className="flex flex-col text-lg mb-10 "
//         style={{ color: colors.darkGrey }}
//       >
//         <label htmlFor={id} className="" style={{ color: colors.darkGrey }}>
//           {label}
//         </label>
//         <input
//           className="shrink-0 mt-3 rounded-lg p-4 "
//           style={{
//             color: colors.darkGrey,
//             backgroundColor: colors.white,
//             border: `1px solid ${colors.darkBg}`,
//           }}
//           type={type}
//           id={id}
//           aria-label={altText || label}
//           name={name}
//           onChange={onChange}
//           value={value}
//           disabled={disabled}
//         />
//       </div>
//     );
//   }
// };

const SWP: FC<MFProps> = ({ fundId, fundInfo, onClose }) => {
  const swpRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const userData=useUserData();
  const [swpFrequencies, setSwpFrequencies] = useState([]);
  const [swpFrequencyData, setSwpFrequencyData] = useState<any>({});
  const [investmentPlan, setInvestmentPlan] = useState<string>(
    "Existing investment"
  );
  const [swpDates, setSwpDates] = useState<string[]>([]);
  const [swpEndDate, setSwpEndDate] = useState<string>();
  const [selectedAmount, setSelectedAmount] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const [button, setButton] = useState(false);
  const [InterestOpen, setInterestOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authLink, setAuthLink] = useState("");
  const [orderID, setOrderID] = useState("");
  const [loadmessage, setLoadMessage] = useState<string>("");
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [fundParams, setFundParams] = useState<any>({
    "Total Units": fundInfo?.totalUnits||(fundInfo?.currentValue/fundInfo?.currentNAV).toFixed(2),
    "Current Value": fundInfo?.currentValue,
    // {
    //   label: "Available Clear Units",
    //   value: "7489",
    // },
    // {
    //   label: "Min. Units",
    //   value: "0.01",
    // },
  });
  const [Open, setOpen] = useState<boolean>(false);
  const { bank } = useUserData() || { bank: [] };
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [swpSchema, setSwpSchma] = useState<any>(
    yup.object({
      sourceScheme: yup.string().required("Source scheme is required."),
      folioNo: yup.string().required("Folio number is required."),
      startDate: yup.string().required("Start date is required."),
      frequency: yup.number().required("Frequency is required."),
      noOfInstallment: yup.number().required("No. of installment is required."),
      amount: yup.number().required("Amount is required."),
      bank: yup.number().required("Bank Account is required.")
    })
  );

  const [swpValues, setSwpValues] = useState({
    orderType: 3, // 1 for lumpsump, 2 for sip, 3 for swp, 4 for stp
    newOrder: 1,
    fundId: 0,
    amount: 0,
    bankId: 0,
    folioNumber: "",
    dividend: "",
    startDate: "",
    frequency: 0,
    noOfInstallment: 0,
    firstOrderToday: "",
    units: 0,
    transType: "",
    STPType: "",
    buySellType: "",
    toSchemeId: 0,
    folioNo: "",
    sourceScheme: ""
  });

  useEffect(() => {
    if (fundInfo && swpRef.current) {
      console.log(fundInfo.schemeName, "fundInfo.schemeName");
      swpRef.current?.setFieldValue("sourceScheme", fundInfo?.schemeName);
      swpRef.current?.setFieldValue("folioNo", fundInfo?.folioNumber[0]?.folio || "112233");
    }
    if (fundInfo?.swpInfo?.length > 0) {
      const fqs: any = [];
      const data: any = {};
      fundInfo?.swpInfo?.map((info: any) => {
        data[info?.swpFrequency] = {
          minSwp: info?.swpMinimumInstallmentAmount,
          maxSwp: info?.swpMaximumInstallmentAmount,
          minInstallment: info?.swpMinimumInstallmentNumber,
          maxInstallment: info?.swpMaximumInstallmentNumber,
        };
        info?.swpFrequency === 1 && fqs.push({ label: "Daily", value: 1 });
        info?.swpFrequency === 2 && fqs.push({ label: "Weekly", value: 2 });
        info?.swpFrequency === 3 && fqs.push({ label: "Monthly", value: 3 });
        info?.swpFrequency === 4 && fqs.push({ label: "Quarterly", value: 4 });
      });
      console.log(data, "data stp");
      setSwpFrequencyData(data);
      setSwpFrequencies(fqs);
    }
  }, [fundInfo]);

  useEffect(() => {
    if (swpFrequencies.length > 0) {
      setSwpSchma(
        yup.object({
          folioNo: yup.string(),
          amount: yup
            .number()
            .required("Amount is required.")
            .when("frequency", (frequency, schema) => {
              const { minSwp, maxSwp } = swpFrequencyData[
                parseInt(frequency[0])
              ] || { minSwp: "100", maxSwp: "9999999" };
              return schema
                .min(
                  minSwp,
                  `Amount must be greater than or equal to ${minSwp}`
                )
                .max(maxSwp, `Amount must be less than or equal to ${maxSwp}`);
            }),
          startDate: yup
            .string()
            .test(
              "is-valid-start-date",
              `Invalid start date. Start date should be: ${swpDates}`,
              function (value) {
                if (!value) return true;
                console.log(value, "selected date");
                const date = dayjs(value, "DD/MM/YYYY").format("D");
                console.log(swpDates, "sip dates");
                if (!swpDates) return true;
                return swpDates?.includes(date);
              }
            )
            .required("Start date is required."),
          frequency: yup.number().required(),
          noOfInstallment: yup
            .number()
            .when("frequency", (frequency, schema) => {
              const f = parseInt(frequency[0]);
              if (f === 1) return schema;
              return schema.required("No. of Installment is required.");
            })
            .when("frequency", (frequency, schema) => {
              const { minInstallment, maxInstallment } = swpFrequencyData[
                parseInt(frequency[0])
              ] || { minInstallment: "5", maxInstallment: "50" };
              return schema
                .min(
                  minInstallment,
                  `Installment must be more than or equal to ${minInstallment}`
                )
                .max(
                  maxInstallment,
                  `Installment must be less than or equal to ${maxInstallment}`
                );
            }),
          bankId: yup.string().required("Bank Account is required."),
        })
      );
    }
  }, [swpFrequencies && swpDates]);

  const onFrequencyChange = (value: any) => {
    fundInfo?.swpInfo?.map((info: any) => {
      if (info?.swpFrequency == value) {
        setSwpDates(info?.swpDates);
      }
    });
  };

  const calculateEndDate = (
    frequency: any,
    startDate: any,
    numOfInstallments: any
  ) => {
    console.log(
      frequency,
      numOfInstallments,
      "frequency, numOfInstallments, startDate"
    );
    if (!frequency || !numOfInstallments || !startDate) return;
    let endDate = dayjs(startDate, "DD/MM/YYYY");
    console.log(frequency, "EndDate");
    switch (frequency) {
      case 1:
        endDate = endDate.add(numOfInstallments - 1, "day");
        break;
      case 2:
        endDate = endDate.add(numOfInstallments - 1, "week");
        break;
      case 3:
        endDate = endDate.add(numOfInstallments - 1, "month");
        break;
      case 4:
        endDate = endDate.add((numOfInstallments - 1) * 3, "month");
        break;
      default:
        break;
    }

    const endDateValue = endDate.format("DD/MM/YYYY");
    if (endDateValue) {
      setSwpEndDate(endDateValue);
    }
  };

  const investInSWP = async (values: any) => {
    try {
      const payload = {
        fundId: fundId.toString(),
        orderType: "3",
        startDate: values.startDate,
        noOfWithdrawls: values.noOfInstallment,
        frequency: values.frequency,
        amount: parseInt(values.amount),
        units: "",
        transType: "NEW",
        firstOrderToday:
          values.startDate === dayjs(new Date()).format("dd-MM-yyyy")
            ? "Y"
            : "N",
        bankId: parseInt(bank[0]?.id),
        folioNo: values.folioNo,
        isDirectAllowed: userData?.preferences?.direct===1?1:0,
      };
      setLoading(true);
      setLoadMessage("Processing your request, please wait 30s to 40s...");
      handleCloseDialog();
      const res = await dispatch(InvestMF(payload));
      setOrderID(res?.payload?.data?.data?.orderId);
        if (res?.payload?.data?.success === 200) {
          setLoading(false);
          const authLink = res?.payload?.data?.data?.authLink;
            setAuthLink(authLink);
            setAuthModalOpen(true); // Open modal with auth link
        } else {
          setLoading(false);
          setOpen(true);
          setErrorMessage(
            "Error in executing your order."
          );
        }

      } catch (error) {
        setOpen(true);
        setErrorMessage("Investment Failed");
      } finally {
      dispatch(hideLoading());
      handleCloseDialog();
    }
  };

  const handleOpenDialog = () => {
    const values = swpRef.current?.values;
  
    // Extract values directly from `values` or use defaults
    const amount = parseFloat(values?.amount || "0");
    const sipDate = values?.startDate || "N/A";
    
    // Single-line frequency mapping
    const frequency = 
                  values?.frequency === 3 ? "Monthly" :
                  values?.frequency === 4 ? "Quarterly" :
                  "N/A";
    
    // Fetching the folio number based on investment plan
    const folioNo = investmentPlan === "Existing investment" ? values?.folioNo || "N/A" : "N/A";
    
    // Assuming current NAV is available
    const currentNAV = parseFloat(fundInfo?.currentNAV || "0");
    const units = currentNAV > 0 ? (amount / currentNAV).toFixed(2) : "N/A";
    
    // Formatting firstOrderToday
    const firstOrderToday = values?.startDate === dayjs(new Date()).format("DD-MM-YYYY") ? "Y" : "N";
    
    // Summary data object including all relevant payload information
    const summary = {
      STPType: values?.STPType || "N/A",
      Amount: amount.toFixed(2),
      BuySellType: values?.buySellType || "N/A",
      FirstOrderToday: firstOrderToday,
      FolioNumber: folioNo,
      Frequency: frequency,
      FundId: values?.fundId || "N/A",
      NoOfWithdrawals: values?.noOfInstallment || "N/A", // assuming `noOfInstallment` corresponds to `NoOfWithdrawals`
      OrderType: values?.orderType || "N/A",
      StartDate: sipDate,
      BankId: values?.bankId || "N/A",
      ToSchemeId: values?.toSchemeId || "N/A",
      TransType: values?.transType || "N/A",
      Units: units,
    };
  console.log(summary,"summary");
    setSummaryData(summary);
    setOpenDialog(true);
  };
  
  
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async () => {
    handleCloseDialog();
    const values = swpRef.current?.values;
    await investInSWP(values);
  };

  
  const showingInterest = async () => {
    try {
      const response = await dispatch(
        showInterest({ id: fundId, holdingType: 2 })
      );

      if (response?.payload?.statusCode === 200) {
        setInterestOpen(true);
        setMessage(`Your interest has been successfully submitted to your RM. You can also reach out to your RM ${userData?.relationManager?.firstName} at ${userData?.relationManager?.mobile}.`);
      } else {
        setInterestOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setInterestOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  const handleInterestClose = () =>{
    setInterestOpen(false);
  }
  const handleClose=()=>{
    setOpen(false);
    onClose();
  }
  useEffect(() => {
    // Check if the popup window is closed every 100 milliseconds
    const checkPopupClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        setLoading(false);
        clearInterval(checkPopupClosed); // Stop checking once the popup is closed
        handleAuthModalClose();
      }
    }, 100);

    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [popupWindow]);

  const openAuthLink = () => {
    setAuthModalOpen(false);
    setLoadMessage("Please authenticate your order and close the bse tab...");
    setLoading(true);
    if (authLink) {
      const newPopupWindow =  BrowserPopUpModal(authLink, "BSE Autherization",1250,550);
      setPopupWindow(newPopupWindow);
    }
  };
  const handleAuthModalClose = async () => {
    setAuthModalOpen(false); // Close the auth link modal
      try {
        const response=await dispatch(
          createPayment({
            orderId: orderID,
            modeofpayment: "DIRECT",
            vpaid: "",
            bankid: parseInt(bank[0]?.id),
            isDirectAllowed: userData?.preferences?.direct===1?1:0,
          })
        );
        setOpen(true);
        if(response.payload.data.statusCode===200){          
          setButton(response.payload.data.statusCode===200);
          setMessage("STP Transaction completed successfully.");
           // Open the modal once data is fetched
        }
        else{
          setErrorMessage(response.payload.data.message);
        }
      } catch (error) {
        console.error("Authentication is failed", error);
      }
  };

  return (
    <>
      <div className="bg-lightBg pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white">
              <div className="md:px-8 py-10">
                <MFDetailCard fundInfo={fundInfo} />
                <Formik
                  innerRef={swpRef}
                  initialValues={swpValues}
                  validationSchema={swpSchema}
                  onSubmit={async (values) => {
                    // console.log(validateAmount(values.amount), "vadliate");
                    // if (validateAmount(values.amount)) return;
                    handleOpenDialog()
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <>
                      <div className="my-5">
                        <form onSubmit={(e) => {
                          e.preventDefault();
                           // Check if either totalUnits or currentValue is missing
                           if (!(fundInfo?.currentValue/fundInfo?.currentNAV).toFixed(2)|| !fundInfo?.currentValue) {
                            return; // Do nothing if both are missing
                          }
                      
                          // Log errors and call handleSubmit if validation passes
                          console.log(errors);
                          handleSubmit();
                        }}>
                          <div className="flex flex-row w-full gap-5 mt-10">
                            <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <FormInput
                                label="Folio Number"
                                name="folioNo"
                                type={"text"}
                                value={values.folioNo}
                                disabled={true}
                                onChange={handleChange("folioNo")}
                              />
                            </div>
                            <div className="w-full sm:w-3/4 px-8">
                              <h6 className="mr-5 text-textGrey text-lg">
                                Exit Load:{" "}
                              </h6>
                              <p className="mt-4 font-inter">
                                {fundInfo?.exitLoad}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
                            <div className="w-full sm:w-1/2 sm:mb-0">
                              <div className="flex flex-wrap gap-5 pt-5 justify-center md:justify-start">
                                {Object.keys(fundParams).map(
                                  (label: any) =>
                                    fundParams[label] !== "" &&
                                    fundParams[label] != null && (
                                      <AmountCard
                                        label={label}
                                        value={fundParams[label]}
                                      />
                                    )
                                )}
                              </div>
                            </div>
                            <div className="w-full sm:w-1/2 px-8">
                              <h6 className="mr-5 text-textGrey text-lg">
                                Free Units:{" "}
                              </h6>
                              <p className="mt-4 font-inter">
                                {fundInfo?.freeUnits}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <h6
                              className="font-medium text-xl mb-5 mt-10"
                              style={{ color: colors.darkGrey }}
                            >
                              SWP Details
                            </h6>
                          </div>
                          <div className="flex flex-row w-full gap-5">
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              <FormSelect
                                label="SWP Frequency"
                                name="frequency"
                                options={swpFrequencies}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "frequency",
                                    parseInt(e.target.value)
                                  );
                                  onFrequencyChange(e.target.value);
                                  calculateEndDate(
                                    parseInt(e.target.value),
                                    values.startDate,
                                    values.noOfInstallment
                                  );
                                }}
                                value={values.frequency}
                                error={errors.frequency}
                                touched={touched.frequency}
                              />
                            </div>

                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              {/* <FormInput
                              label="SIP Date"
                              name="date"
                              type=""
                              id={""}
                              onChange={undefined}
                            /> */}
                            </div>
                          </div>
                          <div className="flex flex-row w-full gap-5">
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              <FormDatePicker
                                label="SWP Start Date"
                                name="startDate"
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? date.format("DD/MM/YYYY")
                                    : "";
                                  setFieldValue("startDate", formattedDate);
                                  calculateEndDate(
                                    values.frequency,
                                    formattedDate,
                                    values.noOfInstallment
                                  );
                                }}
                                value={
                                  values.startDate
                                    ? dayjs(values.startDate, "DD-MM-YYYY")
                                    : null
                                }
                                defaultValue={dayjs(new Date())}
                                error={errors.startDate}
                                touched={touched.startDate}
                                maxDate={dayjs().add(1, "year")}
                                hintText={
                                  swpDates &&
                                  `Suggested SWP start dates: ${swpDates}`
                                }
                              />
                            </div>
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              {values.frequency !== 1 && (
                                <FormInput
                                  label="No. of installments"
                                  name="noOfInstallment"
                                  type={"number"}
                                  onChange={(e: any) => {
                                    let value = e.target.value;
                                    // Remove leading zero when user starts typing
                                    if (
                                      value.startsWith("0") &&
                                      value.length > 1
                                    ) {
                                      value = value.replace(/^0+/, ""); // Remove leading zeros
                                    }
                                    setFieldValue(
                                      "noOfInstallment",
                                       value
                                    );
                                    calculateEndDate(
                                      values.frequency,
                                      values.startDate,
                                      parseInt(value)
                                    );
                                  }}
                                  value={values.noOfInstallment}
                                  error={errors.noOfInstallment}
                                  touched={touched.noOfInstallment}
                                // onChange={() => { calculateInstallmentCount() }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex flex-row w-full gap-5">

                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              <FormDatePicker
                                label="SWP End Date"
                                name="endDate"
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? date.format("DD/MM/YYYY")
                                    : "";
                                  setSwpEndDate(formattedDate);
                                }}
                                value={
                                  swpEndDate
                                    ? dayjs(swpEndDate, "DD-MM-YYYY")
                                    : null
                                }
                                defaultValue={dayjs(new Date())}
                                disabled
                              />
                            </div>
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              {/* <FormInput
                                label="Amount"
                                name="amount"
                                type="number"
                                id="amount"
                                onChange={(e: any) => {
                                  setFieldValue("amount", e.target.value);
                                  setSelectedAmount(e.target.value);
                                }}
                                value={values.amount}
                                error={errors.amount}
                                touched={touched.amount}
                                hintText={getAmountInWords(values.amount)}
                              /> */}
                              {/* {typeof errors.amount === "string" &&
                              touched.amount && (
                                <h5 className="text-red-600">
                                  {errors.amount}
                                </h5>
                              )} */}
                            </div>
                          </div>
                          {/* <div className="flex justify-between items-center">
                            <h6
                              className="font-medium text-xl mb-5 "
                              style={{ color: colors.darkGrey }}
                            >
                              OR Select recommended amount
                            </h6>
                          </div> */}
                          <AmountButtons
                            label="Amount"
                            setFieldValue={setFieldValue}
                            selectedAmount={selectedAmount}
                            setSelectedAmount={setSelectedAmount}
                            fieldName="amount"
                            values={values}
                            errors={errors}
                            touched={touched}
                            currentValue={fundParams["Current Value"]}
                          />
                          {/* <div className="flex flex-row w-full gap-5">
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <h6
                              className="font-medium text-xl mt-10 mb-3"
                              style={{ color: colors.darkGrey }}
                            >
                              Mode Of Payment
                            </h6>
                            <label className="flex items-center">
                              <input
                                type="radio"
                                checked={true}
                                name="modeOfPayment"
                                className={`mr-2`}
                              />
                              Autopay
                            </label>
                          </div>
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <h6
                              className="font-medium text-xl mt-10 mb-3"
                              style={{ color: colors.darkGrey }}
                            >
                              SIP Step Up (in %)
                            </h6>
                            <QuantityInput />
                          </div>
                        </div> */}
                          {/* <div className="w-full my-10">
                            <MFFooterCard
                              onBankChange={handleChange("bankId")}
                              bankValue={values.bankId}
                              bankError={errors.bankId}
                              bankTouched={touched.bankId}
                            />
                          </div> */}
                          <Alert
                            variant="outlined"
                            severity="info"
                            sx={{
                              color: colors.textGrey,
                              border: "1px solid",
                              backgroundColor: colors.bgGrey,
                            }}
                          >
                            Day of Debit to investor’s account: T day <br />
                            Day of Unit Allotment: T + 1 day <br />
                            Cut-off time: 3:00 PM
                          </Alert>
                          <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                              <div className="flex flex-col max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                                  <div className="flex gap-5 justify-between   lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                                  <Button
                                      type="submit"
                                      className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid ${
                                        !fundInfo?.totalUnits &&
                                        !fundInfo?.currentValue
                                          ? "opacity-50 cursor-not-allowed"
                                          : "cursor-pointer hover:bg-primary"
                                      }`}
                                      tabIndex={0}
                                      style={{
                                        cursor:
                                          !fundInfo?.totalUnits &&
                                          !fundInfo?.currentValue
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      Redeem
                                      {/* Proceed to payment */}
                                    </Button>
                                    <Button
                                  type="button"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    onClick={showingInterest}
                                  >
                                    Show Interest
                                    {/* Proceed to payment */}
                                  </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </form>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
          >
           <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
           <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
              <Typography variant="h4" className="font-bold mb-4">
                Summary
              </Typography>
              <DialogContent className="flex flex-col">
              {summaryData ? (
  <>
    <div className="flex justify-between mb-4">
      <span className="font-semibold text-gray-700">Amount:</span>
      <span>Rs. {summaryData.Amount}</span>
    </div>
    <div className="flex justify-between mb-4">
      <span className="font-semibold text-gray-700">Folio Number:</span>
      <span>{summaryData.FolioNumber}</span>
    </div>
    <div className="flex justify-between mb-4">
      <span className="font-semibold text-gray-700">Frequency:</span>
      <span>{summaryData.Frequency}</span>
    </div>
    <div className="flex justify-between mb-4">
      <span className="font-semibold text-gray-700">Start Date:</span>
      <span>{summaryData.StartDate}</span>
    </div>
    <div className="flex justify-between mb-4">
      <span className="font-semibold text-gray-700">Approx. Units:</span>
      <span>{summaryData.Units}</span>
    </div>
  </>
) : (
  <div className="flex justify-center items-center">
    <span>Loading...</span>
  </div>
)}


              </DialogContent>
              <DialogActions>
                <Btn
                    onClick={handleConfirmDialog}
                    color="primary"
                    sx={{
                      backgroundColor: colors.primary,
                      borderRadius: "40px",
                      color: colors.lightBg,
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: colors.lightBg,
                      },
                    }}
                  >
                    Confirm
                  </Btn>
                  <Btn
                    onClick={handleCloseDialog}
                    sx={{
                      backgroundColor: colors.primary,
                      borderRadius: "40px",
                      color: colors.lightBg,
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: colors.lightBg,
                      },
                    }}
                  >
                    Cancel
                  </Btn>
                </DialogActions>
            </div>
            </div>
          </Dialog>
          <SuccessfullModal open={InterestOpen} message={message!==""?message:errormessage} handleClose={handleInterestClose} />
                        <Dialog open={authModalOpen} onClose={handleAuthModalClose}>
  <DialogContent>
    <Typography>
      Click below to complete your authorization on the BSE STAR MF platform and close the tab after authorizing yourself.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Btn onClick={openAuthLink} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Open Authorization Link
    </Btn>
    <Btn onClick={handleAuthModalClose} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Close
    </Btn>
  </DialogActions>
        </Dialog>
        <CustomDialog
        open={Open}
        errormessage={message!==""?message:errormessage}
        button={button}
        handleDialogClose={handleCloseDialog}
        handleClose={handleClose}
        openAuthLink={button ? undefined : openAuthLink}
        openPaymentTab={button ?handleClose :undefined}
      />

    </>
  );
};

export default SWP;
