import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { colors } from "../../constants/colors";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../hooks/useUserData";

interface InvestmentDataProps {
  title: string;
  totalAmount: string;
  profitAmount: string;
  unrealised: string;
  roi: string;
  handleSwitch?:()=>void;
  alignment?: any;
  setAlignment?: any;
  handleChange?: any;
  MFData?: any;
  othersCard?:boolean;
  setOthersCard?:React.Dispatch<React.SetStateAction<boolean>>;
  selectedMember?:any;
}

interface DetailsProps {
  data: InvestmentDataProps[];
  additionalData: any;
  handleSwitchCard:()=> void;
  selectedMember?:any;
  othersCard:boolean;
  setOthersCard:React.Dispatch<React.SetStateAction<boolean>>;
}

const formatIndianCurrency=(value: number | string): string=> {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  if (isNaN(value)) {
    return "Invalid number";
  }

  const [integerPart, decimalPart] = value.toString().split(".");
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  const formattedInteger =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}  

const Details: React.FC<DetailsProps> = ({
  data,
  additionalData,
  selectedMember,
  handleSwitchCard,
  othersCard,
  setOthersCard,
}) => {
  const [alignment, setAlignment] = useState("web");
  const [MFData, setMFData] = useState(additionalData);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    if (additionalData) {
      setMFData(additionalData);
    }
  }, [additionalData]);

  // Separate the standard cards and "Others" data
  const standardCards = data.filter(
    (investment) =>
      !["PPF/EPS/KWP", "Private Stock"].includes(investment.title)
  );
  const othersData = data.filter((investment) =>
    ["PPF/EPS/KWP", "Private Stock"].includes(investment.title)
  );

  return (
    <div className="space-y-2 mt-4">
      {/* Render Standard Investment Cards */}
      {standardCards.map((investment, index) => (
        <InvestmentData
          key={index}
          {...investment}
          alignment={alignment}
          setAlignment={setAlignment}
          handleChange={handleChange}
          handleSwitch={handleSwitchCard}
          othersCard={othersCard}
          setOthersCard={setOthersCard}
          MFData={additionalData}
          selectedMember={selectedMember}
        />
      ))}

      {/* Render "Others" Card */}
      <div
        className="px-4 py-4 border border-primary rounded-lg cursor-pointer"
        onClick={() => setOthersCard((prev) => !prev)}
      >
        <Grid container spacing={2.5} alignItems="center" marginBottom={othersCard?3:0}>
          <Grid item xs={12} sm={2.5}>
            <h2 className="text-gray-700_01 font-bold text-xl text-center sm:text-left">
              Others
            </h2>
          </Grid>
          <Grid item xs={12} sm={2} textAlign="center">
            <p className="text-gray-700_01 text-md">Invested</p>
            <p className="font-bold">
              {formatIndianCurrency(othersData
                .reduce(
                  (acc, item) => acc + Number(item.totalAmount || 0),
                  0
                )
                .toFixed(2))}
            </p>
          </Grid>
          <Grid item xs={12} sm={2} textAlign="center">
            <p className="text-gray-700_01 text-md">Current Value</p>
            <p className="font-bold">
              {formatIndianCurrency(othersData
                .reduce(
                  (acc, item) => acc + Number(item.profitAmount || 0),
                  0
                )
                .toFixed(2))}
            </p>
          </Grid>
          <Grid item xs={12} sm={3} textAlign="center">
            <p className="text-gray-700_01 text-md">Unrealised Gain/Loss</p>
            <p className="font-bold">
              {formatIndianCurrency(othersData
                .reduce(
                  (acc, item) => acc + Number(item.unrealised || 0),
                  0
                )
                .toFixed(2))}
            </p>
          </Grid>
          <Grid item xs={12} sm={2} textAlign="center">
            <p className="text-gray-700_01 text-md">Absolute Return</p>
            <p className="font-bold">
              {othersData.length > 0
                ? (
                    othersData.reduce(
                      (acc, item) => acc + Number(item.roi || 0),
                      0
                    ) / othersData.length
                  ).toFixed(2)
                : "0.00"}
              %
            </p>
          </Grid>
          <Grid item xs={12} sm={0.5} textAlign="center"
          className="flex md:justify-start justify-center text-2xl">
              {othersCard ? <KeyboardArrowDownIcon/> :<KeyboardArrowRightIcon/> }
          </Grid>
        </Grid>
        {/* Expanded "Others" Cards */}
      {othersCard && (
        <div className="mt-2 space-y-2">
          {othersData.map((investment, index) => (
            <InvestmentData
              key={index}
              {...investment}
              alignment={alignment}
              setAlignment={setAlignment}
              handleChange={handleChange}
              handleSwitch={handleSwitchCard}
              othersCard={othersCard}
              setOthersCard={setOthersCard}
              MFData={additionalData}
            />
          ))}
        </div>
      )}
      </div>
    </div>
  );
};


const InvestmentData: React.FC<InvestmentDataProps> = ({
  title,
  totalAmount,
  profitAmount,
  unrealised,
  roi,
  handleSwitch,
  handleChange,
  setAlignment,
  alignment,
  MFData,
  selectedMember
}) => {
  const user=useUserData();
  const navigate = useNavigate();
  const handleIconClick = () => {
    if (title === 'Mutual fund') {
        navigate('/mf-summary');
    } else if (title === 'AIF') {
      navigate('/aif-summary');
    } else if (title === 'PMS') {
      navigate('/pms-summary');
    } else if (title === 'BOND') {
      navigate('/bond-summary');
    } else if (title === 'FD') {
      navigate('/fd-summary');
    }
    else if (title === 'EQUITY') {
      navigate('/fd-equity');
    } else {
        navigate('/asset-holdings');
    }
  };

  const formattedTotalAmount = totalAmount
    ? Number(totalAmount).toFixed(2)
    : "0.00";
  const formattedProfitAmount = profitAmount
    ? Number(profitAmount).toFixed(2)
    : "0.00";
  const formattedUnrealised = unrealised
    ? Number(unrealised).toFixed(2)
    : "0.00";
  const formattedRoi = roi ? Number(roi).toFixed(2) : "0.00";
  return (
    <div className="px-4 py-4 border border-primary rounded-lg">
      <Grid container spacing={2.5} alignItems="center">
        <Grid item xs={12} sm={2.5}>
          <h2 className="text-gray-700_01 font-bold text-xl text-center sm:text-left">
            {title}
          </h2>
        </Grid>
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md">Invested</p>
          <p className="font-bold">{formatIndianCurrency(formattedTotalAmount)}</p>
        </Grid>
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md md:whitespace-nowrap">
            {title === "FD" ? "Maturity Amount" : "Current Value"}
          </p>
          <p className="font-bold">{formatIndianCurrency(formattedProfitAmount)}</p>
        </Grid>

        <Grid item xs={12} sm={3} textAlign="center">
          <p className="text-gray-700_01 text-md">
            {/* {title === "Mutual fund" ? "Unrealised Gain/Loss" : "Earnings"} */}
            Unrealised Gain/Loss
          </p>
          <p className="font-bold">{formatIndianCurrency(formattedUnrealised)}</p>
        </Grid>
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md">Absolute Return</p>
          <p className="font-bold">{formattedRoi}%</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={0.5}
          textAlign="center"
          className="flex md:justify-start justify-center"
        >
          <p
            className={`text-2xl cursor-pointer`}
            style={{
              color: title === "FD" || title === "EQUITY" || (selectedMember!="" && user?.firstName!==selectedMember) ? "#A0A0A0" : "#000",
              cursor: title === "FD" || title === "EQUITY" || (selectedMember!="" && user?.firstName!==selectedMember) ? "not-allowed" : "pointer",
            }}
            onClick={title === "FD" || title === "EQUITY" ? undefined : handleIconClick}
          >
            <MdKeyboardArrowRight />
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Details;
