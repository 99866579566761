import React, { useEffect, useState } from "react";
import { Text, Heading, Button, Img, Input } from "../../components";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { Apple, Google, Visibility, VisibilityOff } from "@mui/icons-material";
import Footer from "../../components/Footer/Footer";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login, socialSignUp } from "../../redux/auth/authSlice";
import { LoginModel } from "../../models/login.model";
import toast from "react-hot-toast";
import { access } from "fs";
import GoogleButton from "react-google-button";
import { signInWithPopup } from "firebase/auth";
import { auth, googleAuthProvider } from "../../config/firebaseConfig";

const loginSchema = yup.object({
  userId: yup.string().required("User ID is required."),
  password: yup.string().required("Password is required."),
});

const loginValues = {
  userId: "",
  password: "",
};

export default function Login() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userData, setUserData] = useState(null);
  const [countdown, setCountdown] = useState(10);
  const refreshToken = useSelector((state: any) => state?.auth?.refreshToken);
  const socialLoggedIn = useSelector(
    (state: any) => state?.auth?.socialLoggedIn
  );
  const accessToken = useSelector((state: any) => state?.auth?.accessToken);
  const isError = useSelector((state: any) => state?.auth?.isError);

  // useEffect(() => {
  //   if (refreshToken && accessToken) {
  //     localStorage.setItem("refreshToken", refreshToken);
  //     localStorage.setItem("accessToken", accessToken);
  //     navigate("/");
  //   }
  // }, [refreshToken && accessToken]);

  // useEffect(() => {
  //   if (socialLoggedIn && accessToken) {
  //     localStorage.setItem("accessToken", accessToken);
  //     localStorage.setItem("refreshToken", refreshToken);
  //     toast.success("Account Created Successfully!");
  //     navigate("/mf-listing");
  //   }
  // }, [socialLoggedIn]);

  // useEffect(() => {
  //   if (isError) {
  //     toast.error("Invalid user email or passsword.");
  //   }
  // }, [isError]);

  useEffect(() => {
    if (userData) {
      console.log("User data updated: ", userData);
    }
  }, [userData]);

  const onLogin = async (values: LoginModel) => {
    const res = await dispatch(login(values));
    console.log(res, "res");
    
    if (res?.error?.message) {
      toast.error("Invalid User ID or Password!");
      return;
    }
    
    const { refreshToken, accessToken, userData } = res?.payload?.data;
    
    if (!refreshToken || !accessToken || !userData) {
      toast.error("Something went wrong. Please try again later.");
      return;
    }
  
    // Save tokens to local storage
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("accessToken", accessToken);
    
    // Extract user type
    const userType = userData?.userType;
    
    // Determine navigation path based on userType
    if (userType === -1) {
      navigate("/register");
    } 
     else if (userType === 2) {
      navigate("/users");
    } 
    else if (userData?.onboardStatus===1 && userType === 1) {
      navigate("/onboarding");
    }  
    else if (userData?.onboardStatus===1 && userType === 5) {
      navigate("/nonIndividual");
    } 
    else if ((userData?.onboardStatus===3 || userData?.onboardStatus===4 || userData?.onboardStatus===2 ) && (userType === 1 || userType === 5)) {
      navigate("/");
    }
    else {
      toast.error("Invalid user type.");
    }
  };
  

  const handleSignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const uid = result?.user?.uid;
      const email = result?.user?.email;
      const firstName = result?.user?.displayName?.split(" ")[0];
      const lastName = result?.user?.displayName?.split(" ")[1] || '';
      
      console.log('Response of signInWithPopup', result, "result", 'UID', uid, email, ' :email', firstName, lastName, ':Name');
  
      if (uid && email && firstName) {
        const res = await dispatch(
          socialSignUp({
            referenceId: uid,
            email,
            firstName,
            lastName,
            logInBy: "google",
          })
        );
        
        if (res?.error?.message) {
          toast.error("Invalid username or password!");
          return;
        }
        
        const { refreshToken, accessToken, userData } = res?.payload?.data;
        
        if (!refreshToken || !accessToken || !userData) {
          toast.error("Something went wrong. Please try again later.");
          return;
        }
        
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessToken", accessToken);
        
        navigate("/");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      console.log(error);
    }
  };
  

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full">
        <div className="flex flex-col items-center justify-center w-full px-4 py-5 md:px-14 md:py-20 bg-primary shadow-xs">
          <div className="flex flex-col items-center justify-start w-full max-w-[650px] mt-1">
            <div className="flex flex-col items-center justify-center w-full p-8 bg-white shadow-sm rounded-[18px]">
              <Heading
                as="h1"
                className="mt-0.5 text-center text-darkGrey text-lg md:text-3xl"
              >
                Login to AUM Sampann
              </Heading>
              {/* <Text
                size="s"
                as="p"
                className="mt-[5px] !text-gray-800 text-center text-sm md:text-lg"
              >
                Login/Signup to AUM Sampann for a more personalised experience
              </Text> */}
              <Formik
                initialValues={loginValues}
                validationSchema={loginSchema}
                onSubmit={(values, { setSubmitting }) => {
                  onLogin(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  /* and other goodies */
                }) => (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (!isValid) {
                          toast.error("Invalid user email or passsword.");
                          return;
                        }
                        handleSubmit();
                      }}
                      className="w-full flex flex-col gap-[31px]"
                    >
                      <div className="flex flex-col items-center justify-start mx-auto w-full mt-[31px] gap-4 max-w-[501px]">
                        <Input
                          type="text"
                          name="userId"
                          placeholder="User ID"
                          outline="none"
                          className="w-full"
                          onChange={handleChange("userId")}
                          value={values.userId}
                        />
                        <Input
                          type={passwordVisible ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          outline="none"
                          suffix={
                            <IconButton
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              {passwordVisible ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          }
                          className="w-full gap-[35px]"
                          onChange={handleChange("password")}
                          value={values.password}
                        />
                        <div className="flex flex-row justify-between items-center w-full gap-x-3">
                          <Link
                            to="/forgot-password"
                            className="!text-indigo-800  cursor-pointer text-sm md:text-lg"
                          >
                            Forgot Password?
                          </Link>
                          <Button
                            type="submit"
                            // to="/entity"
                            className="flex justify-center items-center py-4 bg-darkGrey hover:bg-secondary transition-all rounded-lg max-w-[150px] w-full"
                          >
                            <Text
                              size="xs"
                              className="text-white text-sm md:text-lg"
                            >
                              Login
                            </Text>
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </Formik>
            </div>
            {/* <Text
              size="lg"
              as="p"
              className="mt-[21px] text-white text-sm md:text-2xl"
            >
              or Login Via
            </Text> */}
            {/* <div className="flex flex-row justify-center w-full mt-[18px] gap-[25px]"> */}
              {/* <Button
                color="white_A700"
                size="sm"
                leftIcon={<Google />}
                className="md:gap-[9px] border-blue_gray-100 border border-solid bg-white"
              >
                <span className="text-sm md:text-lg">Sign in with Google</span>
              </Button> */}
              {/* <GoogleButton onClick={handleSignInWithGoogle} /> */}
              {/* <Button
                color="white_A700"
                size="sm"
                leftIcon={<Apple />}
                className="md:gap-[17px] border-blue_gray-100 border border-solid bg-white"
              >
                <span className="text-sm md:text-lg">Sign in with Apple</span>
              </Button> */}
            {/* </div> */}
            <Heading size="md" as="h3" className="mt-[33px] text-center">
              <div className="flex">
                <span className="text-white font-normal text-sm md:text-2xl">
                  First time investor with AumSampann?{" "}
                </span>
                <NavLink to="/register">
                  <div className="text-white font-normal underline ml-2 cursor-pointer text-sm md:text-2xl">
                    Start here
                  </div>
                </NavLink>
              </div>
            </Heading>
          </div>
        </div>
      </div>
    </>
  );
}
