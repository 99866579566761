import React, { FC, useEffect, useRef, useState } from "react";
import FormDatePicker from "../../../components/FormDatePicker/FormDatePicker";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import person from "../../../assets/icons/f7_person-2 blue.png";
import { colors } from "../../../constants/colors";

import { Formik } from "formik";
import * as yup from "yup";

import dayjs from "dayjs";
import styles from "../Individual.module.css";
import ZeroDocKyc from "../ZeroDocKyc/ZeroDocKyc";
import ManualKyc from "../ManualKyc/ManualKyc";
import file from "../../../assets/icons/file.png";
import Buttons from "../../../components/Buttons/IndividualButtons/Buttons";
import { useDispatch } from "react-redux";
import { getUserById, updateUser } from "../../../redux/user/userSlice";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/core";
import { useUserData } from "../../../hooks/useUserData";
import toast from "react-hot-toast";
import { FormInput } from "../../../components/FormInput/FormInput";
import FormSelect from "../../../components/FormSelect/FormSelect";
import { City, State } from "country-state-city";
import { IndianStates, getKeyByValue } from "../../../enums/states";
import { Button } from "../../../components";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import { getStatesOfCountry } from "country-state-city/lib/state";

interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}

interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name?: string;
  type?: string;
  disabled?: boolean;
}

interface GeneralDetailsFormProps {
  activeSection: string; // Prop to indicate the active section
  onProceed: () => void;
  passingData: any;
  userData: any;
  isVerified: any;
  setIsVerified: any;
}

const userValues = {
  // firstName: "",
  // lastName: "",
  // email: "",
  mobile: "",
  // dateOfBirth: dayjs("2000-01-01").format("DD-MM-YYYY"),
  // gender: "",
  // dateOfBirth: dayjs("2000-01-01").format("DD/MM/YYYY"),
  gender: "male",
  addressType: 1,
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  pincode: "",
  wealthSource: "",
  incomeSlab: "",
  occupation: "",
  politicalExposed: "0",
  isNRI: "1",
  fullName: "",
};

const userSchema = yup.object({
  mobile: yup.string().required(),
  gender: yup.string().required("Gender is required."),
  addressType: yup.string().required("Address type is required."),
  address1: yup.string().required("Address1 is required."),
  address2: yup.string().required("Address2 is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  pincode: yup.string().required("Pincode is required."),
  wealthSource: yup.string().required("Wealth Source is required."),
  incomeSlab: yup.string().required("Income Slab is required."),
  occupation: yup.string().required("Occupation is required."),
  politicalExposed: yup.number().required("Political Exposed is required."),
  isNRI: yup.number().required("NRI status is required."),
});

const GeneralDetailsForm: FC<GeneralDetailsFormProps> = ({
  activeSection,
  onProceed,
  userData,
  isVerified,
  setIsVerified,
}) => {
  const updateUserRef = useRef<any>();
  const formikRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const user = useUserData();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [availableData, setAvailableData] = useState(false);
  const [FormData, setFormData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [alignment, setAlignment] = React.useState("web");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [generalData, setGeneralData] = useState<any>({
    mobile: "",
    gender: "",
    addressType: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    state: "",
    pincode: "",
    wealthSource: "",
    incomeSlab: "",
    occupation: "",
    politicalExposed: "",
    isNRI: "",
  });

  useEffect(() => {
    setAvailableData(
      userData?.mobile !== "" &&
        userData?.gender !== "" &&
        userData?.addressType >= 0 &&
        userData?.address1 !== "" &&
        userData?.address2 !== "" &&
        userData?.pincode !== "" &&
        userData?.state !== "" &&
        userData?.city !== "" &&
        userData?.wealthSource !== "" &&
        userData?.incomeSlab !== "" &&
        userData?.occupation !== "" &&
        userData?.politicalExposed?.toString() !== "" &&
        userData?.isNRI?.toString() !== ""
    );
  }, [userData]);

  const isGeneralDataComplete = () => {
    console.log(generalData, "genearalData");
    // Iterate over the values and check if any are empty
    return Object.values(generalData).every((value) => value !== "");
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const [selectedOption, setSelectedOption] = useState<string>("manual");
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUserById({}));
    getStates();
  }, [dispatch]);

  useEffect(() => {
    if (userData?.mobile) {
      formikRef?.current?.setFieldValue("mobile", userData?.mobile);
      addGeneralData("mobile", userData?.mobile);
    }
    if (userData?.gender) {
      formikRef?.current?.setFieldValue(
        "gender",
        userData?.gender === 1 ? "male" : userData?.gender === 2 ? "female" : ""
      );
      addGeneralData(
        "gender",
        userData?.gender === 1 ? "male" : userData?.gender === 2 ? "female" : ""
      );
    }
    if (userData?.addressType && userData?.addressType !== -1) {
      formikRef?.current?.setFieldValue("addressType", userData?.addressType);
      addGeneralData("addressType", userData?.addressType);
    }
    if (userData?.address1) {
      formikRef?.current?.setFieldValue("address1", userData?.address1);
      addGeneralData("address1", userData?.address1);
    }
    if (userData?.address2) {
      formikRef?.current?.setFieldValue("address2", userData?.address2);
      addGeneralData("address2", userData?.address2);
    }
    if (userData?.address3) {
      formikRef?.current?.setFieldValue("address3", userData?.address3);
      addGeneralData("address3", userData?.address3);
    }
    if (userData?.pincode) {
      formikRef?.current?.setFieldValue("pincode", userData?.pincode);
      addGeneralData("pincode", userData?.pincode);
    }
    if (userData?.state) {
      const state = getKeyByValue(userData?.state);
      formikRef?.current?.setFieldValue("state", state);
      addGeneralData("state", state);
      getCities(state, state);
    }
    if (userData?.city) {
      formikRef?.current?.setFieldValue("city", userData?.city);
      addGeneralData("city", userData?.city);
    }

    if (userData?.wealthSource) {
      formikRef?.current?.setFieldValue("wealthSource", userData?.wealthSource);
      addGeneralData("wealthSource", userData?.wealthSource);
    }
    if (userData?.incomeSlab) {
      formikRef?.current?.setFieldValue("incomeSlab", userData?.incomeSlab);
      addGeneralData("incomeSlab", userData?.incomeSlab);
    }
    if (userData?.occupation) {
      formikRef?.current?.setFieldValue("occupation", userData?.occupation);
      addGeneralData("occupation", userData?.occupation);
    }
    if (userData?.occupation) {
      formikRef?.current?.setFieldValue(
        "politicalExposed",
        userData?.politicalExposed?.toString()
      );
      addGeneralData(
        "politicalExposed",
        userData?.politicalExposed?.toString()
      );
    }
    if (userData?.occupation) {
      formikRef?.current?.setFieldValue("isNRI", userData?.isNRI?.toString());
      addGeneralData("isNRI", userData?.isNRI?.toString());
    }
  }, [userData]);

  const addGeneralData = (key: string, value: string) => {
    setGeneralData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const handleRadioChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false); // Hide the modal after confirmation
    if (formikRef.current) {
      // Trigger Formik's submission handler
      await formikRef.current.handleSubmit();
      const values = formikRef.current.values;
      console.log(values);
      const body = {
        address1: values.address1,
        address2: values.address2,
        address3: values.address3 || "",
        addressType: values.addressType,
        city: values.city,
        gender: values.gender,
        incomeSlab: values.incomeSlab,
        isNRI: values.isNRI,
        mobile: values.mobile,
        occupation: values.occupation,
        pincode: values.pincode,
        politicalExposed: values.politicalExposed,
        state: IndianStates[values.state],
        wealthSource: values.wealthSource,
      };
      console.log(body, "Body");
      try {
        const response = await dispatch(updateUser(body));
        if (response?.error?.message) {
          toast.error("Failed to update details");
          return;
        }
        onProceed();
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        console.error(error);
      }
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = () => {
    if (formikRef.current?.isValid && formikRef.current?.dirty) {
      console.log(formikRef.current?.values, "Form Values");
      setIsModalVisible(true); // Show the modal if form is valid
    } else {
      return;
    }
  };

  const checkAlreadySubmitted = () => {
    if (isGeneralDataComplete()) {
      onProceed();
    } else {
      handleSaveAndProceed();
    }
  };

  const getStates = () => {
    setStates(
      State.getStatesOfCountry("IN").map((state) => {
        return {
          label: state.name,
          value: state.name,
          isoCode: state?.isoCode,
          code: IndianStates[state.name],
        };
      })
    );
  };

  const getCities = (state: any, city: any) => {
    const currentState = getStatesOfCountry("IN").filter((item: any) => {
      return item.name === state;
    })[0];
    const cities = [];

    cities.push({
      label: city[0]?.Block,
      value: city[0]?.Block,
    });
    // Otherwise, map all the cities based on the state's isoCode
    const stateCities = City.getCitiesOfState("IN", currentState?.isoCode).map(
      (x: any) => {
        return { label: x.name, value: x.name };
      }
    );
    cities.push(...stateCities); // Spread the array to add all cities

    // Set the cities data
    setCities(cities);
  };

  const fetchCityAndState = (pincode: string) => {
    if (!pincode || pincode.length !== 6) {
      return;
    }
    fetch(`https://api.postalpincode.in/pincode/${pincode}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "data");
        if (data[0].Status === "Success") {
          const { PostOffice } = data[0];
          if (PostOffice.length > 0) {
            formikRef.current.setFieldValue("state", PostOffice[0].State);
            console.log(PostOffice[0].State, PostOffice, "state");
            getCities(PostOffice[0].State, PostOffice);
            formikRef.current.setFieldValue("city", PostOffice[0].Block);
          } else {
            toast("No data found for this pincode");
          }
        } else {
          toast("Invalid pincode");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    // { value: "other", label: "Other" },
  ];
  const [passingData, setPassingData] = useState({});

  const addressTypes = [
    { value: 1, label: "Residential or Business" },
    { value: 2, label: "Residential" },
    { value: 3, label: "Business" },
    { value: 4, label: "Registered Office" },
    { value: 5, label: "Prefer not to say" },
  ];

  const wealthSource = [
    { value: "01", label: "Salary" },
    { value: "02", label: "Business Incode" },
    { value: "03", label: "Gift" },
    { value: "04", label: "Ancestral Property" },
    { value: "05", label: "Rental Income" },
    { value: "06", label: "Prize Money" },
    { value: "07", label: "Royalty" },
    { value: "08", label: "Others" },
  ];

  const incomeSlab = [
    { value: "31", label: "Below 1 Lakh" },
    { value: "32", label: "1 to 5 Lacs" },
    { value: "33", label: "5 to 10 Lacs" },
    { value: "34", label: "10 to 25 Lacs" },
    { value: "35", label: "25 Lacs to 1 Crore" },
    { value: "36", label: "Above 1 Crore" },
  ];

  const occupation = [
    { value: "01", label: "Business" },
    { value: "02", label: "Service" },
    { value: "03", label: "Professional" },
    { value: "04", label: "Agriculturist" },
    { value: "05", label: "Retired" },
    { value: "06", label: "Housewife" },
    { value: "07", label: "Student" },
    { value: "08", label: "Others" },
    { value: "09", label: "Doctor" },
    { value: "41", label: "Private Sector Service" },
    { value: "42", label: "Public Sector Service" },
    { value: "43", label: "Forex Dealer" },
  ];

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        <Formik
          innerRef={formikRef}
          initialValues={userValues}
          validationSchema={userSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("Form Values:", values);
            setSubmitting(false); // Reset the submitting state after processing
            // const payload = {
            //   ...values,
            //   state: IndianStates[values.state],
            // };
            // const response: any = await dispatch(updateUser(payload));
            // if (response?.error?.message) {
            //   toast.error("Failed to update details");
            //   return;
            // }
            // onProceed();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            const detailsPassing = ({
              field,
              value,
            }: {
              field: string;
              value: any;
            }) => {
              setPassingData((prevData) => ({ ...prevData, [field]: value }));
            };
            return (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log(values, "values");
                    console.log(errors, "errors");
                    handleSubmit();
                  }}
                  className="w-full flex flex-col gap-[31px]"
                >
                  <div className="flex flex-row flex-wrap gap-8 justify-center">
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Phone *"
                        type="phone"
                        name="mobile"
                        onChange={handleChange("mobile")}
                        value={values.mobile}
                        disabled={userData?.mobile != ""}
                        // error={errors.mobile}
                        // touched={touched.mobile}
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}  
                      />
                      {touched.mobile && errors.mobile && (
                        <span className="error-text" >
                          {errors.mobile}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      {" "}
                      {/* Adjusted width for Gender */}
                      <FormSelect
                        label="Gender *"
                        options={genderOptions}
                        onChange={handleChange("gender")}
                        value={values.gender}
                        // error={errors.gender}
                        // touched={touched.gender}
                        disabled={userData?.gender !== -1}
                        style={{
                          marginBottom: 0, // Disable the default margin
                        }}
                      />
                      {touched.gender && errors.gender && (
                        <span className="error-text" >
                          {errors.gender}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Address Type *"
                        options={addressTypes}
                        onChange={handleChange("addressType")}
                        value={values.addressType}
                        // error={errors.addressType}
                        // touched={touched.addressType}
                        disabled={userData?.addressType !== -1}
                        style={{
                          marginBottom: 0, // Disable the default margin
                        }}
                      />
                      {touched.addressType && errors.addressType && (
                        <span className="error-text" >
                          {errors.addressType}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Pincode *"
                        name="pincode"
                        onChange={(e: any) => {
                          setFieldValue("pincode", e.target.value);
                          fetchCityAndState(e.target.value);
                        }}
                        value={values.pincode}
                        disabled={userData?.pincode !== null}
                        // error={errors.pincode}
                        // touched={touched.pincode} 
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}                       
                      />
                      {touched.pincode && errors.pincode && (
                        <span className="error-text" >
                          {errors.pincode}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="State *"
                        name="state"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("state", e.target.value);
                          getCities(e.target.value, e.target.value);
                        }}
                        value={values.state}
                        disabled={userData?.state !== null}
                        // error={errors.state}
                        // touched={touched.state}
                        options={states}
                        style={{
                          marginBottom: 0, // Disable the default margin
                        }}
                      />
                      {touched.state && errors.state && (
                        <span className="error-text" >
                          {errors.state}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      {userData?.city !== null ? (
                        <FormInput
                          label="City *"
                          name="city"
                          value={values.city}
                          disabled={userData?.city !== null}
                        />
                      ) : (
                        <>
                          <FormSelect
                            label="City *"
                            name="city"
                            onChange={(e: any) => {
                              console.log(e.target.value, "e");
                              setFieldValue("city", e.target.value);
                            }}
                            value={values.city}
                            // error={errors.city}
                            // touched={touched.city}
                            options={cities}  
                            style={{
                              marginBottom: 0, // Disable the default margin
                            }}                         
                          />
                          {touched.city && errors.city && (
                        <span className="error-text" >
                          {errors.city}
                        </span>
                      )}
                        </>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 1 *"
                        name="address1"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          if (value.length <= 28) {
                            handleChange("address1")(e);
                            setErrorMessage(""); // Clear the error if the length is valid
                          } else {
                            setErrorMessage(
                              "Address 1 cannot exceed 30 characters"
                            );
                          }
                        }}
                        value={values.address1}
                        disabled={
                          userData?.address1 !== "" &&
                          !userData.address1?.includes("null")
                            ? userData.address1
                            : ""
                        }
                        // error={errors.address1 || errorMessage}
                        // touched={touched.address1}
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}  
                      />
                      {touched.address1 && errors.address1 && (
                        <span className="error-text" >
                          {errors.address1}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 2 *"
                        name="address2"
                        onChange={(e: any) => {
                          if (e.target.value.length <= 28) {
                            handleChange("address2")(e);
                            setErrorMessage2(""); // Clear the error if the length is valid
                          } else {
                            setErrorMessage2(
                              "Address 2 cannot exceed 30 characters"
                            );
                          }
                        }}
                        value={
                          !values.address2?.includes("null")
                            ? values.address2
                            : ""
                        }
                        disabled={
                          userData?.address2 !== "" &&
                          !userData.address2?.includes("null")
                            ? userData.address2
                            : ""
                        }
                        // error={errors.address2 || errorMessage2}
                        // touched={touched.address2}
                        style={{
                          marginBottom: "-6px", // Disable the default margin
                        }}  
                      />
                      {touched.address2 && errors.address2 && (
                        <span className="error-text" >
                          {errors.address2}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 3 "
                        name="address3"
                        onChange={(e: any) => {
                          if (e.target.value.length <= 28) {
                            handleChange("address3")(e);
                            setErrorMessage3(""); // Clear the error if the length is valid
                          } else {
                            setErrorMessage3(
                              "Address 3 cannot exceed 30 characters"
                            );
                          }
                        }}
                        value={
                          !values.address3?.includes("null")
                            ? values.address3
                            : ""
                        }
                        disabled={
                          userData?.address3 !== "" &&
                          !userData.address3?.includes("null")
                            ? userData.address3
                            : ""
                        }
                      />
                    </div>
                    <div className="w-full max-w-[500px]"></div>
                  </div>
                  <div className="pl-32">
                    <h4 className="font-inter font-semibold text-xl">
                      FATCA Details
                    </h4>
                  </div>
                  <div className="flex flex-row flex-wrap gap-8 justify-center">
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Wealth Source *"
                        options={wealthSource}
                        onChange={handleChange("wealthSource")}
                        value={values.wealthSource}
                        disabled={userData?.wealthSource !== ""}
                        // error={errors.wealthSource}
                        // touched={touched.wealthSource}  
                        style={{
                          marginBottom: 0, // Disable the default margin
                        }}                      
                      />
                      {touched.wealthSource && errors.wealthSource && (
                        <span className="error-text" >
                          {errors.wealthSource}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Occupation *"
                        options={occupation}
                        onChange={handleChange("occupation")}
                        value={values.occupation}
                        disabled={userData?.occupation !== null}
                        // error={errors.occupation}
                        // touched={touched.occupation} 
                        style={{
                          marginBottom: 0, // Disable the default margin
                        }}                       
                      />
                      {touched.occupation && errors.occupation && (
                        <span className="error-text" >
                          {errors.occupation}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Income Slab *"
                        options={incomeSlab}
                        onChange={handleChange("incomeSlab")}
                        value={values.incomeSlab}
                        disabled={userData?.incomeSlab !== ""}
                        // error={errors.incomeSlab}
                        // touched={touched.incomeSlab}
                        style={{
                          marginBottom: 0, // Disable the default margin
                        }}
                      />
                      {touched.incomeSlab && errors.incomeSlab && (
                        <span className="error-text" >
                          {errors.incomeSlab}
                        </span>
                      )}
                    </div>
                    <div className="w-full max-w-[500px]"></div>
                    <div
                      style={{ width: "100%" }}
                      className="md:px-32 flex flex-col gap-8"
                    >
                      <div className="flex flex-col md:flex-row justify-between items-center">
                        <label htmlFor="">
                          Politically exposed person /Related to Politically
                          exposed person *
                        </label>
                        <ToggleButtonGroup
                          color="primary"
                          value={values.politicalExposed}
                          exclusive
                          onChange={handleChange("politicalExposed")}
                          // disabled={!!generalData?.politicalExposed}
                          aria-label="Platform"
                          sx={{
                            "& .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                              {
                                backgroundColor: colors.primary,
                                color: colors.white,
                                fontWeight: "bold",
                              },
                          }}
                        >
                          <ToggleButton value="1">Yes</ToggleButton>
                          <ToggleButton value="0">No</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <div className="flex flex-col md:flex-row  justify-between items-center">
                        <label htmlFor="">
                          I am an Indian Citizen and a Tax Resident of India and
                          no other country citizenship *
                        </label>
                        <ToggleButtonGroup
                          color="primary"
                          value={values.isNRI}
                          exclusive
                          onChange={handleChange("isNRI")}
                          // disabled={!!generalData?.isNRI}
                          aria-label="Platform"
                          sx={{
                            "& .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                              {
                                backgroundColor: colors.primary,
                                color: colors.white,
                                fontWeight: "bold",
                              },
                          }}
                        >
                          <ToggleButton value="1">Yes</ToggleButton>
                          <ToggleButton value="0">No</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </div>
                  {availableData ? (
                    <div className="pl-32">
                      <Button
                        type="button"
                        className="form-button"
                        tabIndex={0}
                        onClick={checkAlreadySubmitted}
                        //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                        // Disable button if radio is not selected
                      >
                        Proceed
                      </Button>
                    </div>
                  ) : (
                    <div className="pl-32">
                      <button
                        type="submit"
                        className="form-button"
                        tabIndex={0}
                        onClick={checkAlreadySubmitted}
                        //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                        // Disable button if radio is not selected
                      >
                        Save and Proceed
                      </button>
                    </div>
                  )}

                  <ConfirmationModal
                    isVisible={isModalVisible} // Render conditionally based on state
                    message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                    onConfirm={handleModalConfirm} // Confirm action and submit form
                    onCancel={handleModalCancel} // Cancel action and close modal
                  />
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default GeneralDetailsForm;
