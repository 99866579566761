import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "../pages/NotFound";
import GeneralDetailsForm from "../pages/GeneralDetails/GeneralDetails";
import CompanyDetails from "../pages/CompanyDetails/CompanyDetails";
import MainPage from "../pages/IndividualForms/Main/Main";
import FDDetails from "../pages/FDDetails/FDDetails";
import MFListing from "../pages/MFListing/MFListing";
import MFDetails from "../pages/MFDetails/MFDetails";
import Login from "../pages/Login/Login";
import EntityPage from "../pages/EntityPage/EntityPage";
import Register from "../pages/Register/Register";
import MainNonIndividual from "../pages/NonIndividualPages/Main/MainNonIndividual";
import FDListing from "../pages/FDListing/FDListing";
import MainDashboard from "../pages/MainDashboard/MainDashboard";
import AssetHoldings from "../pages/AssetHoldings/AssetHoldings";
import Main from "../components/MFTransaction/main";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import AppLayout from "../layouts/AppLayout/AppLayout";
import VerificationPending from "../pages/VerificationPending/VerificationPending";
import WishlistFund from "../pages/WishlistFunds/WishlistFund";
import Report from "../pages/ReportsPage/Report";
import DigilockerDetails from "../pages/DigilockerDetails/DigilockerDetails";
import Profile from "../pages/ProfilePage/Profile";
import AssetComparison from "../pages/AssetComparison/AssetComparison";
import BondsListing from "../pages/BondsListing/BondsListing";
import AIFListing from "../pages/AssetComparison/AIFListing/AIFListing";
import PMSListing from "../pages/PMSListing/PMSListing";
import EsignSuccess from "../pages/SuccessPage/EsignSuccess";
import MFSummary from "../pages/MutualFundSummary/MFSummary";
import EquityListing from "../pages/EquityListing/EquityListing";
import UserList from "../pages/RM/UserList";
import AIFDetails from "../pages/AIFDetails/AIFDetails";
import PMSDetails from "../pages/PMSDetails/PMSDetails";
import AIFSummary from "../pages/AIFSummary/AIFSummary";
import PMSSummary from "../pages/PMSSummary/PMSSummary";
import BondsSummaryDetails from "../pages/BondsSummary/BondsSummaryDetails";
import BondsSummary from "../pages/BondsSummary/BondsSummary";
import BankDetailsForm from "../pages/IndividualForms/BankDetailsForm/BankDetailsForm";
import Sign from "../pages/NonIndividualPages/Signature/Sign";
import DematForm from "../pages/NonIndividualPages/Demat/Demat";
import SignForm from "../pages/NonIndividualPages/Signature/Sign";
import Preferences from "../pages/NonIndividualPages/Preferences/Preferences";
import Preferences2 from "../pages/NonIndividualPages/Preferences/Preferences2";
import PrivacyPolicyAndTerms from "../pages/PrivacyPolicy/Privacy";
import OrderHistory from "../pages/OrderHistoryPage/OrderHistory";
import AddFamilyMember from "../pages/AddFamily/AddFamilyMember";
import DisclaimerPage from "../pages/FooterLinks/DisclaimerPage";
import TermsOfUsePage from "../pages/FooterLinks/TermsOfUsePage";
import CutOffTime from "../pages/FooterLinks/Cut-OffTime";
import UnlistedShare from "../pages/UnlistedShare/UnlistedShare";
import FetchResponseTable from "../pages/AutoFetchEmailPage/FetchResponseTable";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route element={<PrivateRoutes />}>
            <Route path="/generaldetails" element={<GeneralDetailsForm />} />
            <Route path="/companydetails" element={<CompanyDetails />} />

            {/* Individual routes */}
            <Route path="/onboarding" element={<MainPage />} />
            <Route path="/digilocker-webhook" element={<DigilockerDetails />} />

            {/* Non-Individual routes */}
            <Route path="/nonIndividual" element={<MainNonIndividual />} />
            <Route path="/entity" element={<EntityPage />} />
            <Route path="/fd-listing" element={<FDListing />} />
            <Route path="/fd-details/:id" element={<FDDetails />} />
            <Route path={`/mf-details/:isin/:id`} element={<MFDetails />} />
            <Route path="/" element={<MainDashboard />} />
            <Route path="/mf-listing" element={<MFListing />} />
            <Route path="/asset-holdings" element={<AssetHoldings />} />
            <Route path="/mf-transactions" element={<Main />} />
            <Route path="/reports" element={<Report />} />
            <Route path="/wishlist-fund" element={<WishlistFund />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/orders-history" element={<OrderHistory />} />
            <Route path="/email-history" element={<FetchResponseTable />} />
            <Route path="/asset-comparison" element={<AssetComparison />} />
            <Route path="/bond-listing" element={<BondsListing />} />
            <Route path="/equity-listing" element={<EquityListing />} />
            <Route path="/aif-listing" element={<AIFListing />} />
            <Route path={`/aif-details/:id`} element={<AIFDetails />} />
            <Route path="/pms-listing" element={<PMSListing />} />
            <Route path={`/pms-details/:id`} element={<PMSDetails />} />
            <Route path="/mf-summary" element={<MFSummary />} />
            <Route path="/aif-summary" element={<AIFSummary />} />
            <Route path="/pms-summary" element={<PMSSummary />} />
            <Route path="/bond-summary" element={<BondsSummary />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/add-members" element={<AddFamilyMember />} />
            <Route path="/disclaimer" element={<DisclaimerPage />} />
            <Route path="/termsOfUse" element={<TermsOfUsePage />} />
            <Route path="/cutOffTime" element={<CutOffTime />} />
            <Route path="/unlistedEquity-listing" element={<UnlistedShare />} />
          </Route>

          {/* Home and catch-all route */}
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/privacy" element={<PrivacyPolicyAndTerms />} />
            <Route
              path="/verification-pending/:id"
              element={<VerificationPending />}
            />
            <Route path="/disclaimer" element={<DisclaimerPage />} />
            <Route path="/termsOfUse" element={<TermsOfUsePage />} />
            <Route path="/*" element={<NotFound />} />
          </Route>
          <Route path="/esign-success" element={<EsignSuccess />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
