import React, { useCallback, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
  Select,
} from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FiUpload } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { uploadHoldingsData } from "../../redux/asset_Holdings/assetSlice";
import APIloader from "../AppLoader/APIloader";
import SuccessfullModal from "./SuccessfullModal";
import { useUserData } from "../../hooks/useUserData";

interface UploadModalProps {
  open: boolean;
  handleClose: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch<any>();
  const user = useUserData();
  const [uploadType, setUploadType] = useState("file");
  const [assetType, setAssetType] = useState("");
  const [subAssetType, setSubAssetType] = useState("");
  const [fileType, setFileType] = useState("");
  const [settlementDate, setSettlementDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Message, setMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [successfulDone, setSuccessfulDone] = useState(false);

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleDrop = useCallback((e: any) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === "text/csv") {
      setFile(droppedFile);
      console.log("Dropped file:", droppedFile);
      // Handle the dropped file (e.g., validate, process, etc.)
    } else {
      alert("Please drop a valid CSV file.");
    }
  }, []);

  const handleDragOver = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const SuccessfulModalClose = () => {
    setsuccessModal(false);
    setMessage("");
    setAssetType("");
    setFileType("");
    setSubAssetType("");
    if (successfulDone) {
      handleClose();
      setSuccessfulDone(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file && assetType) {
      const formData = new FormData();
      if (assetType === "STOCK") {
        formData.append("file", file);
        formData.append("holdingType", assetType);
        formData.append("fileFormat", fileType);
      } else if (assetType === "others") {
        formData.append("file", file);
        formData.append("holdingType", subAssetType);
      } else {
        formData.append("file", file);
        formData.append("holdingType", assetType);
      }
      try {
        const response = await dispatch(uploadHoldingsData(formData));
        if (response?.payload?.statusCode === 400) {
          setMessage(response?.payload?.message);
          setLoading(false);
          setsuccessModal(true);
          setFile(null);
          setFileName(null);
        } else if (response?.payload?.data?.statusCode === 200) {
          setMessage("File uploaded successfully.");
          setLoading(false);
          setsuccessModal(true);
          setFile(null);
          setFileName(null);
          setSuccessfulDone(true);
        } else {
          setMessage("You have uploaded a wrong or empty file. Please review the file.");
          setLoading(false);
          setsuccessModal(true);
          setFile(null);
          setFileName(null);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setMessage(
          "File not uploading due to server issues.Try after sometimes!"
        );
        setLoading(false);
        setsuccessModal(true);
      }
    }
  };

  const handleDownload = () => {
    let templateFile = "/assets/Aif_Template.csv"; // Default file path
    let templateName = "Template.csv"; // Default file name

    // Adjust file path and name based on the selected asset type
    if (assetType === "AIF") {
      templateFile = "/assets/AIF_Template.csv";
      templateName = "AIF_Template.csv";
    } else if (assetType === "MUTUAL FUND") {
      templateFile = "/assets/MF_Template.csv";
      templateName = "MUTUAL FUND_Template.csv";
    } else if (assetType === "PMS") {
      templateFile = "/assets/PMS_Template.csv";
      templateName = "PMS_Template.csv";
    } else if (assetType === "BOND") {
      templateFile = "/assets/Bond_Template.csv";
      templateName = "Bond_Template.csv";
    } else if (assetType === "STOCK") {
      templateFile = "/assets/Stock_Template.csv";
      templateName = "Stock_Template.csv";
    } else if (assetType === "FD") {
      templateFile = "/assets/Fd_Template.csv";
      templateName = "Fd_Template.csv";
    }else if (subAssetType === "PPF") {
      templateFile = "/assets/PPF_Template.csv";
      templateName = "PPF_Template.csv";
    }else if (subAssetType === "PRIVATE STOCK") {
      templateFile = "/assets/Unlisted Equity_Template.csv";
      templateName = "Unlisted Equity_Template.csv";
    }
    const tempFile = document.createElement("a");
    tempFile.href = templateFile;
    tempFile.setAttribute("download", templateName);
    document.body.appendChild(tempFile);
    tempFile.click();
    document.body.removeChild(tempFile);
  };
  return (
    <>
      <Modal open={open} onClose={handleClose} className="overflow-hidden">
        <Box
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-4xl bg-lightBg p-8 rounded-lg shadow-lg overflow-y-auto"
          style={{ maxHeight: "95vh" }}
        >
          <div className="flex justify-between items-center mb-4">
            <Typography
              variant="h6"
              className="text-primary"
              style={{ fontWeight: "bolder" }}
            >
              Upload Data
            </Typography>
            <IconButton
              onClick={() => {
                handleClose();
                setAssetType("");
                setFileType("");
                setSubAssetType("");
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div className="flex flex-col gap-4 p-5 bg-white">
            <Typography variant="subtitle1" className="text-black">
              Upload Type
            </Typography>
            <RadioGroup
              row
              value={uploadType}
              onChange={(e) => setUploadType(e.target.value)}
            >
              {/* <FormControlLabel
              value="manual"
              control={<Radio />}
              label="Manual"
              className="text-darkGrey"
            /> */}
              <FormControlLabel
                value="file"
                control={<Radio />}
                label="File"
                className="text-darkGrey"
              />
            </RadioGroup>

            {/* {uploadType === "manual" && (
            <div className="flex flex-col gap-4 p-5 bg-lightBg">
              <Typography variant="subtitle1" className="text-black">
                Choose Asset Type
              </Typography>
              <RadioGroup
                row
                value={assetType}
                onChange={(e) => setAssetType(e.target.value)}
              >
                <FormControlLabel
                  value="AIF"
                  control={<Radio />}
                  label="Aif"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="PMS"
                  control={<Radio />}
                  label="Pms"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="BOND"
                  control={<Radio />}
                  label="Bonds"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="STOCK"
                  control={<Radio />}
                  label="Equity"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="FD"
                  control={<Radio />}
                  label="Fixed Deposit"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="Estate"
                  control={<Radio />}
                  label="Real Estate"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="Gold/Silver"
                  control={<Radio />}
                  label="Gold/Silver Physical"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="Estate"
                  control={<Radio />}
                  label="Real Estate"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="Insurance"
                  control={<Radio />}
                  label="Life Insurance"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="PRIVATE STOCK"
                  control={<Radio />}
                  label="Private Equity"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="PPF"
                  control={<Radio />}
                  label="PPF/EPS/ULIP/NPS/KWP "
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="Jwellary "
                  control={<Radio />}
                  label="Jwellary "
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="Bank"
                  control={<Radio />}
                  label="Bank Account "
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="BankFD"
                  control={<Radio />}
                  label="Bank FD"
                  className="text-darkGrey"
                />                
              </RadioGroup>
              <Divider className="bg-primary" />
              {assetType==="AIF" &&(
                <Typography variant="subtitle1" className="text-black">
                Upload Format
              </Typography>
              
              )}
              <div className="relative mt-4">
                <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-primary" />
                <input
                  type="text"
                  placeholder="Search Company Name"
                  className="pl-10 pr-4 py-2 border rounded w-full"
                />
              </div>
              <div className="flex items-center gap-4 mt-4">
                <div className="w-10 h-20 rounded-full  bg-gray-200 flex items-center justify-center"></div>
                <Typography
                  variant="body1"
                  className="text-primary"
                  style={{ fontWeight: "bolder" }}
                >
                  Company Name
                </Typography>
              </div>
              <div className="flex gap-4 mt-4">
                <div className="w-1/2">
                  <label
                    htmlFor="quantity"
                    className="block mb-1 text-darkGrey"
                  >
                    Quantity (in units)
                  </label>
                  <TextField
                    select
                    variant="outlined"
                    className="w-full bg-white"
                    id="quantity"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </TextField>
                </div>
                <div className="w-1/2">
                  <label htmlFor="price" className="block mb-1 text-darkGrey">
                    Price Purchased At (in INR) / Yield
                  </label>
                  <TextField
                    variant="outlined"
                    type="number"
                    className="w-full bg-white"
                    id="price"
                  />
                </div>
              </div>

              <div className="w-1/2 mt-4 pr-2">
                <label
                  htmlFor="settlementDate"
                  className="block mb-1 text-darkGrey"
                >
                  Settlement Date
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" className="w-full bg-white" />
                </LocalizationProvider>
              </div>

              <button className="bg-darkGrey text-white py-2 px-4 rounded hover:bg-primary mt-4 w-36">
                Upload
              </button>
            </div>
          )} */}

            {uploadType === "file" && (
              <div className="flex flex-col gap-4 p-5 bg-lightBg">
                <Typography variant="subtitle1" className="text-darkGrey flex">
                  Choose Asset Type{" "}
                  <p className="text-red-600 text-2xl ml-1">*</p>
                </Typography>
                <RadioGroup
                  row
                  value={assetType}
                  onChange={(e) => setAssetType(e.target.value)}
                >
                  {user?.userType === 5 && (
                    <FormControlLabel
                      value="MUTUAL FUND"
                      control={<Radio />}
                      label="Mutual Fund"
                      className="text-darkGrey"
                    />
                  )}
                  <FormControlLabel
                    value="AIF"
                    control={<Radio />}
                    label="AIF"
                    className="text-darkGrey"
                  />
                  <FormControlLabel
                    value="PMS"
                    control={<Radio />}
                    label="PMS"
                    className="text-darkGrey"
                  />
                  <FormControlLabel
                    value="BOND"
                    control={<Radio />}
                    label="Bonds"
                    className="text-darkGrey"
                  />
                  <FormControlLabel
                    value="STOCK"
                    control={<Radio />}
                    label="Equity"
                    className="text-darkGrey"
                  />
                  <FormControlLabel
                    value="FD"
                    control={<Radio />}
                    label="Fixed Deposit"
                    className="text-darkGrey"
                  />
                  <FormControlLabel
                    value="others"
                    control={<Radio />}
                    label="Others"
                    className="text-darkGrey"
                  />
                </RadioGroup>
                {assetType === "others" && (
                  <FormControl
                    sx={{
                      width: "50%",
                    }}
                  >
                    <Select
                      value={subAssetType}
                      onChange={(e: any) => setSubAssetType(e.target.value)}
                      displayEmpty
                      sx={{
                        fontSize: "14px",
                        color: "textGrey",
                        "& .MuiSelect-icon": { color: "darkGrey" },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "darkGrey",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "primary",
                        },
                      }}
                    >
                      <MenuItem value="">Select Asset Type</MenuItem>
                      <MenuItem value="Estate" disabled>
                        Real Estate
                      </MenuItem>
                      <MenuItem value="Gold/Silver" disabled>
                        Gold/Silver Physical
                      </MenuItem>
                      <MenuItem value="Insurance" disabled>
                        Life Insurance
                      </MenuItem>
                      <MenuItem value="PRIVATE STOCK">Unlisted Equity</MenuItem>
                      <MenuItem value="PPF">PPF/EPS/KWP</MenuItem>
                      <MenuItem value="ULIP" disabled>
                        ULIP/NPS
                      </MenuItem>
                      <MenuItem value="Jwellary" disabled>
                        Jewellery
                      </MenuItem>
                      <MenuItem value="Bank" disabled>
                        Bank Account
                      </MenuItem>
                      <MenuItem value="BankFD" disabled>
                        Bank FD
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                {/* <Divider className="bg-primary" /> */}
                {(assetType === "MUTUAL FUND" ||
                  assetType === "AIF" ||
                  assetType === "PMS" ||
                  assetType === "FD" ||
                  assetType === "BOND" ||
                  assetType === "STOCK" ||
                  subAssetType) && (
                  <>
                    <Typography
                      variant="subtitle1"
                      className="text-darkGrey flex"
                    >
                      Choose upload format{" "}
                      <p className="text-red-600 text-2xl ml-1">*</p>
                    </Typography>
                    <RadioGroup
                      row
                      value={fileType}
                      onChange={(e) => setFileType(e.target.value)}
                    >
                      <FormControlLabel
                        value="excel"
                        control={<Radio />}
                        label={
                          assetType === "MUTUAL FUND" ||
                          assetType === "AIF" ||
                          assetType === "PMS" ||
                          assetType === "FD" ||
                          assetType === "BOND" ||
                          assetType === "STOCK"
                            ? "Excel/CSV"
                            : "Excel/CSV"
                        }
                        className="text-darkGrey"
                      />
                      {assetType === "STOCK" && (
                        <>
                          <FormControlLabel
                            value="zerodha"
                            control={<Radio />}
                            label="Zerodha Excel"
                            className="text-darkGrey"
                          />
                          <FormControlLabel
                            value="stockpro"
                            control={<Radio />}
                            label="StockPro Excel"
                            className="text-darkGrey"
                          />
                        </>
                      )}
                      {(assetType === "AIF" ||
                        assetType === "PMS" ||
                        assetType === "STOCK") && (
                        <FormControlLabel
                          value="pdf"
                          control={<Radio />}
                          label="PDF"
                          className="text-darkGrey"
                        />
                      )}
                    </RadioGroup>
                  </>
                )}
                {(assetType === "AIF" ||
                  assetType === "PMS" ||
                  assetType === "FD" ||
                  assetType === "BOND" ||
                  assetType === "STOCK" ||
                  subAssetType) && <Divider className="bg-primary" />}
                {fileType === "excel" &&
                  (assetType === "MUTUAL FUND" ||
                    assetType === "AIF" ||
                    assetType === "PMS" ||
                    assetType === "FD" ||
                    assetType === "BOND" ||
                    assetType === "STOCK" || subAssetType ==="PRIVATE STOCK" || subAssetType === "PPF") && (
                    <>
                      <Typography
                        variant="subtitle1"
                        className="text-primary mt-4"
                        style={{ fontWeight: "bolder" }}
                      >
                        Download Form Template
                      </Typography>
                      <div className="flex items-center gap-5">
                        <Typography
                          variant="subtitle1"
                          className="text-primary"
                          style={{ fontWeight: "normal" }}
                        >
                          {assetType === "MUTUAL FUND" ||
                    assetType === "AIF" ||
                    assetType === "PMS" ||
                    assetType === "FD" ||
                    assetType === "BOND" ||
                    assetType === "STOCK"?`${assetType}_Template.csv`:subAssetType==="PRIVATE STOCK"?`Unlisted Equity_Template.csv` :`${subAssetType}_Template.csv`}
                        </Typography>
                        <button
                          className="bg-primary text-white py-2 rounded hover:bg-primary flex items-center justify-center"
                          style={{ width: "15%" }}
                          onClick={handleDownload}
                        >
                          Download
                          <GetAppIcon className="text-white" />
                        </button>
                      </div>
                    </>
                  )}
                {(fileType === "excel" ||
                  fileType === "pdf" ||
                  fileType === "zerodha" ||
                  fileType === "stockpro") && (
                  <>
                    <label
                      htmlFor="settlementDate"
                      className="block text-darkGrey"
                    >
                      Upload File
                    </label>
                    <div>
                      <div
                        className={`w-1/2 bg-white flex flex-col items-center justify-center cursor-pointer`}
                      >
                        <div className="flex w-full px-4 py-1 border-2 rounded">
                          <label
                            htmlFor="dropzone-file"
                            className="flex flex-row items-center justify-between w-full cursor-pointer"
                          >
                            <span className="text-gray-700 text-md">
                              Choose file to upload
                            </span>

                            <FiUpload className="text-gray-700" />
                            <input
                              id="dropzone-file"
                              type="file"
                              accept={`${
                                fileType && fileType === "pdf"
                                  ? ".pdf"
                                  : assetType === "others" &&
                                    fileType === "excel"
                                  ? ".csv"
                                  : fileType === "zerodha" ||
                                    fileType === "stockpro"
                                  ? ".xlsx"
                                  : ".csv"
                              }`}
                              className="hidden"
                              onChange={handleChange}
                            />
                          </label>
                        </div>
                      </div>
                      {fileName && (
                        <div className="mt-4 text-gray-700">
                          <strong>Selected File:</strong> {fileName}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        type="file"
                        id="hiddenFileInput"
                        style={{ display: "none" }}
                        accept={`${
                          fileType && fileType === "pdf"
                            ? ".pdf"
                            : assetType === "others" &&
                              fileType === "excel"
                            ? ".csv"
                            : fileType === "zerodha" ||
                              fileType === "stockpro"
                            ? ".xlsx"
                            : ".csv"
                        }`}
                        onChange={handleChange}
                      />
                      <Box
                        border={1}
                        borderColor="grey.300"
                        borderRadius="10px"
                        p={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        height={150}
                        onDrop={(e) => {
                          e.preventDefault(); // Prevent default browser behavior
                          if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                            handleDrop(e); // Pass the event to handleDrop
                          }
                        }}
                        onDragOver={(e) => {
                          e.preventDefault(); // Prevent default browser behavior
                        }}
                        style={{ cursor: "pointer" }}
                        sx={{
                          mt: 2,
                          backgroundColor: "white",
                          width: "50%",
                          borderStyle: "dashed",
                        }}
                        onClick={() => {
                          const fileInput =
                            document.getElementById("hiddenFileInput");
                          if (fileInput) {
                            (fileInput as HTMLInputElement).click();
                          }
                        }}
                      >
                        {file ? (
                          <Typography
                            variant="body2"
                            className="text-darkGrey"
                            style={{
                              marginLeft: 10,
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis", // Add "..." for overflowed text
                              whiteSpace: "nowrap", // Prevent text wrapping
                              maxWidth: "100%", // Constrain text to box width
                            }}
                          >
                            {file.name}
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              variant="body2"
                              className="text-darkGrey text-md"
                              style={{ marginLeft: 10 }}
                            >
                              {`Drag and drop file here (${
                                fileType && fileType === "pdf"
                                  ? ".pdf"
                                  : assetType === "others" &&
                                    fileType === "excel"
                                  ? ".csv"
                                  : fileType === "zerodha" ||
                                    fileType === "stockpro"
                                  ? ".xlsx"
                                  : ".csv"
                              } format)`}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </div>
                  </>
                )}
                <button
                  className={`bg-darkGrey text-white py-2 px-4 rounded mt-4 w-36 ${
                    !file || !assetType
                      ? "opacity-50 cursor-not-allowed"
                      : " hover:bg-primary"
                  }`}
                  disabled={!file || !assetType}
                  onClick={handleSubmit}
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        </Box>
      </Modal>
      <APIloader loadingModal={loading} message={"Please wait..."} />
      <SuccessfullModal
        open={successModal}
        message={Message}
        handleClose={SuccessfulModalClose}
      />
    </>
  );
};

export default UploadModal;
