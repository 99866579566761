import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import DatePicker from "../../components/DateRangePicker/DatePicker";
import AllAssetPF from "../../components/ReportStructure/AllAssetPF";
import { colors } from "../../constants/colors";
import { useUserData } from "../../hooks/useUserData";
import { FDAHInfo, HoldingsData } from "../../redux/asset_Holdings/assetSlice";
import { importFunds, mfSendOTP, mfVerifyOTP } from "../../redux/MF/MFSlice";
import {
  getAif,
  getBonds,
  getPms,
  MFAssetHoldings,
  UnrealisedReport,
} from "../../redux/Reports/ReportSlice";
import { getUserById } from "../../redux/user/userSlice";
import TimeStamptoExp from "../../utils/timestampConverter";

const filterOps = [
  {
    name: "Tenure",
    options: [
      { name: "1Y", value: 1, isChecked: false },
      { name: "3Y", value: 3, isChecked: false },
      { name: "5Y", value: 5, isChecked: false },
      { name: "7Y", value: 7, isChecked: false },
      { name: "8Y", value: 8, isChecked: false },
      { name: "11Y", value: 11, isChecked: false },
    ],
  },
  {
    name: "Payout Mode",
    options: [
      { name: "Online", value: "online", isChecked: false },
      { name: "Offline", value: "offline", isChecked: false },
    ],
  },
  {
    name: "Special Rate",
    options: [
      { name: "Senior citizen", value: "senior citizen", isChecked: false },
      { name: "Woman", value: "woman", isChecked: false },
    ],
  },
  {
    name: "Interest Rate",
    options: [
      { name: "0-5%", value: "0-5%", isChecked: false },
      { name: "5-15%", value: "5-15%", isChecked: false },
      { name: "15-25%", value: "15-25%", isChecked: false },
    ],
  },
  {
    name: "Payment Frequency",
    options: [
      { name: "Monthly", value: "Monthly", isChecked: false },
      { name: "Quarterly", value: "Quarterly", isChecked: false },
      { name: "Half Yearly", value: "Half Yearly", isChecked: false },
      { name: "Yearly", value: "Yearly", isChecked: false },
    ],
  },
];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Reports = () => {
  const [totalFound, setTotalFound] = useState(28);
  const [filterOptions, setFilterOptions] = useState(filterOps);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchFund, setSearchFund] = useState<string>("");
  const [sortedPlans, setSortedPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [timestamps, setTimestamps] = useState<{ startTimestamp: number | undefined; endTimestamp: number | undefined }>({
  //   startTimestamp: undefined,
  //   endTimestamp: undefined,
  // });
  const user = useUserData();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({ key: "", direction: "" });
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const openMenu = Boolean(anchorEl);
  const [OTPData, setOTPData] = useState({
    otpRef: "",
    clientRefNo: "",
    reqId: 0,
  });
  const [otp, setOtp] = useState("");

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleSummary = () => {
    setShowSummary(!showSummary);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number,
    optionIndex?: number
  ) => {
    const { checked } = event.target;
    const updatedOptions = [...filterOptions];

    if (optionIndex === undefined) {
      updatedOptions[filterIndex].options.forEach(
        (option) => (option.isChecked = checked)
      );
    } else {
      updatedOptions[filterIndex].options[optionIndex].isChecked = checked;
    }

    setFilterOptions(updatedOptions);
  };

  const [rerenderOnce, setRerenderOnce] = useState("true");

  const breadcrumbItems = [
    { label: "Home", href: "/" }, // Breadcrumb item with link
    { label: "Reports" }, // Current page, no link
  ];

  const [dropdownValues, setDropdownValues] = useState<DropdownValues>({
    segment: "",
    reportType: "",
    fundType: "",
    startDate: undefined,
    endDate: undefined,
    transactionType: 0,
    fetchFor: "",
  });

  type DropdownValues = {
    segment: string;
    reportType: string;
    fetchFor?: string;
    fundType: string;
    startDate: number | undefined;
    endDate: number | undefined;
    transactionType: number;
  };

  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(1);
  const [schemes, setSchemes] = useState<any[]>([]);
  const [pageModal, setPageModal] = useState<boolean>(false);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const fetchData = async () => {
    setLoadingModal(true);
    setLoading(true);
    setRowData([]);

    const minimumLoadingTime = 2000;
    const minimumLoadingTimer = delay(minimumLoadingTime);

    try {
      let data;
      let response;

      if (dropdownValues.segment === "Mutual Funds") {
        if (dropdownValues.reportType === "Transaction report") {
          data = {
            startDate: dropdownValues.startDate || 0,
            endDate: dropdownValues.endDate || 0,
            fundType: dropdownValues.fundType,
            transactionType: 1,
          };
          response = await dispatch(MFAssetHoldings(data));
        } else if (dropdownValues.reportType === "Unrealised gain/loss") {
          data = {
            startDate: dropdownValues.startDate || 1,
            endDate: dropdownValues.endDate || 1,
            fundType: dropdownValues.fundType,
            fetchFor: dropdownValues.fetchFor,
            transactionType: 1,
          };
          response = await dispatch(UnrealisedReport(data));
        }
      } else if (dropdownValues.segment === "Corporate FD") {
        data = {
          startDate: dropdownValues.startDate || 0,
          endDate: dropdownValues.endDate || 0,
          forRm: 0,
        };
        response = await dispatch(FDAHInfo(data));
      }
      if (response.payload.statusCode === 400) {
        toast("No data received from the response");
        setLoadingModal(false);
      } else {
        setLoadingModal(false);
        setRowData(response.payload);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("An error occurred while fetching data");
    } finally {
      setLoading(false);
      await minimumLoadingTimer; // Ensure minimum loading time is respected
      setLoadingModal(false);
    }
  };

  const fetchData_ = async () => {
    setLoadingModal(true);
    setLoading(true);
    setRowData([]);
    setPageModal(true);

    const minimumLoadingTime = 2000;
    const minimumLoadingTimer = delay(minimumLoadingTime);

    try {
      let holdingType;
      let response;

      // Determine holding type based on dropdown selection
      if (dropdownValues.segment === "AIF") {
        holdingType = "AIF";
      } else if (dropdownValues.segment === "PMS") {
        holdingType = "PMS";
      } else if (dropdownValues.segment === "Bonds") {
        holdingType = "BOND";
      } else if (dropdownValues.segment === "PRIVATE_STOCK") {
        holdingType = "PRIVATE_STOCK";
      } else if (dropdownValues.segment === "PPF") {
        holdingType = "PPF";
      } else {
        holdingType = "STOCK"; // Default to STOCK or add additional logic as needed
      }

      // Send request to HoldingsData API
      const requestData = {
        holdingType,
      };

      response = await dispatch(HoldingsData(requestData));

      console.log("Response payload data:", response.payload);

      console.log("Full response:", response.payload);

      const { statusCode, data } = response.payload || {};
      console.log("Status Code:", statusCode);
      console.log("Data:", data);

      const holdings = response.payload;

      console.log("Holdings Data:", holdings);

      if (holdings) {
        setTotalPages(totalPages);
        setRowData(holdings);
      } else {
        setRowData([]);
        toast("No data received");
      }
      console.log("row data", rowData);
      if (statusCode === 400) {
        toast("No data received from the response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("An error occurred while fetching data");
    } finally {
      setLoading(false);
      await minimumLoadingTimer;
      setLoadingModal(false);
    }
  };

  const handlePageChange = (event: any, value: any) => {
    setPageIndex(value);
    fetchData();
  };

  const handleItemsPerPageChange = (event: any) => {
    setPageSize(event.target.value);
    setPageIndex(1);
    fetchData();
  };

  const FDfieldTitles = [
    { key: "schemeName", value: "Scheme Name" },
    { key: "investmentDate", value: "Deposit Date" },
    { key: "investmentTenure", value: "Tenure" },
    { key: "endDate", value: "Maturity Date" },
    { key: "amount", value: "Invested Amount" },
    { key: "interesetRate", value: "Interest P.A (%)" },
    { key: "investmentFrequency", value: "Interest Payment Frequency" },
    { key: "maturityInterest", value: "Interest Earned" },
    { key: "returnAmount", value: "Maturity Amount" },
  ];

  const MFfieldTitles = [
    // { key: 'transactionDate', value: 'Transaction Date' },
    { key: "folioNumber", value: "Folio no." },
    { key: "amcCode", value: "AMC Name" },
    { key: "schemeName", value: "Scheme Name" },
    { key: "closingBalance", value: "Closing Balance (Units)" },
    { key: "units", value: "Available Units" },
    { key: "amount", value: "Available Amount" },
    { key: "currentValue", value: "Current Market Value" },
    { key: "costValue", value: "Cost Value" },
    { key: "currentNAV", value: "Current NAV" },
    { key: "unrealisedPNL", value: "Gain/Loss" },
    { key: "unrealisedPNLPercentage", value: "Gain/Loss %" },
    // { key: 'action', value: 'Action' },
  ];

  const MFTransations = [
    { key: "transactionNo", value: "Transaction ID" },
    { key: "createdAt", value: "Transaction Date" },
    { key: "bseSchemeCode", value: "Invested Code" },
    { key: "folioNumber", value: "Folio No." },
    { key: "amcCode", value: "AMC Name" },
    { key: "schemeName", value: "Scheme Name" },
    { key: "category", value: "Category" },
    { key: "options", value: "Option" },
    { key: "orderType", value: "Transaction Type" },
    { key: "subTransactionType", value: "Sub-transaction Type" },
    { key: "grossPurchase", value: "Gross Purchase /Redeem" },
    { key: "stampDuty", value: "Stamp Duty" },
    { key: "netPurchase", value: "Net Purchase/Redeem" },
    { key: "units", value: "Units" },
    // { key: 'createdAt', value: 'NAV (Nav Date)' },
  ];

  const AIFfieldTitles = [
    { key: "holdingName", value: "Holding Name" },
    { key: "currentNavDate", value: "Current NAV Date" },
    { key: "investedAmount", value: "Invested Amount" },
    { key: "currentNAV", value: "Current NAV" },
    { key: "currentAmount", value: "Current Amount" },
    { key: "absolutePnL", value: "Absolute Return" },
    { key: "xirr", value: "XIRR" },
    { key: "transactionDate", value: "Transaction Date" },
  ];

  const PMSfieldTitles = [
    { key: "holdingName", value: "Holding Name" },
    { key: "category", value: "Category" },
    { key: "investedAmount", value: "Invested Amount (₹)" },
    { key: "currentAmount", value: "Current Amount (₹)" },
    { key: "transactionDate", value: "Transaction Date" },
    { key: "gainLoss", value: "Current P&L (₹)" },
    { key: "absolutePnL", value: "Current P&L (%)" },
    { key: "xirr", value: "XIRR" },
  ];

  const BondsFieldTitles = [
    { key: "holdingName", value: "Holding Name" },
    { key: "category", value: "Category" },
    { key: "isin", value: "ISIN" },
    { key: "investedAmount", value: "Invested Amount (₹)" },
    { key: "currentAmount", value: "Current Amount (₹)" },
    { key: "currentNAV", value: "Current NAV (₹)" },
    { key: "units", value: "Units" },
    { key: "transactionDate", value: "Transaction Date" },
    { key: "createdAt", value: "Creation Date" },
    { key: "cuurentPnL", value: "Current P&L (₹)" },
    { key: "currentPnLinPercentage", value: "Current P&L (%)" },
  ];

  const EQUITYFieldTitles = [
    { key: "holdingName", value: "Holding Name" },
    { key: "category", value: "Category" },
    { key: "isin", value: "ISIN" },
    { key: "investedAmount", value: "Invested Amount (₹)" },
    { key: "currentAmount", value: "Current Amount (₹)" },
    { key: "currentNAV", value: "Current NAV (₹)" },
    { key: "units", value: "Units" },
    { key: "transactionDate", value: "Transaction Date" },
    { key: "createdAt", value: "Creation Date" },
    { key: "cuurentPnL", value: "Current P&L (₹)" },
    { key: "currentPnLinPercentage", value: "Current P&L (%)" },
  ];

  const PRIVATE_EQUITYFieldTitles = [
    { key: "symbol", value: "Stock Name" },
    { key: "transactionDate", value: "Transaction Date" },
    { key: "unit", value: "Unit" },
    { key: "purchasePrice", value: "Purchase Price (₹)" },
    { key: "currentPrice", value: "Current Price (₹)" },
    { key: "currentAmount", value: "Current Amount (₹)" },
  ];

  const PPFFieldTitles = [   
    { key: "assetName", value: "Holding Name" },
    { key: "createdAt", value: "Creation Date" },
    { key: "interestRate", value: "Interest Rate (%)" },
    { key: "amount", value: "Invested Amount (₹)" },
    { key: "maturityDate", value: "Maturity Date" },
    { key: "currentAmount", value: "Maturity Amount (₹)" },
  ];

  const handleSort = (key: string) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    // Add sorting logic here based on key and direction
  };

  const controlBarObj =
    dropdownValues.segment === "Mutual Funds" &&
    dropdownValues.reportType === "Unrealised gain/loss"
      ? MFfieldTitles
      : dropdownValues.segment === "Mutual Funds"
      ? MFTransations
      : dropdownValues.segment === "Corporate FD"
      ? FDfieldTitles
      : dropdownValues.segment === "AIF"
      ? AIFfieldTitles
      : dropdownValues.segment === "PMS"
      ? PMSfieldTitles 
      : dropdownValues.segment === "Bonds"
      ? BondsFieldTitles
      : dropdownValues.segment === "STOCK"
      ? EQUITYFieldTitles
      : dropdownValues.segment === "PRIVATE_STOCK"
      ? PRIVATE_EQUITYFieldTitles
      : dropdownValues.segment === "PPF"
      ? PPFFieldTitles
      : []; // Add this line for PMS

  // useEffect(() => {

  // }, [searchFund])

  const searchFundFunction = (value: any) => {
    setSearchFund(value);
    if (searchFund) {
      setSortedPlans(rowData);
      let filteredPlans;
      if (dropdownValues.segment === "Mutual Funds") {
        filteredPlans = rowData.filter((plan: any) =>
          plan.schemeName.toLowerCase()?.includes(searchFund.toLowerCase())
        );
      } else {
        filteredPlans = rowData.filter((plan: any) =>
          plan.name.toLowerCase()?.includes(searchFund.toLowerCase())
        );
      }
      setRowData(filteredPlans);
    } else {
      fetchData();
      // toast.success('not found');
    }
  };

  const sendOtp = async () => {
    if (user.todayImportDone === 1) {
      const fetechImport = await dispatch(importFunds({ isRefresh: 1 }));
      // console.log('Mutual fund Data Sync Request submitted',fetechImport);
      if (fetechImport?.payload?.data?.statusCode === 200) {
        toast.success("Mutual fund Data Synced successfully");
        fetchData();
      }
      // toast.error(fetechImport?.payload?.data?.message);
    } else {
      try {
        const response = await dispatch(mfSendOTP());
        // const response = payload;
        // console.log('model', response);

        if (response.payload.reqId) {
          toast.success("OTP Sent Successfully");
          // console.log('getting info OTP', response.payload);

          setOTPData(response.payload);
          setShowSummary(true);
        } else {
          toast.error(
            "No mutual fund found on provided pan card and mobile number."
          );
        }
      } catch (error) {
        toast.error("OTP Send Failed");
      }
    }
  };

  const handleOtpChange = (event: any) => {
    setOtp(event.target.value);
  };

  const showDateRangePicker =
    dropdownValues.segment === "Mutual Funds" ||
    dropdownValues.segment === "Corporate FD";

  const handleSubmit = async () => {
    if (OTPData && otp) {
      setLoader(true);
      // console.log('after otp', OTPData);

      // Include otp in the OTPData before making the API call
      const updatedOTPData = { ...OTPData, otp: otp };
      setOTPData(updatedOTPData);
      // console.log('after otp', updatedOTPData);

      // console.log('OTP', typeof otp, otp, updatedOTPData);

      if (updatedOTPData) {
        try {
          const verifyResponse = await dispatch(mfVerifyOTP(updatedOTPData));

          if (verifyResponse.payload.data.success === 400) {
            toast.error("Invalid OTP");
          }

          if (verifyResponse.payload.data.success === 200) {
            let fetechImport;
            const importData = {
              clientRefNo: updatedOTPData.clientRefNo,
              reqId: updatedOTPData.reqId,
              isRefresh: 0,
            };

            fetechImport = await dispatch(importFunds(importData));

            // console.log("import data", importData)

            if (
              fetechImport?.payload.success === 400 &&
              fetechImport.payload.message ===
                "We are in process of generating the CAS. Please visit after sometime."
            ) {
              toast.success(fetechImport?.payload.message);
              dispatch(getUserById({}));
            } else if (fetechImport?.payload.success === 200) {
              toast.success("Mutual fund Data Sync Request submitted");
              dispatch(getUserById({}));
            }
            // console.log("import data", fetechImport)
            fetchData();
            setShowSummary(false);
            setOTPData({
              otpRef: "",
              clientRefNo: "",
              reqId: 0,
            });
            setOtp("");
          } else {
            toast.error("Request Failed");
          }
        } catch (error) {
          // console.error('Request for Quote failed:', error);
          toast.error("An error occurred while processing your request.");
        } finally {
          setLoader(false);
        }
      }
    } else {
      toast.error("Please enter the OTP.");
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setDropdownValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDropdownOpen = () => {
    // Reset the dropdown value to an empty string when opened
    setDropdownValues({
      segment: "",
      reportType: "",
      fundType: "",
      startDate: undefined,
      endDate: undefined,
      transactionType: 0,
      fetchFor: "",
    });
    setRowData([]);
    setPageModal(false);
  };

  return (
    <div>
      {!searchFund && (
        <Modal
          open={loadingModal}
          aria-labelledby="loading-modal-title"
          aria-describedby="loading-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div className="w-[30%] h-[30%] flex flex-col items-center justify-center bg-lightBg rounded-lg">
              <Typography
                id="loading-modal-title"
                variant="subtitle1"
                component="h2"
                marginBottom={5}
              >
                {` ${dropdownValues.segment} generation can take upto 5 minutes, please wait`}
              </Typography>
              <CircularProgress />
            </div>
          </Box>
        </Modal>
      )}
      <div className="pb-20">
        <div className="bg-lightBg">
          <div className="md:p-[30px] max-w-[1440px] w-full mx-auto flex flex-row md:flex-row gap-x-6">
            <div className="flex flex-col">
              <div className="md:pb-10">
                <Breadcrumbs items={breadcrumbItems} />
              </div>
              <div className="flex items-center">
                <div className="flex flex-row w-full justify-between pb-4">
                  <h3 className="text-3xl font-medium text-black">Reports</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[1440px] w-full mx-auto p-8">
          <div className="my-8 flex justify-between gap-x-3">
            <TextField
              className="w-full"
              // onChange={(e) => setSearchFund(e.target.value)}
              onChange={(e) => searchFundFunction(e.target.value)}
              placeholder="Search and add"
              hiddenLabel
              id="filled-hidden-label-small"
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Box>
            <div className="w-full flex flex-wrap items-center justify-between pb-8">
              <div className="flex flex-wrap gap-3 items-center">
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="segment-select-label">Segment</InputLabel>
                  <Select
                    labelId="segment-select-label"
                    id="segment-select"
                    name="segment"
                    value={dropdownValues.segment}
                    label="Segment"
                    onChange={handleChange}
                    onOpen={handleDropdownOpen}
                  >
                    <MenuItem value="Mutual Funds">Mutual Funds</MenuItem>
                    <MenuItem value="Corporate FD">Corporate FD</MenuItem>
                    <MenuItem value="AIF">AIF</MenuItem>
                    <MenuItem value="PMS">PMS</MenuItem>
                    <MenuItem value="Bonds" disabled>
                      Bonds
                    </MenuItem>
                    <MenuItem value="STOCK" disabled>
                      Equity
                    </MenuItem>
                    <MenuItem value="PRIVATE_STOCK">Unlisted Equity</MenuItem>
                    <MenuItem value="PPF">PPF/EPS/KWP</MenuItem>
                  </Select>
                </FormControl>

                {dropdownValues.segment === "Mutual Funds" && (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="report-type-select-label">
                        Report type
                      </InputLabel>
                      <Select
                        labelId="report-type-select-label"
                        id="report-type-select"
                        name="reportType"
                        value={dropdownValues.reportType}
                        label="Report type"
                        onChange={handleChange}
                      >
                        <MenuItem value="Unrealised gain/loss">
                          Unrealised gain/loss
                        </MenuItem>
                        <MenuItem value="Transaction report">
                          Transaction report
                        </MenuItem>
                        {/* <MenuItem value="NFO Report">NFO Report</MenuItem> */}
                      </Select>
                    </FormControl>
                    {dropdownValues.reportType === "Unrealised gain/loss" && (
                      <>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                          <InputLabel id="report-type-select-label">
                            Broker type
                          </InputLabel>
                          <Select
                            labelId="report-type-select-label"
                            id="report-type-select"
                            name="fetchFor"
                            value={dropdownValues.fetchFor}
                            label="Broker type"
                            onChange={handleChange}
                          >
                            {/* <MenuItem value="Unrealised gain/loss">C</MenuItem> */}
                            <MenuItem defaultChecked value="consolidated">
                              Consolidated
                            </MenuItem>
                            <MenuItem value="aumcapital">Aum Capital</MenuItem>
                            {/* <MenuItem value="JS ASSOCIATESl">JS ASSOCIATESl</MenuItem>
                          <MenuItem value="INVEST AAJ FOR KAL EDUTECH PRIVATE LIMITED">KAL EDUTECH</MenuItem> */}
                          </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                          <InputLabel id="fund-type-select-label">
                            Fund type
                          </InputLabel>
                          <Select
                            labelId="fund-type-select-label"
                            id="fund-type-select"
                            name="fundType"
                            value={dropdownValues.fundType}
                            label="Fund type"
                            onChange={handleChange}
                          >
                            <MenuItem value="EQUITY">EQUITY</MenuItem>
                            <MenuItem value="DEBT">DEBT</MenuItem>
                            <MenuItem value="HYBRID">HYBRID</MenuItem>
                            {/* <MenuItem value="GILT">GILT</MenuItem>
                          <MenuItem value="ELSS">ELSS</MenuItem>
                          <MenuItem value="MIP">MIP</MenuItem>
                          <MenuItem value="BALANCED">BALANCED</MenuItem>
                          <MenuItem value="STP">STP</MenuItem>
                          <MenuItem value="FOF">FOF</MenuItem>
                          <MenuItem value="LIQUID">LIQUID</MenuItem>
                          <MenuItem value="HYBRID">HYBRID</MenuItem>
                          <MenuItem value="BOND">BOND</MenuItem>
                          <MenuItem value="INCOME">INCOME</MenuItem> */}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </>
                )}

                {showDateRangePicker &&
                  dropdownValues.reportType !== "Unrealised gain/loss" && (
                    <DatePicker
                      open={open}
                      setOpen={setOpen}
                      setTimestamps={setDropdownValues}
                      timestamps={dropdownValues}
                    />
                  )}
                <div className="flex justify-end items-center">
                  {!open && (
                    <>
                      {(dropdownValues.segment === "Corporate FD" ||
                        dropdownValues.reportType === "Transaction report") && (
                        <Button
                          sx={{
                            backgroundColor: colors.primary,
                            borderRadius: 2,
                            textTransform: "none",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#1565c0" },
                            width: "200px",
                            height: "50px",
                          }}
                          onClick={fetchData}
                        >
                          Generate
                        </Button>
                      )}

                      {dropdownValues.segment === "Mutual Funds" &&
                        dropdownValues.reportType === "Unrealised gain/loss" &&
                        dropdownValues.fundType &&
                        dropdownValues.fetchFor && (
                          <Button
                            sx={{
                              backgroundColor: colors.primary,
                              borderRadius: 2,
                              textTransform: "none",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#1565c0" },
                              width: "200px",
                              height: "50px",
                              marginLeft: "20px",
                            }}
                            onClick={sendOtp}
                          >
                            Generate
                          </Button>
                        )}

                      {(dropdownValues.segment === "AIF" ||
                        dropdownValues.segment === "PMS" ||
                        dropdownValues.segment === "Bonds" ||
                        dropdownValues.segment === "STOCK" ||
                        dropdownValues.segment === "PRIVATE_STOCK" ||
                        dropdownValues.segment === "PPF") && (
                        <Button
                          sx={{
                            backgroundColor: colors.primary,
                            borderRadius: 2,
                            textTransform: "none",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#1565c0" },
                            width: "200px",
                            height: "50px",
                            marginLeft: "20px",
                          }}
                          onClick={fetchData_}
                        >
                          Generate
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {dropdownValues.segment &&
              dropdownValues.reportType &&
              dropdownValues.startDate &&
              dropdownValues.endDate && (
                <Typography
                  className="text-primary font-bold"
                  pb={4}
                  variant="h5"
                >
                  {`${dropdownValues.segment}: ${dropdownValues.reportType} ${
                    TimeStamptoExp.formatDateFromTimestamp(
                      dropdownValues.startDate
                    ).slice(0, 10) === "01/01/1970" &&
                    TimeStamptoExp.formatDateFromTimestamp(
                      dropdownValues.endDate
                    ).slice(0, 10) === "01/01/1970"
                      ? ""
                      : `(${
                          TimeStamptoExp.formatDateFromTimestamp(
                            dropdownValues.startDate
                          ).slice(0, 10) !== "01/01/1970"
                            ? TimeStamptoExp.formatDateFromTimestamp(
                                dropdownValues.startDate
                              ).slice(0, 10)
                            : ""
                        } - ${
                          TimeStamptoExp.formatDateFromTimestamp(
                            dropdownValues.endDate
                          ).slice(0, 10) ===
                          TimeStamptoExp.formatDateFromTimestamp(
                            Date.now()
                          ).slice(0, 10)
                            ? TimeStamptoExp.formatDateFromTimestamp(
                                dropdownValues.endDate
                              ).slice(0, 10)
                            : TimeStamptoExp.formatDateFromTimestamp(
                                dropdownValues.endDate
                              ).slice(0, 10)
                        })`
                  }`}
                </Typography>
              )}
            {rowData.length > 0 && (
              <AllAssetPF
                loading={loading}
                rowData={rowData}
                controlBarObj={controlBarObj}
                sortConfig={sortConfig}
                handleSort={handleSort}
                segment={dropdownValues.segment}
                reportType={dropdownValues.reportType}
              />
            )}
          </Box>
        </div>
      </div>
      <Modal
        open={showSummary}
        onClose={handleToggleSummary}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-primary pb-8"
          >
            Verify Mobile number
          </Typography>
          <Input
            fullWidth
            placeholder="Enter OTP"
            value={otp}
            onChange={handleOtpChange}
          />
          <Typography
            id="modal-modal-title"
            variant="subtitle2"
            className="text-textGrey py-2"
          >
            OTP is sent to your registered number
          </Typography>
          <DialogActions>
            <Button onClick={sendOtp}>Resend OTP</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
};

export default Reports;
