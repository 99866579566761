// hooks/useWebSocket.tsx
import { useRef } from "react";
import { io, Socket } from "socket.io-client";

const useWebSocket = () => {
  const socketRef = useRef<Socket | null>(null);

  const connectWebSocket = () => {
    if (!socketRef.current) {
      const socket = io("https://vuwycnpnem.ap-south-1.awsapprunner.com");
      socketRef.current = socket;

      socket.on("connect", () => {
        console.log("WebSocket connected.");
      });

      socket.on("message", (data: any) => {
        console.log("Message received:", data);
        const parsedData = typeof data === "string" ? JSON.parse(data) : data;
        const url = parsedData?.message;
        if (url) {
          localStorage.setItem("ConfirmationMail", url);
        }
      });

      socket.on("disconnect", () => {
        console.log("WebSocket disconnected.");
        socketRef.current = null;
      });
    }
  };

  const disconnectWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      console.log("WebSocket connection closed.");
    }
  };

  return { connectWebSocket, disconnectWebSocket };
};

export default useWebSocket;
