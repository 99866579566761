import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  Tooltip,
  TextField,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { Button } from "../Button";
import img1 from "../../assets/images/GmailImg1.png";
import img2 from "../../assets/images/GmailImg2.png";
import img3 from "../../assets/images/GmailImg3.png";
import img4 from "../../assets/images/GmailImg4.png";
import img5 from "../../assets/images/GmailImg5.png";
import img6 from "../../assets/images/GmailImg6.png";
import img7 from "../../assets/images/GmailImg7.png";
import img8 from "../../assets/images/GmailImg8.png";
import { BrowserPopUpModal } from "../../utils/BrowserPopUpModal";
import { useDispatch } from "react-redux";
import { allowUserToAutoFetch } from "../../redux/AutoFetchEmail/FetchedEmailSlice";

interface CarouselItem {
  text1?: string;
  subText?: string;
  bullet1?: string;
  bullet2?: string;
  bullet3?: string;
  text2?: string;
  link?: string;
  text3?: string;
  url?: string;
  text4?: string;
  image: string;
  checkUrl: boolean;
}

interface CarouselModalProps {
  open: boolean;
  onClose: () => void;
}

const CarouselModal: React.FC<CarouselModalProps> = ({ open, onClose }) => {
  const dispatch=useDispatch<any>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [isWindowClosed, setIsWindowClosed] = useState<boolean>(false);


  // Fetch the index based on URL
  const getIndexByUrl = (url: string | null): number => {
    if (!url) return 0;
    const index = carouselItems.findIndex((item) => item.url === url);
    return index !== -1 ? index : 0;
  };

  useEffect(() => {
    const checkLocalStorage = () => {
      const storedUrl = localStorage.getItem("ConfirmationMail");
      setUrl(storedUrl || ""); // Set url to empty string if not found or null
      const index = getIndexByUrl(storedUrl || "");
    };
  
    // Call the function immediately to initialize the state
    checkLocalStorage();
  
    // Set up an interval to check every second
    const intervalId = setInterval(checkLocalStorage, 1000);
  
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  
  

  useEffect(() => {
    // Check if the popup window is closed every 1000 milliseconds
    const checkPopupClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        clearInterval(checkPopupClosed); // Stop checking once the popup is closed
        setIsWindowClosed(true);
      }
    }, 1000);

    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [popupWindow]);

  const carouselItems: CarouselItem[] = [
    {
      text1: "Login to your Gmail account.",
      text2:
        "Click the Settings icon (top-right) and then click See all settings from the dropdown.",
      link: "aumsampann@gmail.com",
      image: img1,
      checkUrl: false,
    },
    {
      text1: "Next, go to the Fowarding and POP/IMAP tab.",
      text2: "Then click - Add a forwarding address.",
      link: "aumsampann@gmail.com",
      image: img2,
      checkUrl: false,
    },
    {
      text1:
        "Copy the Forword to email address listed below on this screen and paste it on Gmail.Then click Next.",
      text2:
        "A pop-up asking you to confirm will appear on Gmail.Click Proceed.",
      link: "aumsampann@gmail.com",
      image: img3,
      checkUrl: false,
    },
    {
      text1:
        "Click the link below and click confirm on the page that opens.",
      // text2:
      //   "If a code appears, copy the code into the confirmation box on Gmail and click Verify.If a link appears,simply click the link and click Confirm on the page that opens.",
      url: url || "Fetching URL...",
      image: img4,
      checkUrl: true,
    },
    {
      text1:
        "Next,  make sure to select the Disable forwarding before proceeding any further.",
      text2:
        "Once you have disabled forwarding, you can proceed to creating the forwarding filter.",
      image: img5,
      checkUrl: false,
    },
    {
      text1:
        "Next, go back to your inbox and search for the email containing your file.",
      text2:
        "Once located, select the email>click the 3 Dot menu >click Filter messages like these in the dropdown.",
      image: img6,
      checkUrl: false,
    },
    {
      text1:
        "Now, create a filter that will auto-forword your files to Aumsampann.",
      subText: "Add rules as per the guidelines below:",
      bullet1: "Add a From Email.",
      bullet2:
        "Under Subject, specify keywords that are always part of the email subject.",
      bullet3:
        "Check Has attachment to ensure that only emails that contain a file attached are forwarded.",
      text2: "Next, click Create filter.",
      image: img7,
      checkUrl: false,
    },
    {
      text1:
        "Finally,select the Forward it to option and select the correct auto-forword Aumsampann email address in the dropdown.",
      text2: "Then click, Create filter.",
      text3: "Your auto-forward rule will be created for this template.",
      text4:
        "You are now done!Your future files should now be auto-forwarded and imported to your Aumsampann account.",
      image: "",
      checkUrl: false,
    },
  ];

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, carouselItems.length - 1)
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText("aumsampann@gmail.com");
    setIsCopied(true);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const ModalClose = () => {
    setCurrentIndex(0);
  };
  const mainModalClose = () =>{
    onClose();
  }

  const ModalDone = async () => {
    if (url && isWindowClosed) {
      await dispatch(allowUserToAutoFetch());
      setCurrentIndex(0);
      localStorage?.removeItem("ConfirmationMail");
      setIsWindowClosed(false);
      setUrl("");
      onClose();
    } else {
      setCurrentIndex(0);
      localStorage?.removeItem("ConfirmationMail");
      setIsWindowClosed(false);
      setUrl("");
      onClose();
    }
  };

  const OpenWindow = () => {
    if (url) {
      const newPopupWindow = BrowserPopUpModal(
        url,
        "Confirmation of forwoard email",
        1250,
        550
      );
      setPopupWindow(newPopupWindow);
    }
  };

  return (
    <Modal open={open} onClose={()=>{ModalClose();mainModalClose()}} className="overflow-x-hidden overflow-y-auto">
      <Box
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-4xl bg-lightBg p-4 rounded-lg shadow-lg overflow-hidden"
        sx={{
          height: "87vh", // Set modal height to 90% of viewport height
          maxWidth: "75%", // Limit the width to 75% of the viewport
          overflowY:"auto"
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h6"
            className="text-primary"
            style={{ fontWeight: "bolder" }}
          >
            Set-up Auto Import
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="flex flex-col gap-4">
          {/* <div className="flex justify-center">
            <Typography
              variant="h6"
              className="text-primary text-center"
              style={{ fontWeight: "bolder" }}
            >
              Set-up Auto Import
            </Typography>
          </div> */}
          <div className="flex items-start justify-center bg-white rounded-lg p-5">
            <div className="w-1/2 p-4">
              <div
                className="text-darkGrey mb-4 text-xl"
                // style={{ fontWeight: "semi-bold" }}
              >
                {carouselItems[currentIndex]?.text1}
              </div>
              {carouselItems[currentIndex]?.subText && (
                <div
                  className="text-darkGrey mb-4 text-xl ml-6"
                  // style={{ fontWeight: "semi-bold" }}
                >
                  {carouselItems[currentIndex].subText}
                  <ul className="list-disc pl-5">
                    <li>{carouselItems[currentIndex].bullet1}</li>
                    <li>{carouselItems[currentIndex].bullet2}</li>
                    <li>{carouselItems[currentIndex].bullet3}</li>
                  </ul>
                </div>
              )}
              <div
                className="text-darkGrey mb-6 text-xl"
                // style={{ fontWeight: "semi-bold" }}
              >
                {carouselItems[currentIndex]?.text2}
              </div>
              {carouselItems[currentIndex]?.text3 && (
                <div
                  className="text-darkGrey mb-6 text-xl"
                  // style={{ fontWeight: "semi-bold" }}
                >
                  {carouselItems[currentIndex]?.text3}
                </div>
              )}
              {carouselItems[currentIndex]?.text4 && (
                <div
                  className="text-darkGrey mb-6 text-xl"
                  // style={{ fontWeight: "semi-bold" }}
                >
                  {carouselItems[currentIndex]?.text4}
                </div>
              )}
              {(carouselItems[currentIndex]?.link ||
                carouselItems[currentIndex]?.checkUrl) && (
                <Divider className="bg-primary" />
              )}
              {carouselItems[currentIndex]?.link && (
                <div className="flex items-center gap-2 mt-8">
                  <Typography
                    variant="body1"
                    className="text-darkGrey text-xl"
                    // style={{ fontWeight: "semi-bold" }}
                  >
                    Forwarded to :
                  </Typography>
                  {/* Read-only input field */}
                  <TextField
                    value="aumsampann@gmail.com"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    sx={{
                      width: "250px",
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 6px", // For better control, ensure consistent padding
                      },
                    }}
                  />

                  {/* Copy button */}
                  <Tooltip title="Copy to clipboard">
                    <button
                      className="bg-darkGrey hover:bg-primary text-white px-4 py-2 rounded-md"
                      onClick={handleCopy}
                    >
                      Copy
                    </button>
                  </Tooltip>

                  {/* Snackbar for copy confirmation */}
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Link copied to clipboard!
                    </Alert>
                  </Snackbar>
                </div>
              )}

              {carouselItems[currentIndex]?.checkUrl && (
                <div className="mt-6">
                  <span className="text-darkGrey text-xl">Click here :</span>
                  <a
                    // href={carouselItems[currentIndex].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-700 cursor-pointer"
                    onClick={OpenWindow}
                  >
                    {carouselItems[currentIndex]?.url}
                  </a>
                </div>
              )}
            </div>
            <div className="w-1/2">
              <img
                src={carouselItems[currentIndex]?.image}
                alt={`Carousel item ${currentIndex + 1}`}
                style={{
                  maxWidth: "100%", // Restricts the image to the container width
                  minHeight: "350px", // Limits the image height
                  maxHeight: "400px",
                  objectFit: "contain", // Ensures the image scales proportionally
                  marginLeft: "auto",
                }}
              />
            </div>
          </div>
          <div className="flex justify-end items-center mt-4">
            <button
              className="bg-white text-darkGrey hover:text-white hover:bg-secondary px-4 py-2 border border-darkGrey hover:border-secondary rounded disabled:opacity-50"
              onClick={handlePrevious}
              disabled={currentIndex === 0}
            >
              Previous
            </button>
            <button
              className=" bg-primary text-white hover:bg-secondary px-4 py-2 rounded disabled:opacity-50 ml-2"
              onClick={
                currentIndex === carouselItems?.length - 1
                  ? ModalDone
                  : handleNext
              }
            >
              {currentIndex === carouselItems?.length - 1 ? "Done" : "Next"}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CarouselModal;
