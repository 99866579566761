import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Button as Btn } from "@mui/material";
import { colors } from "../../constants/colors";

interface SuccessfullModalProps {
    open: boolean;
    message:string;
    handleClose: () => void;
  }

const SuccessfullModal: React.FC<SuccessfullModalProps> = ({open,message,handleClose}) => {

  // Custom onClose handler to prevent closing by backdrop click
  const handleDialogClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}  // Disable closing by clicking outside
      disableEscapeKeyDown  // Disable closing by pressing the escape key
    >
      <DialogContent>
      <Typography variant="h6" className="font-bold mt-3 text-center">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
      <Btn
               onClick={handleClose}
                sx={{
                  backgroundColor: colors.primary,
                  // marginX:"auto",
                  marginBottom:2,
                  borderRadius: 2,
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.secondary,
                    color: colors.lightBg,
                  },
                }}
              >
                Ok
              </Btn>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessfullModal;
