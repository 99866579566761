import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import CarouselModal from "./CarouselModal";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import useWebSocket from "../../hooks/useWebSocket";


interface AutoImportProps {
  open: boolean;
  handleClose: () => void;
}

const AutoImportFromGmail: React.FC<AutoImportProps> = ({ open, handleClose }) => {
  // const { connectWebSocket, disconnectWebSocket } = useWebSocket();
  const [forwardModal, setforwardModal] = useState(false);
  const handleForwordClose = () => {
    localStorage?.removeItem("ConfirmationMail");
    setforwardModal(false);
    // disconnectWebSocket();
  };

  const handleSubmit = () => {
    setforwardModal(true);
    // connectWebSocket();
  };


  return (
    <>
      <Modal open={open} onClose={handleClose} className="overflow-y-auto">
        <Box
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-4xl bg-lightBg p-8 rounded-lg shadow-lg overflow-y-auto"
          style={{ maxHeight: "95vh" }}
        >
          <div className="flex justify-between items-center mb-4">
            <Typography
              variant="h6"
              className="text-primary"
              style={{ fontWeight: "bolder" }}
            >
              Set-up auto import from Gmail
            </Typography>

            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div className="flex flex-col gap-4 p-5 bg-white">
          <Typography variant="h6"
              className="text-primary text-center"
              style={{ fontWeight: "bolder" }}>
              Auto-import any file that you receive via email
          </Typography>
          <Typography 
              className="text-darkGrey text-center"
              style={{ fontWeight: "bolder" }}>
            Create a rule in your gmail to auto-forword this file to Aumsampann when received in your inbox.
          </Typography>
              <div className="flex w-full items-center justify-center">
            
                <button
                  className="px-4 py-2 text-white bg-primary hover:bg-secondary text-center rounded-md"
                  onClick={handleSubmit}
                >
                  Start Here
                </button>
              </div>
          </div>
        </Box>
      </Modal>   
      <CarouselModal open={forwardModal} onClose={handleForwordClose} />   
    </>
  );
};

export default AutoImportFromGmail;
