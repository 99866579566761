// DashboardSlice.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashboardModal from '../../models/dashboard.model';
import mainDashboardChartData from "./MainDashboardFilter";


export const dashboardFilter = createAsyncThunk(
    "user/getChartInfo",
    async (data: dashboardModal, thunkAPI: any) => {
        try {
            if (!data) {
                throw new Error("invalid Parameter provided");
            }
            // console.log('Getting data for ', data);

            const response = await mainDashboardChartData.dashboardChartFilter(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const fetchDashboardData = createAsyncThunk(
    "user/getInvestmentData",
    async (data: any, thunkAPI: any) => {
        try {
            const response = await mainDashboardChartData.getDashboardData(data);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getFinanceNewData = createAsyncThunk(
    "user/getFinanceNew",
    async (_, thunkAPI:any) => {
      try {
        const response = await mainDashboardChartData.getFinanceNewData();
        return response; // Response from the API
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  )

  export const getAnalyticsData = createAsyncThunk(
    "user/getAnalyticsData",
    async (data:any, thunkAPI:any) => {
      try {
        const response = await mainDashboardChartData.getAnalyticsData(data);
        return response; // Response from the API
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  )



const initialState = {
    funds: [],
    Dashfunds: [],
    financeData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
};

export const dashboardSlice = createSlice({
    name: "dashboardFilter",
    initialState: initialState,
    reducers: {
        resetState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(dashboardFilter.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(dashboardFilter.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.funds = action.payload;
            })
            .addCase(dashboardFilter.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })

            .addCase(fetchDashboardData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDashboardData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.Dashfunds = action.payload.data;
            })
            .addCase(fetchDashboardData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
            })
            .addCase(getFinanceNewData.pending, (state) => {
                state.isLoading = true;
              })
              .addCase(getFinanceNewData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.financeData = action.payload; // Save the API response
              })
              .addCase(getFinanceNewData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
              })
              .addCase(getAnalyticsData.pending, (state) => {
                state.isLoading = true;
              })
              .addCase(getAnalyticsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.financeData = action.payload; // Save the API response
              })
              .addCase(getAnalyticsData.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error.message || "";
              });
    },
});

export const { resetState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
