import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  TableFooter,
  Typography,
  Box,
  Button,
  Modal,
} from "@mui/material";
import { Close, Edit, Delete } from "@mui/icons-material";
import { GrTransaction } from "react-icons/gr";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import {
  deleteHoldingsData,
  editHoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { DateUtils } from "../../utils/FormatDate";

interface TransactionsModalProps {
  open: boolean;
  onClose: () => void;
  transactions: {
    transactionDate: string;
    investedAmount: number;
    days: number;
  }[];
}

const TransactionsModal: React.FC<TransactionsModalProps> = ({
  open,
  onClose,
  transactions,
}) => {
  const formatTransactionDate = (dateString: any) => {
    if (dateString?.includes("/")) {
      const [day, month, year] = dateString.split("/"); // Convert MM/DD/YYYY format to DD-MM-YYYY
      return `${day}/${month}/${year}`;
    }

    // Check if the date is already in DD-MM-YYYY format
    if (dateString?.includes("-")) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`; // Keep as DD-MM-YYYY
    }

    return dateString; // If the format is unexpected, return the original
  };

  const calculateDays = (dateString: any) => {
    // Convert formatted date string to Date object
    const [day, month, year] = dateString.split("-");
    const transactionDate = new Date(`${year}-${month}-${day}T00:00:00`);
    const today = new Date();

    // Reset the time portion to ignore time differences
    const todayWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    // Calculate the difference in milliseconds
    const timeDiff = todayWithoutTime.getTime() - transactionDate.getTime();
    // Convert milliseconds to days
    const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));

    return isNaN(diffDays) ? 1 : diffDays + 1; // Return 0 days for the same day or NaN case
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800, // Adjust width as needed
          maxHeight: "80vh", // Limit the height of the modal
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          overflowX: "auto", // Make the content scrollable on the Y-axis
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h6">Transaction Details</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "200px",
            borderTop: "1.5px solid rgba(224, 224, 224, 1)",
            padding: "0 20px",
            overflow: "auto", // Enable horizontal scrolling
            maxWidth: "100%", // Ensure the table doesn't exceed the container's width
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "start" }}>
                  Name
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  Transaction Date
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  Invested Amount
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  Withdrawal Amount
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "end" }}>
                  Days
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions?.map((trans: any, index: any) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "start" }}>
                    {formatTransactionDate(trans?.holdingName)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {trans?.transactionDate ? DateUtils.formatDate(formatTransactionDate(trans?.transactionDate)) : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {"Rs."+(formatIndianCurrency(Number(trans?.investedAmount)))}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {"Rs."+(formatIndianCurrency(Number(trans?.redeemValue || 0)))}
                  </TableCell>
                  <TableCell sx={{ textAlign: "end" }}>
                    {calculateDays(
                      formatTransactionDate(trans?.transactionDate)
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

const AIFTableRowComponent = ({ data, getInitials, modalMenuClick,fetchDataAIF }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [selectedTransactions, setSelectedTransactions] = useState<[] | null>(
    null
  );
  const [transModalOpen, setTransModalOpen] = useState(false);
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");

  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const isArray = Array.isArray(data);

  const handleOpenModal = (transactions: any) => {
    setSelectedTransactions(transactions);
    setTransModalOpen(true);
  };
  const handleCloseModal = () => {
    setTransModalOpen(false);
    setSelectedTransactions(null);
  };

  const handleOpen = (
    id: any,
    initialNAV: any,
    currentNAV: any,
    units: any,
    transactionDate: any
  ) => {
    setFields([
      { name: "initialNAV", label: "Purchased NAV", type: "number" },
      { name: "currentNAV", label: "Current NAV", type: "number" },
      { name: "units", label: "Units", type: "number" },
      { name: "transactionDate", label: "Transaction Date", type: "text" },
    ]);
    setValues({
      initialNAV: Number(initialNAV),
      currentNAV: Number(currentNAV),
      units: Number(units),
      transactionDate: transactionDate,
    });
    setID(id);
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        editHoldingsData({
          id: id,
          initialNAV: formData?.initialNAV,
          currentNAV: formData?.currentNAV,
          units: formData?.units,
          transactionDate: formData?.transactionDate,
          holdingType: holdingType,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        fetchDataAIF();
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        fetchDataAIF();
        setMessage(`Scheme edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        fetchDataAIF();
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      fetchDataAIF();
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType: "AIF",
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        fetchDataAIF();
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        fetchDataAIF();
        setMessage(`Scheme deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        fetchDataAIF();
        setMessage(response?.payload.message);
      }
    } catch (error) {
      fetchDataAIF();
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    setOpen(false);
    setMessage("");
  };

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          const Name = item?.holdingName || "N/A";

          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #ccc",
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                  className="min-w-[200px]"
                >
                  <div className="flex gap-x-2 items-center">
                    <div className="w-12 h-10 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                      {getInitials(Name)}
                    </div>
                    <div className="w-full ml-1">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleExpandClick(index)}
                      >
                        {toCamelCase(Name)}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.investedAmount).toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {Number(item?.currentNAV).toFixed(2) || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.units
                    ? Number(item?.units).toFixed(2)
                    : 0}
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.currentAmount).toFixed(2)) || "-"}
                </TableCell>
                {/* <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.realisedPnL}</TableCell>
                                <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.unrealisedPnL}</TableCell> */}
                <TableCell
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.absolutePnL
                    ? Number(item.absolutePnL.replace("%", "")).toFixed(2) + "%"
                    : "-"}
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.xirr ? item?.xirr : "-"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {item?.transactions?.length > 0 ? (
                    <GrTransaction
                      onClick={() => handleOpenModal(item?.transactions)}
                      className="text-gray-600 text-2xl font-bold hover:text-blue-600 mx-auto"
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton>
                    <Edit
                      onClick={() =>
                        handleOpen(
                          item?.id,
                          item?.initialNAV,
                          item?.currentNAV,
                          item?.units? Number(item?.units).toFixed(2)
                            : 0,
                          item?.transactionDate
                        )
                      }
                    />
                  </IconButton>
                  <IconButton>
                    <Delete
                      onClick={() =>
                        handleConfirmModalOpen(item?.id, toCamelCase(item?.holdingName))
                      }
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      {selectedTransactions && (
        <TransactionsModal
          open={transModalOpen}
          onClose={handleCloseModal}
          transactions={selectedTransactions}
        />
      )}
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="AIF"
          heading="AIF Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
        <SuccessfullModal
          open={Open}
          message={Message}
          handleClose={handleSuccessClose}
        />
    </>
  );
};

export default AIFTableRowComponent;
