import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DownArrow from "@mui/icons-material/KeyboardArrowDownOutlined";
import IconButton from "@mui/material/IconButton";
import { colors } from "../../constants/colors";
import { Link, useLocation } from "react-router-dom";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../redux/auth/authSlice";
import authService from "../../redux/auth/authService";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';



export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
    showInvestments: false,
    showSummary: false,
    showHoldings: false,
  });

  const location = useLocation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      left: false,
    }));
  }, [location]);

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const closeInvestments = () => {
    setState({ ...state, showInvestments: false });
  };

  const logout = () => {
    authService.logout();
    dispatch(resetState());
    navigate("/");
  };

  const isButtonActive = (path: string) => {
    return location.pathname === path;
  };

  const toggleInvestments = () => {
    setState({ ...state, showInvestments: !state.showInvestments });
  };

  const toggleHoldings = () => {
    setState({ ...state, showHoldings: !state.showHoldings });
  };

  const closeHoldings = () => {
    setState({ ...state, showHoldings: false });
  };
  const toggleSummary = () => {
    setState((prevState) => ({
      ...prevState,
      showSummary: !prevState.showSummary,
    }));
  };

  // Function to close the dropdown
  const closeSummary = () => {
    setState((prevState) => ({ ...prevState, showSummary: false }));
  };

  const list = (anchor: "left") => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "200vh",
        width: 260,
        backgroundColor: colors.primary,
      }}
    >
      <IconButton
        sx={{
          color: colors.white,
          alignSelf: "flex-end",
          marginRight: "10px",
        }}
        onClick={toggleDrawer("left", false)}
      >
        <CloseIcon />
      </IconButton>
      <List>
        <ListItem
          disablePadding
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "30px",
            marginTop: "10px",
            backgroundColor: isButtonActive("/") ? colors.white : "inherit",
          }}
        >
          <ListItemButton
            component={Link}
            to="/"
            sx={{
              "&:hover": {
                backgroundColor: colors.secondary,
              },
              backgroundColor: isButtonActive("/") ? colors.white : "inherit",
            }}
            selected={isButtonActive("/")}
          >
            <ListItemIcon>
              <DashboardOutlinedIcon
                sx={{
                  fontSize: 32,
                  color: isButtonActive("/") ? colors.primary : colors.white,
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontSize: 32,
                color: isButtonActive("/") ? colors.primary : colors.white,
                fontWeight: "",
              }}
              primary="Dashboard"
            />
          </ListItemButton>
        </ListItem>
        {/* <ListItem
          disablePadding
          sx={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '30px', marginTop: '10px', backgroundColor: isButtonActive('/asset-holdings') ? colors.white : 'inherit' }}
        >
          <ListItemButton
            component={Link}
            to="/asset-holdings"
            sx={{
              '&:hover': {
                backgroundColor: colors.secondary,
              },
              backgroundColor: isButtonActive('/asset-holdings') ? colors.white : 'inherit'
            }}
            selected={isButtonActive('/asset-holdings')}
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon sx={{ fontSize: 32, color: isButtonActive('/asset-holdings') ? colors.primary : colors.white }} />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: 32, color: isButtonActive('/asset-holdings') ? colors.primary : colors.white, fontWeight: '' }}
              primary="Asset Holdings"
            />
          </ListItemButton>
        </ListItem> */}

        <ListItem
          disablePadding
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "30px",
            backgroundColor: state.showHoldings ? colors.white : "inherit",
          }}
        >
          <ListItemButton
            onClick={toggleHoldings}
            sx={{
              borderRadius: 1,
              "&:hover": {
                backgroundColor: colors.secondary,
              },
            }}
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon
                sx={{
                  fontSize: 30,
                  color: state.showHoldings ? colors.primary : colors.white,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Asset Holdings"
              sx={{
                fontSize: 32,
                color: state.showHoldings ? colors.primary : colors.white,
              }}
            />
            <ListItemIcon>
              <DownArrow
                sx={{
                  marginLeft: 3,
                  fontSize: 32,
                  color: state.showHoldings ? colors.primary : colors.white,
                }}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {state.showHoldings && (
          <List sx={{ marginLeft: 1 }}>
            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/asset-holdings")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/asset-holdings"
                onClick={closeHoldings}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/asset-holdings")}
              >
                <ListItemText
                  primary="Upload/View"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/asset-holdings")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}

        <ListItem
          disablePadding
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "30px",
            backgroundColor: state.showInvestments ? colors.white : "inherit",
          }}
        >
          <ListItemButton
            onClick={toggleInvestments}
            sx={{
              borderRadius: 1,
              "&:hover": {
                backgroundColor: colors.secondary,
              },
            }}
          >
            <ListItemIcon>
              <CurrencyRupeeIcon
                sx={{
                  fontSize: 30,
                  color: state.showInvestments ? colors.primary : colors.white,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Investments"
              sx={{
                fontSize: 32,
                color: state.showInvestments ? colors.primary : colors.white,
              }}
            />
            <ListItemIcon>
              <DownArrow
                sx={{
                  marginLeft: 3,
                  fontSize: 32,
                  color: state.showInvestments ? colors.primary : colors.white,
                }}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        {state.showInvestments && (
          <List sx={{ marginLeft: 1 }}>
            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/mf-listing")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/mf-listing"
                onClick={closeInvestments}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/mf-listing")}
              >
                <ListItemText
                  primary="Mutual funds"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/mf-listing")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/fd-listing")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/fd-listing"
                onClick={closeInvestments}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/fd-listing")}
              >
                <ListItemText
                  primary="Corporate Fixed Deposits"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/fd-listing")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/bond-listing")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/bond-listing"
                onClick={closeInvestments}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/bond-listing")}
              >
                <ListItemText
                  primary="Bonds investment"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/bond-listing")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/Equity-listing")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/Equity-listing"
                onClick={closeInvestments}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/Equity-listing")}
              >
                <ListItemText
                  primary="Equity investment"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/Equity-listing")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/aif-listing")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/aif-listing"
                onClick={closeInvestments}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/aif-listing")}
              >
                <ListItemText
                  primary="AIF investment"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/aif-listing")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/pms-listing")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/pms-listing"
                onClick={closeInvestments}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/pms-listing")}
              >
                <ListItemText
                  primary="PMS investment"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/pms-listing")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                backgroundColor: isButtonActive("/unlistedEquity-listing")
                  ? colors.white
                  : "inherit",
              }}
            >
              <ListItemButton
                component={Link}
                to="/unlistedEquity-listing"
                onClick={closeInvestments}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive("/unlistedEquity-listing")}
              >
                <ListItemText
                  primary="Unlisted Equity"
                  sx={{
                    fontSize: 32,
                    color: isButtonActive("/unlistedEquity-listing")
                      ? colors.primary
                      : colors.white,
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding sx={{ backgroundColor: isButtonActive('/asset-holdings') ? colors.white : 'inherit' }}>
              <ListItemButton
                component={Link}
                to="/asset-holdings"
                onClick={closeInvestments}
                sx={{
                  '&:hover': {
                    backgroundColor: colors.secondary,
                  },
                }}
                selected={isButtonActive('/asset-holdings')}
              >
                <ListItemText primary="Asset Holdings" sx={{ fontSize: 32, color: isButtonActive('/asset-holdings') ? colors.primary : colors.white }} />
              </ListItemButton>
            </ListItem> */}
          </List>
        )}

        <ListItem
          disablePadding
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "30px",
            marginTop: "10px",
            backgroundColor: state.showSummary ? colors.white : "inherit",
          }}
        >
          <ListItemButton
            onClick={toggleSummary} // Function to toggle the dropdown
            sx={{
              borderRadius: 1,
              "&:hover": {
                backgroundColor: colors.secondary,
              },
            }}
          >
            <ListItemIcon>
              <SummarizeOutlinedIcon
                sx={{
                  fontSize: 30,
                  color: state.showSummary ? colors.primary : colors.white,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Summary"
              sx={{
                fontSize: 32,
                color: state.showSummary ? colors.primary : colors.white,
              }}
            />
            <ListItemIcon>
              <DownArrow
                sx={{
                  marginLeft: 3,
                  fontSize: 32,
                  color: state.showSummary ? colors.primary : colors.white,
                }}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        {state.showSummary && (
          <List sx={{ marginLeft: 1 }}>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/mf-summary"
                onClick={closeSummary} // Function to close the dropdown
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
              >
                <ListItemText
                  primary="Mutual Funds"
                  sx={{ fontSize: 32, color: colors.white }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/fd-summary"
                onClick={closeSummary}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
              >
                <ListItemText
                  primary="Corporate FD"
                  sx={{ fontSize: 32, color: colors.white }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/aif-summary"
                onClick={closeSummary}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
              >
                <ListItemText
                  primary="AIF"
                  sx={{ fontSize: 32, color: colors.white }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/pms-summary"
                onClick={closeSummary}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
              >
                <ListItemText
                  primary="PMS"
                  sx={{ fontSize: 32, color: colors.white }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}

        <ListItem
          disablePadding
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "30px",
            marginTop: "30px",
            backgroundColor: isButtonActive("/reports")
              ? colors.white
              : "inherit",
          }}
        >
          <ListItemButton
            component={Link}
            to="/reports"
            sx={{
              "&:hover": {
                backgroundColor: colors.secondary,
              },
            }}
            selected={isButtonActive("/reports")}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon
                sx={{
                  fontSize: 32,
                  color: isButtonActive("/reports")
                    ? colors.primary
                    : colors.white,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Reports"
              sx={{
                fontSize: 32,
                color: isButtonActive("/reports")
                  ? colors.primary
                  : colors.white,
              }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "30px",
            marginTop: "30px",
            backgroundColor: isButtonActive("/asset-comparison")
              ? colors.white
              : "inherit",
          }}
        >
          <ListItemButton
            component={Link}
            to="/asset-comparison"
            onClick={closeInvestments}
            sx={{
              "&:hover": {
                backgroundColor: colors.secondary,
              },
            }}
            selected={isButtonActive("/asset-comparison")}
          >
            <ListItemIcon>
              <CompareArrowsIcon
                sx={{
                  fontSize: 32,
                  color: isButtonActive("/asset-comparison")
                    ? colors.primary
                    : colors.white,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Assets Comparison"
              sx={{
                fontSize: 32,
                color: isButtonActive("/asset-comparison")
                  ? colors.primary
                  : colors.white,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider sx={{ my: 2, backgroundColor: colors.white }} />

      <List sx={{ mt: "auto" }}>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/settings"
            sx={{
              "&:hover": {
                backgroundColor: colors.secondary,
              },
            }}
            selected={isButtonActive("/settings")}
            disabled
          >
            <ListItemIcon>
              <SettingsOutlinedIcon
                sx={{ fontSize: 32, color: colors.white }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              sx={{ fontSize: 32, color: colors.white }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ marginTop: "10px" }}>
          <ListItemButton
            onClick={logout}
            sx={{ "&:hover": { backgroundColor: colors.secondary } }}
          >
            <ListItemIcon>
              <LogoutOutlinedIcon sx={{ fontSize: 32, color: colors.white }} />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              sx={{ fontSize: 32, color: colors.white }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer("left", true)}>
        <MenuIcon sx={{ fontSize: 32 }} />
      </Button>
      <SwipeableDrawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </div>
  );
}
