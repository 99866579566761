import * as React from "react";
import styles from "./ZeroDocKyc.module.css";
import { colors } from "../../../constants/colors";
import { Button } from "../../../components";
import { Checkbox, FormControlLabel } from "@mui/material";
import { v4 as uuid4 } from "uuid";

interface ZeroDocKycProps {
  onProceed: () => void;
  userData: any;
  activeSection: string;
}

const ZeroDocKyc: React.FC<ZeroDocKycProps> = ({
  onProceed,
  userData,
  activeSection,
}: any) => {
  const checkAlreadySubmitted = (e: any) => {
    e.preventDefault();
    if (userData?.kyc?.isAadharVerified) {
      onProceed();
    } else {
      handleSaveAndProceed();
    }
  };

  const handleSaveAndProceed = () => {
    const APP_URL = process.env.REACT_APP_URL;
    const appUrl = `${APP_URL}/digilocker-webhook`;
    const encodedUrl = encodeURIComponent(appUrl);
    // Encode the string to Base64
    const base64EncodedUrl = btoa(encodedUrl);
    const uid = uuid4();
    const digiUrl = `https://digilocker-prod.atlaskyc.com/v2/prod/digilocker/signup?digilocker_uid=${uid}&webhook_url=${base64EncodedUrl}`;
    window.location.href = digiUrl;
  };

  return (
    <>
      <div className="flex justify-center sm:justify-start mb-10">
        <form
          onSubmit={(e) => checkAlreadySubmitted(e)}
          className="max-w-screen-xl sm:ml-20"
        >
          <div
            className="text-center text-sm pl-5 pr-5 sm:ml-10 sm:text-xl sm:pr-10 font-bold max-md:mt-10 max-md:mr-2.5 max-md:max-w-full sm:text-left"
            style={{ color: colors.darkGrey }}
          >
            AumSampann Digilocker Integration would like to access your
            Digilocker to:
          </div>
          <div className=" sm:w-full ">
            <ul
              className={`${styles.containerText} text-sm sm:text-lg text-left pr-5 ml-12 sm:pl-8`}
              style={{ color: colors.darkGrey }}
            >
              <li>Get your e-Aadhar Data</li>
              <li>Get your PAN Card Data</li>
              <li>
                Pull documents into your Digilocker <br /> from issuers.
              </li>
            </ul>
          </div>
          <div className="sm:ml-10 pr-10 ml-10 mt-9 sm:flex sm:justify-left align-top sm:w-auto sm:pl-5">
            <FormControlLabel
              required
              control={<Checkbox />}
              label=" By clicking allow, you provide your consent to allow this
                          application to access your DigiLocker."
              className="text-sm md:text-lg"
            />
          </div>
          <div className="pl-8 flex items-center gap-x-2 mt-12">
            <button
              className={`grow justify-center px-4 py-4 whitespace-nowrap rounded-md border bg-white text-darkGrey border-solid hover:bg-secondary hover:text-white transition-all max-w-[150px] w-full`}
              tabIndex={0}
              onClick={() => {
                onProceed();
              }} // Add onClick event handler // Disable button if radio is not selected
            >
              Skip
            </button>
            <Button
              type="submit"
              className={`px-8 py-4 rounded-md bg-primary text-white hover:bg-secondary transition-all max-w-[250px] w-full`}
              tabIndex={0}
              //onClick={() => updateUserRef.current.click()} // Add onClick event handler
              // Disable button if radio is not selected
            >
              Save and Proceed
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ZeroDocKyc;
