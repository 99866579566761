import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts";
import { toCamelCase } from "../../utils/ToCamelCase";

interface SimplePieChartProps {
  data: any;
}

const PieSummary: React.FC<SimplePieChartProps> = ({ data }) => {
  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  // Define a subtle color palette
  const colorPalette = [
    "#A8DADC", "#457B9D", "#F4A261", "#264653", "#E9C46A",
    "#A3A3C2", "#BDE0FE", "#FFB4A2", "#6A994E", "#D4A373",
    "#D5C6E0", "#83C5BE", "#F2CC8F", "#81A4CD", "#B7E4C7",
    "#ADC178", "#FFCB77", "#E5989B", "#A7BED3", "#C3B299",
  ];

  const totalValue = data.reduce((sum: number, item: any) => sum + item.percentage, 0);
  const minPercentage = 0.5; // Minimum slice size threshold (in percentage)

  // Adjust data for small slices
  const adjustedData = data.map((fund: any, index: number) => {
    const value = fund.percentage < minPercentage ? minPercentage : fund.percentage;
    return {
      label: fund.amcName,
      value,
      color: colorPalette[index % colorPalette.length],
    };
  });

  // Recalculate total to maintain correct proportions
  const adjustedTotal = adjustedData.reduce((sum: number, item: any) => sum + item.value, 0);
  const normalizedData = adjustedData.map((item: any) => ({
    ...item,
    value: (item.value / adjustedTotal) * totalValue, // Normalize back to original total
  }));

  function PieCenterLabel() {
    const { width, height, left, top } = useDrawingArea();
    const total = normalizedData.reduce(
      (acc: number, item: { value: number }) => acc + item.value,
      0
    );
    return (
      <g transform={`translate(${left + width / 2}, ${top + height / 2})`}>
        <StyledText x={0} y={-10} fontWeight={"bold"}>
          Total
        </StyledText>
        <StyledText x={0} y={10} color="#A1A1A1">
          {total.toFixed(2) + "%"}
        </StyledText>
      </g>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <Card className="flex-grow flex flex-col">
        <CardContent className="flex-grow flex flex-col">
          <div className="flex-grow flex items-center justify-center">
            {normalizedData.length > 0 ? (
              <PieChart
                series={[
                  {
                    data: normalizedData,
                    cx: "50%",
                    cy: "50%",
                    innerRadius: 100,
                    outerRadius: 140,
                  },
                ]}
                width={400}
                height={400}
                sx={{ padding: 0, margin: 0, border: "none" }}
                margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                slotProps={{ legend: { hidden: true } }}
              >
                <PieCenterLabel />
              </PieChart>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <h6 className="font-bold text-sm text-gray-500">No Data Available</h6>
              </div>
            )}
          </div>

          {normalizedData.length > 0 && (
            <div
              className="flex flex-col gap-4 justify-between mt-5"
              style={{
                maxHeight: "200px",
                overflowY: normalizedData.length > 5 ? "auto" : "hidden",
              }}
            >
              {normalizedData.map((item: any, index: any) => (
                <div
                  key={index}
                  className="flex justify-between items-center"
                  style={{ width: "100%", color: "#A1A1A1" }}
                >
                  <div className="flex w-full items-center gap-x-3">
                    <span
                      className="w-3 h-3 rounded-3xl"
                      style={{ backgroundColor: item.color }}
                    ></span>
                    <h6 className="font-bold text-xs pr-2">{toCamelCase(item.label)}</h6>
                  </div>
                  <span className="font-inter text-xs">
                    {item.value.toFixed(2) + "%"}
                  </span>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PieSummary;
