import React from 'react';
import { Table, TableBody } from '@mui/material';
import TableRowComponent from './TableRowComponent';



const MainTableComponent = ({ data,  modalMenuClick }: any) => {
    // console.log('map Data', data);

    return (
        <>
            {data?.map((row: any) => (
                <TableRowComponent
                    // key={row.scheme.id}
                    data={row}                 
                    modalMenuClick={modalMenuClick}
                   
                />
            ))
            }
        </>
    );
};

export default MainTableComponent;
