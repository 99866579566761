import React, { useState } from "react";
import InfoSection from "../../components/InfoSection/InfoSection";
import person from "../../assets/icons/f7_person-2-normal.png";
import person24 from "../../assets/icons/octicon_person-24 grey (1).png";
import bankDetails from "../../assets/icons/ph_bank_normal.png";
import ConfirmPerson from "../../assets/icons/ConfirmUser.png";
import demat from "../../assets/icons/jam_document_normal.png";
import preference from "../../assets/icons/grommet-icons_checkbox-selected.svg";
import sign from "../../assets/icons/la_file-signature_normal.png";
import Footer from "../../components/Footer/Footer";
import CreditCard from "../../assets/icons/Credit_Card_01.svg"
import AadharCard from "../../assets/icons/File_Document.svg"
import { colors } from "../../constants/colors";

const IndividualFormLayout = ({
  completedSections,
  activeSection,
  children,
  setActiveSection,
}: any) => {
  const infoSections = [
    { icon: CreditCard, title: "PAN", key: "pan", index: 0 },
    { icon: ConfirmPerson, title: "Select Type", key: "select", index: 1 },
    { icon: AadharCard, title: "AADHAR", key: "aadhar", index: 2 },
    { icon: person, title: "General Details", key: "general", index: 3 },
    { icon: person, title: "Nominee Details", key: "nominee", index: 4 },
    { icon: bankDetails, title: "Bank Details", key: "bank", index: 5 },
    // { icon: demat, title: "Demat Details", key: "demat", index: 5 },
    { icon: preference, title: "Preferences", key: "preference", index: 6 },
    { icon: sign, title: "AOF", key: "sign", index: 7 },
  ];
  return (
    <main
      className="flex flex-col items-center pl-2 pr-2 sm:pl-5 min-h-screen"
      style={{ backgroundColor: colors.lightBg }}
    >
      <div
        className="font-bold max-md:max-w-full text-center sm:text-left md:text-left sm:ml-28 md:ml-10  sm:text-3xl text-base mt-16 mb-10"
        style={{ color: colors.primary }}
      >
        Individual or Sole Proprietorship
      </div>
      <div className="flex flex-wrap text-center justify-evenly gap-10 sm:justify-center sm:flex-row sm:row-span-3 mb-10 mx-5 mt-10">
        {/* <div className="flex flex-wrap text-center justify-evenly gap-10 sm:justify-center sm:flex-row sm:row-span-3 mb-10 mx-5 mt-10"> */}
        {infoSections.map((section, index) => (
          <InfoSection
            icon={section.icon}
            title={section.title}
            isActive={section.key === activeSection}
            isCompleted={completedSections[index]}
            isPrevious={section.index < index}
            setActiveSection={setActiveSection}
            sectionKey={section.key}
          />
        ))}
        {/* <InfoSection
              icon={demat}
              title="Demat Details"
              isActive={false}
              isCompleted={completedSections[3]}
              isPrevious={true}
            />
            <InfoSection
              icon={person24}
              title="General Details"
              isActive={false}
              isCompleted={completedSections[0]}
              isPrevious={true}
            />
            <InfoSection
              icon={bankDetails}
              title="Bank Details"
              isActive={false}
              isCompleted={completedSections[1]}
              isPrevious={true}
            /> */}
        {/* </div> */}
        {/* <InfoSection
            icon={person}
            title="Nominee Details"
            isActive={false}
            isCompleted={completedSections[2]}
            isPrevious={true}
          />

          <InfoSection
            icon={sign}
            title="Signatures"
            isActive={false}
            isCompleted={completedSections[5]}
            isPrevious={false}
          /> */}
      </div>
      {/* <p className="text-xl mb-5"
        style={{ color: colors.orange}}
      >  
       All fields are mandatory
      </p> */}
      {children}
    </main>
  );
};

export default IndividualFormLayout;
