import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Text, Button, Input, Heading, Img } from "../../components";
import VerificationModal from "../../components/modals/VerificationModal";
import Header from "../../components/Header/Header";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { colors } from "../../constants/colors";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SignUp } from "../../models/signup.model";
import { addPassword, signUp } from "../../redux/auth/authSlice";
import toast from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../components/Loader/Loader";

const signUpSchema = yup.object({
  firstName: yup.string().required("First Name is required."),
  // lastName: yup.string().required("Last Name is required."),
  email: yup.string().email().required("Email is required."),
  mobile: yup.string().length(13, "Mobile Number should be 10 digit"),
});

const createPasswordSchema = yup.object({
  // userName: yup.string().required("Username is required."),
  password: yup
    .string()
    .required("Password is required.")
    .min(
      8,
      "Password is too short - should be 8 chars minimum with one uppercase, one lowercase and one special character"
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/~`])[A-Za-z\d!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/~`]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm Password is required."),
});

const signUpValues = {
  firstName: "",
  // lastName: "",
  email: "",
  mobile: "",
};

export default function Register() {
  const dispatch = useDispatch<any>();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [signUpData, setSignUpData] = useState<SignUp>();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const accessToken = useSelector((state: any) => state?.auth?.accessToken);
  const refreshToken = useSelector((state: any) => state?.auth?.refreshToken);
  const addedPassword = useSelector((state: any) => state?.auth?.addedPassword);
  const signUpStatus = useSelector((state: any) => state?.auth?.signUpStatus);
  let createPasswordValues = {
    // userName: signUpValues.email || "",
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    if (accessToken && refreshToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      handleSuccess();
    }
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (addedPassword) {
      toast.success("Password created successfully!");
      navigate("/entity");
    }
  }, [addedPassword]);

  useEffect(() => {
    if (signUpStatus === 200) {
      toast.success("OTP has been sent to your email address as well as phone number.");
    }
  }, [signUpStatus]);

  const handleSuccess = () => {
    setOpen(false);
    setIsVerified(true);
    toast.success("Account created successfully!");
  };

  const validateConfirmPassword = (pass1: string, pass2: string) => {
    if (pass1 && pass2) {
      if (pass1 !== pass2) {
        return toast.error("Passwords do not match");
      }
    }
  };

  const handleSignup = async (values: SignUp) => {
    setLoading(true);    
    setSignUpData(values);
    const response = await dispatch(signUp(values));
    if (response?.error?.message) {
      setLoading(false);
      toast.error(response?.payload?.message ?? "Something went wrong!");
      return;
    }
    handleOpen();
    setLoading(false);
    // navigate("/entity");
  };
  return (
    <>
    {loading && <Loader />}
      <div className="flex flex-col items-center justify-start w-full bg-white">
        <div className="flex flex-col items-center justify-start w-full px-5 py-8 md:px-14 md:py-12 bg-primary shadow-xs min-h-screen">
          <div className="flex flex-col items-start justify-start w-full gap-[31px] max-w-[962px]">
            <Heading
              as="h1"
              className="text-center text-white text-lg md:text-3xl"
            >
              Create New Account
            </Heading>
            {isVerified ? (
              <Formik
                initialValues={createPasswordValues}
                validationSchema={createPasswordSchema}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  if (!signUpData?.email) {
                    toast.error("Something went wrong!");
                    return;
                  }
                  dispatch(
                    addPassword({
                      userName: signUpData?.email,
                      password: values.password,
                    })
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (!isValid && errors.password) {
                          toast.error(errors.password);
                          return;
                        } else if (!isValid && errors.confirmPassword) {
                          toast.error(errors.confirmPassword);
                          return;
                        }
                        handleSubmit();
                      }}
                      className="w-full flex flex-col gap-[31px]"
                    >
                      <div className="flex flex-row justify-start items-center w-full gap-[42px]">
                        <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                          <Text
                            size="s"
                            as="p"
                            className="text-center text-white text-sm md:text-lg"
                          >
                            User email
                          </Text>
                          <Input
                            size="sm"
                            name="userName"
                            type="text"
                            // onChange={handleChange("userName")}
                            disabled
                            value={signUpData?.email}
                            className="w-full bg-lightGrey"
                          />
                        </div>
                        <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                          <Text
                            size="s"
                            as="p"
                            className="text-center text-white text-sm md:text-lg"
                          >
                            Password
                          </Text>
                          <Input
                            size="sm"
                            name="password"
                            type={passwordVisible ? "text" : "password"}
                            onChange={handleChange("password")}
                            suffix={
                              <IconButton
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                              >
                                {passwordVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            }
                            value={values.password}
                            className="w-full bg-white"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-start items-center w-full gap-[42px]">
                        <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                          <Text
                            size="s"
                            as="p"
                            className="text-center text-white text-sm md:text-lg"
                          >
                            Confirm Password
                          </Text>
                          <Input
                            size="sm"
                            name="confirmPassword"
                            type="password"
                            onChange={handleChange("confirmPassword")}
                            value={values.confirmPassword}
                            className="w-full bg-white"
                          />
                        </div>
                        <Text
                          as="xs"
                          className="text-lightGrey text-sm max-w-[460px] leading-5 md:mt-8"
                        >
                          A combination of uppercase letters, lowercase
                          letters,numbers, and symbols. At least 8 characters
                          long but 12 or more is better.
                        </Text>
                      </div>
                      <Button
                        className="w-full md:w-48 font-semibold text-white bg-darkGrey border border-white whitespace-nowrap hover:bg-secondary transition-all"
                        color={colors.darkGrey}
                        variant="fill"
                        type="submit"
                      >
                        <span className="text-sm md:text-lg">Proceed</span>
                      </Button>
                    </form>
                  </>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={signUpValues}
                validationSchema={signUpSchema}
                onSubmit={(values) => {
                  console.log("first");
                  handleSignup(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="w-full">
                    <div className="flex flex-row justify-start w-full">
                      <div className="flex flex-col items-start justify-start w-full gap-[34px]">
                        <div className="flex flex-row justify-start w-full gap-[42px]">
                          <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                            <Text
                              size="s"
                              as="p"
                              className="text-center text-white text-sm md:text-lg"
                            >
                              Email ID
                            </Text>
                            <Input
                              size="sm"
                              name="email"
                              type="email"
                              onChange={handleChange("email")}
                              value={values.email}
                              className="w-full bg-white"
                              error={errors.email}
                              touched={touched.email}
                            />
                          </div>
                          <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                            <Text
                              size="s"
                              as="p"
                              className="text-center text-white text-sm md:text-lg"
                            >
                              Phone
                            </Text>
                            <Input
                              size="sm"
                              name="mobile"
                              type="phone"
                              onChange={(e: any) => {
                                console.log(e, "mobile number");
                                setFieldValue("mobile", e);
                              }}
                              value={values.mobile}
                              className="w-full bg-white"
                              error={errors.mobile}
                              touched={touched.mobile}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row justify-start items-center w-full gap-[42px]">
                          <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                            <Text
                              size="s"
                              as="p"
                              className="text-center text-white text-sm md:text-lg"
                            >
                              Full name as per PAN
                            </Text>
                            <Input
                              size="sm"
                              name="firstName"
                              type="text"
                              onChange={handleChange("firstName")}
                              value={values.firstName}
                              className="w-full bg-white"
                              error={errors.firstName}
                              touched={touched.firstName}
                            />
                          </div>
                          {/* <div className="flex flex-col items-start justify-start w-[48%] gap-2">
                            <Text
                              size="s"
                              as="p"
                              className="text-center text-white text-sm md:text-lg"
                            >
                              Last Name
                            </Text>
                            <Input
                              size="sm"
                              type="text"
                              name="lastName"
                              onChange={handleChange("lastName")}
                              value={values.lastName}
                              className="w-full bg-white"
                              error={errors.lastName}
                              touched={touched.lastName}
                            />
                          </div> */}
                        </div>
                        <FormControlLabel
                          required
                          control={<Checkbox sx={{ color: colors.white }} />}
                          label="I am aware that my contact details will be used for all future communications by AUM Sampann."
                          sx={{ color: colors.white }}
                          className="text-sm md:text-lg"
                        />
                        <a href="#" style={{ color: 'white' }}>By proceeding, you agree to our T&C. Plese click here to read them.</a>
                        <div className="flex flex-row justify-start">
                          <Button
                            className="w-full font-semibold text-white bg-darkGrey border border-white whitespace-nowrap hover:bg-secondary transition-all mr-7"
                            color={colors.darkGrey}
                            type="submit"
                            variant="fill"
                          >
                            <span className="text-sm md:text-lg">
                              Verify Email & Phone Number
                            </span>
                          </Button>
                          <Button
                            className="w-full font-semibold text-white bg-darkGrey border border-white hover:bg-secondary transition-all mr-4"
                            color={colors.darkGrey}
                            variant="fill"
                            onClick={() => navigate("/")}
                          >
                            <span className="text-sm md:text-lg">
                             Back To Home
                            </span>
                          </Button>
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{ backgroundColor: colors.white }}
                          >
                           <VerificationModal
                              onClose={handleClose}
                              onSuccess={handleSuccess}
                              signupValues={signUpData}
                              handleSignup={() => handleSignup(values)}
                            />
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
      
    </>
  );
}