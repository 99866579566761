import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companyService from "./companyService";


// Define async thunk to add organization
export const addOrganizationFatca = createAsyncThunk(
  "org/addFatca",
  async (data: any, thunkAPI: any) => {
    try {
      return await companyService.addOrganizationFatca(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getOrganizationFatca = createAsyncThunk(
  "org/getFatca",
  async (data: any, thunkAPI: any) => {
    console.log("Thunk executed with data:", data);
    try {
      const response = await companyService.getOrganizationFatca(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create companySlice
export const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrganizationFatca.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrganizationFatca.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data || {};
      })
      .addCase(addOrganizationFatca.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getOrganizationFatca.pending, (state) => {
        console.log("Fetching FATCA data...");
              state.isLoading = true;
            })
            .addCase(getOrganizationFatca.fulfilled, (state, action) => {
              console.log(action.payload, "Payload of getOrganizationFatca"); 
              state.isLoading = false;
              state.isSuccess = true;
              state.data = action.payload.data;
            })
            .addCase(getOrganizationFatca.rejected, (state, action) => {
              console.log("Error in getOrganizationFatca:", action.error.message);
              state.isError = true;
              state.isLoading = false;
              state.isSuccess = false;
              state.message = action.error.message || "";
            })
  },
});

// Export actions and reducer
export const { resetState } = companySlice.actions;
export default companySlice.reducer;
