import React from 'react';
import { Modal, Button, Box, Typography } from '@mui/material';
import { colors } from '../../constants/colors';

interface ConfirmationModalProps {
  open: boolean;
  type: 'phone' | 'email' | 'bank' | 'text' | 'address';
  value?: string; 
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, type, value, onConfirm, onClose }) => {
  let title;
  let content;

  switch (type) {
    case 'phone':
      title = 'Number Change Request';
      content = (
        <>
          <Typography variant="body1" color="textSecondary" paragraph>
            Your request to update your phone number has been successfully submitted to our compliance team at Aum Sampann.
          </Typography>
          {/* <Typography variant="body1" color="textSecondary">
            Please anticipate a response within 2-3 business days.
          </Typography> */}
        </>
      );
      break;
    case 'email':
      title = 'Email ID Change Request';
      content = (
        <>
          <Typography variant="body1" color="textSecondary" paragraph>
            Your request to update your email ID has been successfully submitted to our compliance team at Aum Sampann.
          </Typography>
          {/* <Typography variant="body1" color="textSecondary">
            Please anticipate a response within 2-3 business days.
          </Typography> */}
        </>
      );
      break;
    case 'bank':
      title = 'Bank Details Update Request';
      content = (
        <>
          <Typography variant="body1" color="textSecondary" paragraph>
            Your request to update your bank details has been successfully submitted to our compliance team at Aum Sampann.
          </Typography>
          {/* <Typography variant="body1" color="textSecondary">
            Please anticipate a response within 2-3 business days.
          </Typography> */}
        </>
      );
      break;
    default:
      title = 'Update Request';
      content = (
        <Typography variant="body1" color="textSecondary">
          Your request has been successfully submitted to our compliance team.
        </Typography>
      );
      break;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Center Modal content
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(0, 0, 0, 0.5)', // for semi-transparent background
          position: 'absolute', // Ensure it fills the viewport
          inset: 0,
        }}
      >
        <Box
          sx={{
            bgcolor: 'white',
            borderRadius: '8px',
            boxShadow: 3,
            p: 4,
            width: 600,
            maxWidth: 'md',
            position: 'relative',
            textAlign: 'center', // Center text horizontally
          }}
        >
          <Button
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'gray',
              '&:hover': {
                color: 'black',
              },
            }}
            onClick={onClose}
            aria-label="Close modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Button>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            gutterBottom
            sx={{ color: colors.primary, fontWeight: 'bold' }}
          >
            {title}
          </Typography>
          {content}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}> {/* Center button horizontally */}
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: colors.primary,
                color: '#fff',
                '&:hover': {
                  backgroundColor: colors.primary,
                },
              }} 
              onClick={onConfirm}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
