import React, { useEffect, useState } from 'react'
import { hideLoading, showLoading } from '../../redux/loader/loaderSlice';
import { MFAssetHoldings } from '../../redux/Reports/ReportSlice';
import { useDispatch } from 'react-redux';
import api from '../../config/apiConfig';
import UnlistedShareComponent from '../../components/ListingTemplate/UnlistedShareTemplete';
import { getPrivateStock } from '../../redux/Stocks/StockSlice';



function UnlistedShare() {
    const dispatch = useDispatch<any>();
    const [loader, setLoader] = useState<boolean>(false);

    const breadcrumbItems = [
        { label: 'Home', href: '/' },
        { label: 'Investment', href: '/' },
        { label: 'Unlisted Equity', },
    ];
    const filterOps: any = [
        {
            name: "Fund Type",
            options: [
                {
                    name: "Equity",
                    value: 1,
                    isChecked: false,
                },
                {
                    name: "Debt",
                    value: 3,
                    isChecked: false,
                },
                {
                    name: "Hybrid",
                    value: 5,
                    isChecked: false,
                },
                {
                    name: "Solution Oriented",
                    value: 7,
                    isChecked: false,
                },
                {
                    name: "GILT",
                    value: 8,
                    isChecked: false,
                },
                {
                    name: "ELSS",
                    value: 9,
                    isChecked: false,
                },
                {
                    name: "MIP",
                    value: 10,
                    isChecked: false,
                },
                {
                    name: "Balanced",
                    value: 11,
                    isChecked: false,
                },
                {
                    name: "STP",
                    value: 12,
                    isChecked: false,
                },
                {
                    name: "FOF",
                    value: 13,
                    isChecked: false,
                },
                {
                    name: "Liquid",
                    value: 14,
                    isChecked: false,
                },
                {
                    name: "Bond",
                    value: 15,
                    isChecked: false,
                },
                {
                    name: "Income",
                    value: 16,
                    isChecked: false,
                },
            ],
        },
    ];

    const [totalEnteries, setTotalEnteries] = useState<number>();
    const [dataEnteries, setDataEnteries] = useState<any[]>();
    const [data, setData] = useState<any>();

    const FetchData = async () => {
        const payload={
            pageIndex: 1,
            pageSize: 1000,
            search: ""
        }
        try {
          const response=await dispatch(getPrivateStock(payload));
          console.log(response,response.payload,response?.payload?.data?.data?.pmsList,"response Unlisted Equity");
          setData(response?.payload?.data?.data?.pmsList);
          setTotalEnteries(response?.payload?.data?.data?.pmsList?.length);
        } catch (error) {
          console.error("Error uploading file:", error);
        }finally{
            setLoader(false);
        }
      };

      useEffect(() => {
        setLoader(true);
        FetchData();
      }, [])
      

    const MFTransations = [
        { key: 'stockName', value: 'Stock Name' },
        { key: 'stockSector', value: 'Stock Sector' },
        { key: 'action', value: 'Action' }
    ];

    const summary = "Unlisted shares are private equity shares that are not listed on any stock exchange. They are owned by a smaller group of investors, including founders, early-stage investors & employees. They are not traded publicly but are bought and sold in the over-the-counter market. Additionally, they are not subject to the same regulatory requirements & obligations. By investing in unlisted, investors get greater control  & influence over the company. "


    return (
        <UnlistedShareComponent
            title='Unlisted Equity'
            breadcrumb={breadcrumbItems}
            filterOps={filterOps}
            totalEnteries={totalEnteries}
            dataEnteries={data}
            controlBarObj={MFTransations}
            summary={summary}
            setDataEnteries={setDataEnteries}
            loaderData={loader}
        />
        
    )
}

export default UnlistedShare;