import { Card, CardContent } from "@mui/material";
import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts";
import { colors } from "../../constants/colors";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";

function PieChart_Component({ data, additionalData }: any) {
  const [innerRadius, setInnerRadius] = useState(120);
  const [outerRadius, setOuterRadius] = useState(150);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 440) {
        setInnerRadius(100);
        setOuterRadius(120);
      } else {
        setInnerRadius(120);
        setOuterRadius(150);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalValueInvestedMF = Number(additionalData[0]?.totalAmount) || 0;
  const totalValueInvestedFD = Number(data?.totalValueInvestedFD) || 0;
  const totalValueInvestedAif = Number(data?.totalValueInvestedAif) || 0;
  const totalValueInvestedPms = Number(data?.totalValueInvestedPms) || 0;
  const totalValueInvestedBond = Number(data?.totalValueInvestedBond) || 0;
  const totalValueInvestedStock = Number(data?.totalValueInvestedStock) || 0;
  const othersTotalInvestedValue =
    Number(data?.totalValueInvestedPrivateStock || 0) +
    Number(data?.totalValueInvestedPPF || 0);

  const rawData = [
    { id: 0, value: totalValueInvestedMF, label: "Mutual Fund" },
    { id: 1, value: totalValueInvestedAif, label: "AIF" },
    { id: 2, value: totalValueInvestedPms, label: "PMS" },
    { id: 3, value: totalValueInvestedBond, label: "Bond" },
    { id: 4, value: totalValueInvestedFD, label: "Fixed Deposit" },
    { id: 5, value: totalValueInvestedStock, label: "Equity" },
    { id: 6, value: othersTotalInvestedValue, label: "Others" },
  ];

  const total = rawData.reduce((sum, item) => sum + item.value, 0);
  const minPercentage = 0.01; // Minimum slice size: 1% of the total
  const minValue = total * minPercentage;

  // Adjust data for visibility
  const adjustedData = rawData.map((item) => ({
    ...item,
    value: item.value < minValue && item.value > 0 ? minValue : item.value,
  }));

  return (
    <div className="h-full flex flex-col">
      <Card className="flex-grow flex flex-col">
        <CardContent className="flex-grow flex flex-col">
          <div className="flex-grow flex  items-center justify-center">
            <PieChart
              series={[
                {
                  data: adjustedData,
                  innerRadius: innerRadius,
                  outerRadius: outerRadius,
                  paddingAngle: 0,
                  cornerRadius: 0,
                  startAngle: 0,
                  endAngle: 360,
                },
              ]}
              colors={[
                "#97C5F0",
                "#E0C0F4",
                "#F99BAB",
                "#FFB44F",
                "#C3F3E0",
                "#e0b438",
                "#af7686",
              ]}
              sx={{ width: "100%", padding: 0, margin: 0, border: "none" }}
              height={300}
              margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          </div>
          <div className="flex flex-col gap-4 justify-between mt-5">
            {totalValueInvestedMF > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#97C5F0]"></span>
                  <h6 className="font-bold">Mutual Fund: </h6>
                </div>
                <span className="font-inter">
                  {formatIndianCurrency(totalValueInvestedMF.toFixed(2))}
                </span>
              </div>
            )}
            {totalValueInvestedAif > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#E0C0F4]"></span>
                  <h6 className="font-bold">AIF: </h6>
                </div>
                <span className="font-inter">
                  {formatIndianCurrency(totalValueInvestedAif.toFixed(2))}
                </span>
              </div>
            )}

            {totalValueInvestedPms > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#F99BAB]"></span>
                  <h6 className="font-bold">PMS: </h6>
                </div>
                <span className="font-inter">
                  {formatIndianCurrency(totalValueInvestedPms.toFixed(2))}
                </span>
              </div>
            )}

            {totalValueInvestedBond > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#FFB44F]"></span>
                  <h6 className="font-bold">Bond: </h6>
                </div>
                <span className="font-inter">
                  {formatIndianCurrency(totalValueInvestedBond.toFixed(2))}
                </span>
              </div>
            )}

            {totalValueInvestedFD > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#C3F3E0]"></span>
                  <h6 className="font-bold">Fixed Deposit: </h6>
                </div>
                <span className="font-inter">
                  {formatIndianCurrency(totalValueInvestedFD.toFixed(2))}
                </span>
              </div>
            )}

            {totalValueInvestedStock > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#e0b438]"></span>
                  <h6 className="font-bold">Equity: </h6>
                </div>
                <span className="font-inter">
                  {formatIndianCurrency(totalValueInvestedStock.toFixed(2))}
                </span>
              </div>
            )}
            {othersTotalInvestedValue > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#af7686]"></span>
                  <h6 className="font-bold">Others: </h6>
                </div>
                <span className="font-inter">
                  {formatIndianCurrency(othersTotalInvestedValue.toFixed(2))}
                </span>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default PieChart_Component;

