import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
  Typography,
  Paper,
} from "@mui/material";
import { colors } from "../../constants/colors";

type Article = {
  title: string;
  url: string;
  thumbnail: string;
  publishedAt: string;
};

type Props = {
  data: Article[];
};

const FinancialNewzTable: React.FC<Props> = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false); // Track visibility of the table
  const [scrollIndex, setScrollIndex] = useState(0); // Track current row to scroll
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]); // Reference to table rows
  const tableContainerRef = useRef<HTMLDivElement>(null); // Reference to table container

  // Format published date function
  const formatPublishedDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(date).toLocaleString("en-GB", options); // Use en-GB for day/month formatting
  };

  // IntersectionObserver to track visibility of the table
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting); // Set visibility to true when in view
      },
      { threshold: 1.0 } // Trigger only when the full table is visible (100%)
    );

    if (tableContainerRef.current) {
      observer.observe(tableContainerRef.current); // Observe the table container
    }

    return () => {
      if (tableContainerRef.current) {
        observer.unobserve(tableContainerRef.current); // Clean up observer
      }
    };
  }, []);

  // Auto-scroll logic, starts only when the table is visible
  useEffect(() => {
    if (!isVisible) return; // Do not start scrolling if the table is not visible

    const interval = setInterval(() => {
      setScrollIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 2500); // Scroll every 2.5 seconds

    return () => clearInterval(interval); // Cleanup on unmount or visibility change
  }, [isVisible, data.length]);

  // Scroll to the target row when scrollIndex changes
  useEffect(() => {
    if (isVisible && rowRefs.current[scrollIndex]) {
      rowRefs.current[scrollIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest", 
      });
    }
  }, [scrollIndex]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 400,
        overflowY: "auto",
        overflowX: "hidden",
        border: "1.5px solid rgb(237,237,237)",
        borderRadius: 2,
      }}
      ref={tableContainerRef}
    >
      <Table>
        <TableBody>
          {data.map((article, index) => (
            <TableRow
              key={index}
              ref={(el) => (rowRefs.current[index] = el)}
            >
              <TableCell>
                {/* <Typography variant="h6" sx={{ fontWeight: 600, color: colors.darkGrey }}>
                  {article.title}
                </Typography> */}
                <Typography>
                  <Link
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm md:text-lg"
                    sx={{
                      display: "block",
                      textDecoration: "none",
                      wordBreak: "break-word",
                      marginBottom: 1,
                    }}
                  >
                    {article.title}
                  </Link>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatPublishedDate(article.publishedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinancialNewzTable;
