import React, { useEffect, useState } from "react";

import styles from "../../IndividualForms/Preferences/Preferences.module.css";
import { colors } from "../../../constants/colors";
import { useDispatch } from "react-redux";
import { addPreferences } from "../../../redux/user/userSlice";
import { PreferenceType } from "../../../enums/preferences";
import { Button } from "../../../components";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import { FormControlLabel, Radio, RadioGroup, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useUserData } from "../../../hooks/useUserData";

interface PreferenceDetailsProps {
  onPreferenceToPreferences2: () => void; // Prop to handle transition to Demat Details
  allowToProceed: () => void;
  userData:any
}

const Preferences: React.FC<PreferenceDetailsProps> = ({
  onPreferenceToPreferences2,
  allowToProceed,userData
}) => {
  const dispatch = useDispatch<any>(); // redux function
  const [fileName, setFileName] = useState<string | null>(null);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [investmentPlan, setInvestmentPlan] = useState<string>("Direct");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isAumAccount, setIsAumAccount] = useState("0");
  const [availableData, setAvailableData] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userData?.preferences && Object.values(userData?.preferences).length > 0) {
      const options = [];
      userData?.preferences?.aif === 1 && options.push(PreferenceType.aif);
      userData?.preferences?.bonds === 1 && options.push(PreferenceType.bonds);
      userData?.preferences?.corporateFD === 1 &&
        options.push(PreferenceType.corporateFD);
      userData?.preferences?.equity === 1 &&
        options.push(PreferenceType.equity);
      userData?.preferences?.mutualFunds === 1 &&
        options.push(PreferenceType.mutualFunds);
        userData?.preferences?.direct === 1 &&
        options.push(PreferenceType.direct);
        userData?.preferences?.regular === 1 &&
        options.push(PreferenceType.regular);
      userData?.preferences?.pms === 1 && options.push(PreferenceType.pms);
      setSelectedOptions(options);
      setAvailableData(true);
      setUpdate(true)
    }
  }, [userData?.preferences]);

  const handleCheckboxChange = (option: string) => {
    if (selectedOptions?.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const renderCheckbox = (option: string) => {
    return (
      <label key={option} className="flex items-center ml-10 text-black">
        <input
          type="checkbox"
          checked={selectedOptions?.includes(option)}
          onChange={() => handleCheckboxChange(option)}
          className={`mr-2 ${styles.checkbox}`}
          disabled={availableData}
        />
        {option}
      </label>
    );
  };

  // const renderCheckbox = (option: string) => {
  //   const isDisabled = ["Bonds", "AIF or PMS", "Equity"]?.includes(option); // Check if the option should be disabled

  //   return (
  //     <label
  //       key={option}
  //       className={`flex items-center ml-10 ${isDisabled ? "text-gray-400" : "text-black"
  //         }`}
  //     >
  //       <input
  //         type="checkbox"
  //         checked={!isDisabled && selectedOptions?.includes(option)}
  //         onChange={() => handleCheckboxChange(option)}
  //         className={`mr-2 ${styles.checkbox}`}
  //         disabled={update || isDisabled} // Disable the checkbox if it's in the disabled options list
  //       />
  //       {option}
  //     </label>
  //   );
  // };
  const handleInvestmentPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvestmentPlan(event.target.value);
  };

  const renderRadio = (option: string) => (
    <label key={option} className="flex items-center ml-10">
      <input
        type="radio"
        checked={investmentPlan === option}
        onChange={() => setInvestmentPlan(option)}
        className={`mr-2 ${styles.checkRadio}`}
      />
      {option}
    </label>
  );

  const onAddPreferences = () => {
    const payload = {
      mutualFund: selectedOptions?.includes("Mutual Funds") ? 1 : 0,
      bond: selectedOptions?.includes("Bonds") ? 1 : 0,
      aif: selectedOptions?.includes("AIF or PMS") ? 1 : 0,
      pms: selectedOptions?.includes("AIF or PMS") ? 1 : 0,
      corporateFD: selectedOptions?.includes("Corporate FD") ? 1 : 0,
      equity: selectedOptions?.includes("Equity") ? 1 : 0,
      direct: investmentPlan === "Direct" ? 1 : 0,
      regular: investmentPlan === "Regular" ? 1 : 0,
    };

    dispatch(addPreferences(payload));
    onPreferenceToPreferences2(); // Move to the next step
    setIsModalVisible(false); // Hide the modal after confirming
  };

  const handleButtonClick = () => {
    if (update) {
      onPreferenceToPreferences2();
    } else {
      setIsModalVisible(true);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Close the modal without action
  };

  const validate = () => {
    return (
      selectedOptions?.includes("Mutual Funds") ||
      selectedOptions?.includes("Bonds") ||
      selectedOptions?.includes("AIF or PMS") ||
      selectedOptions?.includes("AIF or PMS") ||
      selectedOptions?.includes("Corporate FD") ||
      selectedOptions?.includes("Equity")
    );
  };


  return (
    <>
      <div className="mx-auto sm:w-full md:px-8 lg:px-14 mt-5">
        <div className="flex flex-col gap-1.5 items-center justify-center w-full md:w-auto md:items-center lg:items-start lg:ml-32">
          <div
            className="flex flex-col md:flex-row md:ml-5 justify-between items-center md:w-1/2 my-8"
          >
            <h1
              className="text-base pl-5 pr-5 md:text-xl"
              style={{ color: colors.darkGrey }}
            >
              Demat account already with AUM Capital? *
            </h1>
            <ToggleButtonGroup
              color="primary"
              value={isAumAccount}
              exclusive
              onChange={(e: any) => setIsAumAccount(e.target.value)}
              aria-label="Platform"
              sx={{
                "& .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  backgroundColor: colors.primary,
                  color: colors.white,
                  fontWeight: "bold",
                },
              }}
            >
              <ToggleButton value="1">Yes</ToggleButton>
              <ToggleButton value="0">No</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="mb-10 md:pl-5 md:pr-5">
            <h1
              className="text-base pl-5 pr-5 md:text-xl"
              style={{ color: colors.darkGrey }}
            >
              What types of assets are you interested in investing in? *
            </h1>
            <div className="flex flex-col md:flex-row text-start justify-start gap-2 text-sm md:text-lg md:mt-5 mt-5">
              {[
                "Mutual Funds",
                "Bonds",
                "AIF or PMS",
                "Equity",
                "Corporate FD",
              ].map(renderCheckbox)}
            </div>
          </div>
          {
            (selectedOptions?.includes("Mutual Funds") || availableData) && (
              <div className="mt-5 mx-12">
              <Typography variant="body1" fontSize="18px" gutterBottom style={{ color: colors.darkGrey }}>
                Which investment plan do you prefer for mutual funds: *
              </Typography>
              <RadioGroup
                row
                value={investmentPlan}
                onChange={handleInvestmentPlanChange}
              >
                <FormControlLabel
                  value="Direct"
                  control={<Radio disabled={availableData}/>}
                  label="Direct"
                />
                <FormControlLabel
                  value="Regular"
                  control={<Radio disabled={availableData}/>}
                  label="Regular"
                />
              </RadioGroup>
            </div>
            )
          }
        </div>
        <div className="ml-4 lg:ml-44 md:ml-32 mt-1 mb-10">
          <section className="mt-10 mb-5 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-5 justify-between  mt-12 text-sm max-md:mt-10">
                    <button
                      type="submit"
                      className="form-button"
                      tabIndex={0}
                      onClick={() => handleButtonClick()} // Add onClick event handler
                      // Disable button if radio is not selected
                      disabled={!validate()}
                    >
                      Save and Proceed
                    </button>
                    <ConfirmationModal
                      isVisible={isModalVisible} // Display the modal based on this state
                      message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                      onConfirm={onAddPreferences} // Confirm action, which adds preferences and proceeds
                      onCancel={handleModalCancel} // Cancel action, just closes the modal
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Preferences;