import React from "react";
import {
  TableRow,
  TableCell
} from "@mui/material";
import getInitials from "../../utils/getInitailasIMGUtils";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { DateUtils } from "../../utils/FormatDate";


const TableRowComponent = ({ data }: any) => {
  const isArray = Array.isArray(data);

  const isValidAssetName = (assetName: string) => {
    return assetName && assetName !== "-" && assetName.trim() !== "";
  };

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          return (            
            <>
            {item?.promoter && isValidAssetName(item?.promoter) && (
              <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "none" }}
                >
                  <div className="flex gap-x-1 items-center">
                    <div className="w-3/5">
                      <div
                        className="flex cursor-pointer items-center"
                        // onClick={() => handleToggleRow(index)}
                      >
                        <span className="flex w-12 h-12 items-center justify-center bg-lightGrey p-4 rounded-[100%] mr-4 text-primary font-semibold">
                {getInitials(item.promoter)}
              </span>
              <span className="font-bold text-primary">{toCamelCase(item.promoter)}</span>
                      </div>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  sx={{ border: "none" }}
                >
                  {formatIndianCurrency(item?.faceValue) || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >
                  {item?.bondYield || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >
                  {formatIndianCurrency(item?.bondPrice) || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >
                  {item?.interestPaymentFrequency||"-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >
                  {item?.couponRate||"-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >                  
                  {item?.maturityDate? DateUtils.formatDate(item?.maturityDate):"-"}
                </TableCell>
                </TableRow>
              {item?.promoter && isValidAssetName(item?.promoter) && (
              <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow>)}
            </React.Fragment>
        )}
            </>
          );
        })}
    </>
  );
};

export default TableRowComponent;
