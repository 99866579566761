/**
 * Formats a number or string value into Indian currency format.
 * 
 * @param value - The numeric value to format, either as a number or string.
 * @returns A formatted string in the Indian numbering system with commas.
 */
export const formatIndianCurrency = (value: number | string): string => {
  let numericValue: number;

  if (typeof value === "string") {
    numericValue = parseFloat(value);
  } else {
    numericValue = value;
  }

  if (isNaN(numericValue)) {
    return "Invalid number";
  }

  const [integerPart, decimalPart] = numericValue.toString().split(".");
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  const formattedInteger =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    (otherDigits ? "," : "") +
    lastThreeDigits;

  return decimalPart
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
};
