import React, { FC, useEffect, useRef, useState } from "react";
import FormDatePicker from "../../../components/FormDatePicker/FormDatePicker";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import person from "../../../assets/icons/f7_person-2 blue.png";
import { colors } from "../../../constants/colors";

import { Formik } from "formik";
import * as yup from "yup";

import dayjs from "dayjs";
import styles from "../Individual.module.css";
import file from "../../../assets/icons/file.png";
import Buttons from "../../../components/Buttons/IndividualButtons/Buttons";
import { useDispatch } from "react-redux";
import { getUserById, updateUser } from "../../../redux/user/userSlice";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/core";
import { useUserData } from "../../../hooks/useUserData";
import toast from "react-hot-toast";
import { FormInput } from "../../../components/FormInput/FormInput";
import FormSelect from "../../../components/FormSelect/FormSelect";
import { City, State } from "country-state-city";
import { IndianStates, getKeyByValue } from "../../../enums/states";
import { getStatesOfCountry } from "country-state-city/lib/state";
import { Button } from "../../../components";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import { addOrganization } from "../../../redux/NonInd_GeneralDetails/generalSlice";

interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}

interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name?: string;
  type?: string;
  disabled?: boolean;
}

const businessTypeOptions = [
  { label: "HUF", value: "1" },
  { label: "Corporate", value: "2" },
  { label: "LLP", value: "3" },
  { label: "Trust", value: "4" },
];

const addressType= [
  { label: "Residential or Business", value: "1" },
  { label: "Residential", value: "2" },
  { label: "Business", value: "3" },
  { label: "Registered Office", value: "4" },
  { label: "Unspecified", value: "5" },
];

interface Country {
  label: string;
  value: string;
}

interface State {
  name: string;
}

interface City {
  name: string;
}

type SectionKey = "HUF" | "Corporate" | "LLP" | "Trust";
interface GeneralDetailsFormProps {
  onGeneralToCompany: () => void;
  activeSection: string;
  allowToProceed: () => void;
  onBusinessTypeChange: (businessType: SectionKey) => void;
  userData: any;
}

const userValues = {
  email: "",
  mobile: "",
  companyName: "",
  businessType: "",
  authSignName: "",
  designation: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  country: "",
  addressType:"",
  pincode:"",
  // placeOfIncorporation:"",
  residentialCountry:"",
};

const userSchema = yup.object({
  companyName: yup.string().required("Company Name is required."),
  businessType: yup.string().required("Business Type is required."),
  authSignName: yup.string().required("Authorised Signatory Name is required."),
  designation: yup.string().required("Designation Signatory Name is required."),
  address1: yup.string().required("Address is required."),
  address2: yup.string().required("Address is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  country: yup.string().required("Country is required."),
  addressType: yup.string().required("Address Type is required."),
 pincode: yup.string().required("Pincode is required."),
  residentialCountry: yup.string().required("Residential Country is required."),
});

const GeneralDetailsForm: FC<GeneralDetailsFormProps> = ({
  onGeneralToCompany,
  activeSection,
  allowToProceed,
  onBusinessTypeChange,
  userData,
}) => {
  const formikRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [countries, setCountries] = useState<Country[]>([
    {
        "label": "India",
        "value": "IN"
    }
]);
  const [isModalVisible, setIsModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessage2, setErrorMessage2] = useState("");

  const [generalData, setGeneralData] = useState<any>({
    companyName: "",
    businessType: "",
    authSignName: "",
    designation: "",
    email: "",
    mobile: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    addressType:"",
    pincode:"",
    residentialCountry:"",
  });

  const isGeneralDataComplete = () => {
    console.log(generalData, "genearalData");
    // Iterate over the values and check if any are empty
    return Object.values(generalData).every((value) => value !== "");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUserById({}));
    getStates();
    // fetch("https://restcountries.com/v3.1/all")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // Filter only the country with the name "India"
    //     const countriesData = data
    //       .filter((country: any) => country.name.common === "India")
    //       .map((country: any) => ({
    //         label: country.name.common,
    //         value: country.cca2, // Country code (ISO 3166-1 alpha-2)
    //       }));

    //     // Set the filtered data to the state
    //     setCountries(countriesData);
    //   })
    //   .catch((error) => console.error("Error fetching country data:", error));
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      formikRef.current?.setFieldValue("country", "IN");
      addGeneralData("country", "IN");
      formikRef.current?.setFieldValue("residentialCountry", "IN");
      addGeneralData("residentialCountry", "IN");
      if (userData?.email) {
        formikRef.current?.setFieldValue("email", userData?.email);
        addGeneralData("email", userData?.email);
      }
      if (userData?.mobile) {
        formikRef.current?.setFieldValue("mobile", userData?.mobile);
        addGeneralData("mobile", userData?.mobile);
      }
      if (userData?.organization?.companyName) {
        formikRef.current?.setFieldValue(
          "companyName",
          userData?.organization?.companyName
        );
        addGeneralData("companyName", userData?.organization?.companyName);
      }
      if (userData?.organization?.businessType) {
        formikRef.current?.setFieldValue(
          "businessType",
          userData?.organization?.businessType
        );
        addGeneralData("businessType", userData?.organization?.businessType);
      }
      if (userData?.organization?.authSignName) {
        formikRef.current?.setFieldValue(
          "authSignName",
          userData?.organization?.authSignName
        );
        addGeneralData("authSignName", userData?.organization?.authSignName);
      }
      if (userData?.organization?.designation) {
        formikRef.current?.setFieldValue(
          "designation",
          userData?.organization?.designation
        );
        addGeneralData("designation", userData?.organization?.designation);
      }
      if (userData?.address1) {
        formikRef.current?.setFieldValue("address1", userData?.address1);
        addGeneralData("address1", userData?.address1);
      }
      if (userData?.address2) {
        formikRef.current?.setFieldValue("address2", userData?.address2);
        addGeneralData("address2", userData?.address2);
      }
      
      if (userData?.state) {
        const state = getKeyByValue(userData?.state);
        formikRef.current?.setFieldValue("state", state);
        addGeneralData("state", state);
        getCities(state);
      }
      if (userData?.city) {
        formikRef.current?.setFieldValue("city", userData?.city);
        addGeneralData("city", userData?.city);
      }
      if (userData?.addressType) {
        formikRef.current?.setFieldValue("addressType", userData?.addressType);
        addGeneralData("addressType", userData?.addressType);
      }
      if (userData?.pincode) {
        formikRef.current?.setFieldValue("pincode", userData?.pincode);
        addGeneralData("pincode", userData?.pincode);
      }
    }
  }, [userData]);

  const addGeneralData = (key: string, value: string) => {
    setGeneralData((prevData: any) => ({ ...prevData, [key]: value }));
  };


  const getStates = () => {
    setStates(
      State.getStatesOfCountry("IN").map((state) => {
        return {
          label: state.name,
          value: state.name,
          isoCode: state?.isoCode,
          code: IndianStates[state.name],
        };
      })
    );
  };

  const getCities = (state: any) => {
    const currentState = getStatesOfCountry("IN").filter((item: any) => {
      return item.name === state;
    })[0];
    setCities(
      City.getCitiesOfState("IN", currentState?.isoCode).map((x: any) => {
        return { label: x.name, value: x.name };
      })
    );
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false);

    if (formikRef.current) {
      // Trigger Formik's submission handler
      await formikRef.current.handleSubmit();

      const values = formikRef.current.values;
      console.log(values);
      const body = {
        companyName: values.companyName,
        businessType: values.businessType,
        authSignName: values.authSignName,
        designation: values.designation,
        email: values.email,
        mobile: values.mobile,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: IndianStates[values.state],
        country: values.country,
        addressType:values.addressType,
        pincode:values.pincode
      };
      console.log(body, "Body");
      try {
        const response = await dispatch(addOrganization(body));
        console.log(
          response,
          response.payload,
          response.payload.data,
          response.payload.data.data,
          "Response"
        );
        if (response.payload.success === 200) {
          toast.success("Organization added successfully!");
          allowToProceed();
          onGeneralToCompany(); // Proceed to next step
        } else {
          toast.error("Failed to add organization. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        console.error(error);
      }
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = () => {
    if (formikRef.current?.isValid && formikRef.current?.dirty) {
      console.log(formikRef.current?.values,"Form Values");
      setIsModalVisible(true);  // Show the modal if form is valid
    }
    else {
      return;
    }
  };

  const checkAlreadySubmitted = () => {
    if (isGeneralDataComplete()) {
      allowToProceed();
      onGeneralToCompany();
    } else {
      handleSaveAndProceed();
    }
  };

  // const fetchCityAndState = (pincode: string) => {
  //   if (!pincode || pincode.length !== 6) {
  //     return;
  //   }
  //   fetch(`https://api.postalpincode.in/pincode/${pincode}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data, "data");
  //       if (data[0].Status === "Success") {
  //         const { PostOffice } = data[0];
  //         if (PostOffice.length > 0) {
  //           formikRef.current.setFieldValue("state", PostOffice[0].State);
  //           getCities(PostOffice[0].State);
  //           // if (PostOffice[0].District === "Bangalore") {
  //           //   formikRef.current.setFieldValue("city", "Bengaluru");
  //           // }
  //           // formikRef.current.setFieldValue("city", PostOffice[0].District);
  //         } else {
  //           toast("No data found for this pincode");
  //         }
  //       } else {
  //         toast("Invalid pincode");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  const [passingData, setPassingData] = useState({});

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        <Formik
          innerRef={formikRef}
          initialValues={userValues}
          validationSchema={userSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("Form Values:", values);
            setSubmitting(false); // Reset the submitting state after processing
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            const detailsPassing = ({
              field,
              value,
            }: {
              field: string;
              value: any;
            }) => {
              setPassingData((prevData) => ({ ...prevData, [field]: value }));
            };
            return (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log(values, "values");
                    console.log(errors, "errors");
                    handleSubmit();
                  }}
                  className="w-full flex flex-col"
                >
                  <div className="flex flex-row flex-wrap gap-4 justify-between px-12">
                    <div className="w-[48%] mb-2">
                      <FormInput
                        label="Company Name *"
                        name="companyName"
                        onChange={handleChange("companyName")}
                        value={values.companyName}
                        disabled={userData?.organization?.id}
                        error={errors.companyName}
                        touched={touched.companyName}

                      />
                    </div>
                    <div className="w-[48%] mb-2">
                      <FormSelect
                        label="Business Type *"
                        options={businessTypeOptions}
                        onChange={handleChange("businessType")}
                        value={values.businessType}
                        error={errors.businessType}
                        touched={touched.businessType}
                        disabled={userData?.organization?.id}
                      />
                    </div>
                    <div className="w-[48%] mb-2">
                      <FormSelect
                        label="Address Type *"
                        options={addressType}
                        onChange={handleChange("addressType")}
                        value={values.addressType}
                        error={errors.addressType}
                        touched={touched.addressType}
                        disabled={userData?.organization?.id}
                      />
                    </div>
                    <div className="w-[48%] mb-2">
                      <FormSelect
                        label="Country Of Incorporation *"
                        name="country"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("country", e.target.value);
                        }}
                        value={values.country ||countries[0]?.value}
                        disabled={userData?.organization?.id}
                        error={errors.country}
                        touched={touched.country}
                        options={countries}
                      />
                    </div>
                    {/* <div className="w-[48%]">
                      <FormInput
                        label="Place Of Incorporation (City) *"
                        name="placeOfIncorporation"
                        onChange={handleChange("placeOfIncorporation")}
                        value={values.placeOfIncorporation}
                        disabled={userData?.organization?.id}
                        error={errors.placeOfIncorporation}
                        touched={touched.placeOfIncorporation}
                      />
                    </div> */}
                    <div className="w-[48%] mb-2">
                      <FormSelect
                        label="Residential Country *"
                        name="residentialCountry"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("residentialCountry", e.target.value);
                        }}
                        value={values.country ||countries[0]?.value}
                        disabled={userData?.organization?.id}
                        error={errors.country}
                        touched={touched.country}
                        options={countries}
                      />
                    </div>
                    <div className="w-[48%] mb-2">
                      <FormSelect
                        label="State *"
                        name="state"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("state", e.target.value);
                          getCities(e.target.value);
                        }}
                        value={values.state}
                        disabled={userData?.state}
                        error={errors.state}
                        touched={touched.state}
                        options={states}
                      />
                    </div>
                    <div className="w-[48%] mb-8">
                      {userData?.city !== null ? (
                        <FormInput
                          label="Place Of Incorporation (City) *"
                          name="city"
                          value={values.city}
                          disabled={userData?.city !== null}
                        />
                      ) : (
                        <>
                          <FormSelect
                            label="Place Of Incorporation (City) *"
                            name="city"
                            onChange={(e: any) => {
                              console.log(e.target.value, "e");
                              setFieldValue("city", e.target.value);
                            }}
                            value={values.city}
                            error={errors.city}
                            touched={touched.city}
                            options={cities}
                          />
                        </>
                      )}
                    </div>
                    <div className="w-[48%] mb-8">
                      <FormInput
                        label="Address 1 *"
                        name="address1"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          if (value.length <= 28) {
                            handleChange("address1")(e);
                            setErrorMessage(""); // Clear the error if the length is valid
                          } else {
                            setErrorMessage("Address 1 cannot exceed 30 characters");
                          }
                        }}
                        value={
                          !values.address1?.includes("null")
                            ? values.address1
                            : ""
                        }
                        disabled={userData?.organization?.id}
                        error={errors.address1}
                        touched={touched.address1}
                      />
                    </div>
                    <div className="w-[48%] mb-8">
                      <FormInput
                        label="Address 2 *"
                        name="address2"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          if (value.length <= 28) {
                            handleChange("address2")(e);
                            setErrorMessage2(""); // Clear the error if the length is valid
                          } else {
                            setErrorMessage2("Address 2 cannot exceed 30 characters");
                          }
                        }}
                        value={
                          !values.address2?.includes("null")
                            ? values.address2
                            : ""
                        }
                        disabled={userData?.organization?.id}
                        error={errors.address2}
                        touched={touched.address2}
                      />
                    </div>
                    <div className="w-[48%] mb-8">
                      <FormInput
                        label="Pincode *"
                        name="pincode"
                        onChange={handleChange("pincode")}
                        value={
                          !values.pincode?.includes("null")
                            ? values.pincode
                            : ""
                        }
                        disabled={userData?.organization?.id}
                        error={errors.pincode}
                        touched={touched.pincode}
                      />
                    </div>
                    <div className="w-[48%] mb-8">
                      <FormInput
                        label="Authorised Signatory Name *"
                        name="authSignName"
                        onChange={handleChange("authSignName")}
                        value={values.authSignName}
                        error={errors.authSignName}
                        touched={touched.authSignName}
                        disabled={userData?.organization?.id}
                      />
                    </div>
                    <div className="w-[48%] mb-8">
                      <FormInput
                        label="Designation *"
                        name="designation"
                        onChange={handleChange("designation")}
                        value={values.designation}
                        disabled={userData?.organization?.id}
                        error={errors.designation}
                        touched={touched.designation}
                      />
                    </div>
                    <div className="w-[48%]">
                      <FormInput
                        label="Official Email ID "
                        type="text"
                        name="Email"
                        onChange={(e: any) =>
                          setFieldValue("email", e.target.value)
                        }
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        disabled={userData?.email != ""}
                      />
                    </div>
                    <div className="w-[48%]">
                      <FormInput
                        label="Mobile Number"
                        type="phone"
                        name="mobile"
                        onChange={(e: any) => setFieldValue("mobile", e)}
                        value={values.mobile}
                        error={errors.mobile}
                        touched={touched.mobile}
                        disabled={userData?.mobile != ""}
                      />
                    </div>
                    <div className="w-[48%]"></div>
                  </div>
                  {userData?.organization?.companyName == null ||
                  userData?.organization?.companyName == "" ? (
                    <div className="w-full flex justify-center">
                      <button
                        type="submit"
                        className="form-button"
                        tabIndex={0}
                        onClick={checkAlreadySubmitted}
                        //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                        // Disable button if radio is not selected
                      >
                        Save and Proceed
                      </button>
                    </div>
                  ) : (
                    <div className="w-full flex justify-center">
                      <Button
                        type="button"
                        className="form-button"
                        tabIndex={0}
                        onClick={checkAlreadySubmitted}
                        //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                        // Disable button if radio is not selected
                      >
                        Proceed
                      </Button>
                    </div>
                  )}

                  <ConfirmationModal
                    isVisible={isModalVisible} // Render conditionally based on state
                    message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                    onConfirm={handleModalConfirm} // Confirm action and submit form
                    onCancel={handleModalCancel} // Cancel action and close modal
                  />
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default GeneralDetailsForm;
