import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { colors } from "../../constants/colors";
import { Text } from "../Text";
import { useDispatch } from "react-redux";
import { resetState } from "../../redux/auth/authSlice";
import {
  AccountCircleSharp,
  AddShoppingCartSharp,
  KeyboardArrowDown,
  Logout,
  Notifications,
  NotificationsOutlined,
  PersonAdd,
  PersonOutline,
  Settings,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Sidebar from "../Sidebar/Sidebar";
import authService from "../../redux/auth/authService";
import { useAuth } from "../../hooks/useAuth";
import { getUserById } from "../../redux/user/userSlice";
import { useUserData } from "../../hooks/useUserData";
import { BookmarkBorder } from "@mui/icons-material";
import { Link } from "react-router-dom";
const Header = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isLoggedIn } = useAuth();
  const user = useUserData();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserById({}));
    }
  }, [isLoggedIn]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    authService.logout();
    // dispatch(resetState());
    // navigate("/");
  };
  

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  let componentView;

  const authHeader = (
    <header className="sticky top-0 z-50 px-16 w-full flex flex-row justify-center bg-white items-center text-black shadow-sm max-md:px-5 max-md:max-w-full">
      <div className="flex flex-row justify-center">
      <Link to="/">
          <img
            src={Logo}
            alt="aum_logo"
            className="w-[90px] h-[90px] md:w-[120px] md:h-[120px] mx-auto object-contain"
          />
        </Link>
      </div>
      {isLoggedIn && (
        <div className="absolute top-14 right-14">
          <p
            className="font-inter font-bold text-primary text-lg cursor-pointer"
            onClick={() => authService.logout()}
          >
            Logout
          </p>
        </div>
      )}
    </header>
  );

  const normalHeader = (
    <header className="sticky top-0 z-50 px-16 w-full flex flex-row items-center bg-white text-black shadow-sm max-md:px-5 max-md:max-w-full">
      <div className="md:pr-16">
        <Sidebar />
      </div>
      <div className="w-full flex flex-row justify-between items-center">
      <Link to="/">
          <img
            src={Logo}
            alt="aum_logo"
            className="w-[90px] h-[90px] md:w-[120px] md:h-[120px] mx-auto object-contain"
          />
        </Link>
        <div className="flex gap-x-1 md:gap-x-3 items-center">
          {/* <IconButton sx={{ backgroundColor: colors.primary }}>
            <NotificationsOutlined sx={{ color: colors.white }} />
          </IconButton> */}
          <Link to="/wishlist-fund">
            <IconButton sx={{ backgroundColor: colors.primary }}>
              <BookmarkBorder sx={{ color: colors.white }} />
            </IconButton>
          </Link>
          <Avatar sx={{ backgroundColor: colors.darkGrey }}>
            <PersonOutline sx={{ color: colors.white }} />
          </Avatar>
          <div className="flex items-center">
            <Text as="xs" className="hidden md:block">
              Hello, {user?.firstName}
            </Text>
            <IconButton onClick={handleClick}>
              <KeyboardArrowDown />
            </IconButton>
          </div>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          disableScrollLock={true}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Link to="/profile">
            <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
          </Link>
          <Link to="/orders-history">
            <MenuItem onClick={handleClose}>
              <AssignmentOutlinedIcon style={{ color: 'gray',marginRight:10}}/> Orders
            </MenuItem>
          </Link>
          <Link to="/email-history">
            <MenuItem onClick={handleClose}>
              <MarkEmailReadIcon style={{ color: 'gray',marginRight:10}}/>Fetched Email
            </MenuItem>
          </Link>
          <Link to="/add-members">
            <MenuItem onClick={handleClose}>
              <PeopleAltIcon style={{ color: 'gray',marginRight:10}}/> Family
            </MenuItem>
          </Link>
          {/* <MenuItem onClick={handleClose}>
            <Avatar /> My account
          </MenuItem> */}
          <Divider />
          {/* <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </header>
  );

  if (
    // location.pathname === "/login" ||
    // location.pathname === "/register" ||
    // location.pathname === "/forgot-password" ||
    // location.pathname === "/reset-password"
    user?.onboardStatus === 2 || user?.onboardStatus === 3 || user?.onboardStatus === 4 
  ) {
    componentView = normalHeader;
    <Sidebar />;
  } else {
    componentView = authHeader;
  }

  return <>{componentView}</>;
};

export default Header;
