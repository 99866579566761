import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { colors } from "../../constants/colors";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import FundSelectorModal from "../../components/modals/FundSelectorModal";
import { useDispatch } from "react-redux";
import { getAllMF } from "../../redux/MF/MFSlice";
import toast from "react-hot-toast";
import getInitials from "../../utils/getInitailasIMGUtils";
import DateFormat from "../../utils/dateFormater";
import InvestModal from "../../components/modals/investModal";
import { useUserData } from "../../hooks/useUserData";

interface SelectedFunds {
  [key: number]: string;
}

const AssetComparison: React.FC = () => {
  interface CompareFunds {
    [key: number]: {
      vroRating: string;
      category: string;
      moneyBank: string;
      fundAge: string;
      fundSize: string;
    };
  }
  interface FundData {
    fund: {
      id: number;
      isin: string;
      schemeType: string;
      schemePlan: string;
      schemeName: string;
      purchaseAllowed: string;
      purchaseTransactionMode: string;
      minimumPurchaseAmount: string;
      rtaAgentCode: string;
      sipFlag: string;
      stpFlag: string;
      swpFlag: string;
      switchFlag: string;
      startData: string;
      endDate: string;
      fundSize: string;
      mfRank: string;
      returnRate3Yrs: string;
      currentNAV: number;
      exitLoad: string;
      expenseRatio: string;
      lockInPeriod: string;
      riskLevel: string;
      isInWishlist: number;
    };
    fundData: { fundINFO: any; fundInfoByID: any };
  }
  const user = useUserData();
  const location = useLocation();
  const { fundState } = location.state || {};
  const [assets, setAssets] = useState<{ [key: number]: FundData | undefined }>(
    fundState || { 1: undefined, 2: undefined, 3: undefined }
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [MFRowData, setMFRowData] = useState<FundData[]>();
  const [loading, setLoading] = useState(false);
  const [recentSearches, setRecentSearches] = useState([
    "Fund A",
    "Fund B",
    "Fund C",
    "Fund D",
    "Fund E",
  ]);
  const [isInvestModalOpen, setIsInvestModalOpen] = useState<boolean>(false);
  const [investmentData, setinvestmentData] = useState();
  const dispatch = useDispatch<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [inputData, setInputData] = useState({
    pageIndex: 1,
    pageSize: 100,
    query: "",
    type: [],
    subType: [],
    tenure: "",
  });

  const checkMF = user?.preferences?.mutualFunds;
  console.log(user?.preferences?.mutualFunds, "MF Details page");

  const handleAddFund = (boxNumber: number) => {
    setLoading(true);
    setModalOpen(true);
    setLoading(false);
  };

  const handleInvestNow = (boxNumber: number) => {
    console.log(`Investing in fund ${boxNumber}`);
  };

  // const handleSearch = (query: string) => {
  //     setRecentSearches((prevSearches) => [query, ...prevSearches.filter((search) => search !== query)]);
  //     console.log('Searching for:', query);
  // };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const fundData: CompareFunds = {};

  const handleRemoveFund = (boxNumber: number): void => {
    console.log("Removing fund from box number:", boxNumber);

    setAssets((prevSelected: any) => {
      const newSelected = { ...prevSelected };
      newSelected[boxNumber] = undefined;
      console.log("Updated selected funds:", newSelected);
      return newSelected;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAllMF(inputData));
        if (response.payload.mfList) {
          setMFRowData(response.payload.mfList);
        } else {
          console.error("Error! Try Again later");
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchData();
    // if (inputData.query) { // Only fetch if query is not empty
    //     fetchData();
    //     console.log('input data', inputData.query);

    // }
  }, [dispatch, inputData]);

  const titleSlicer = (title: string) => {
    return title.length > 20 ? title.slice(0, 40) + "..." : title;
  };

  const datefinder = (title: any) => {
    console.log("date", title);
    return title;
  };
  const openInvestModal = (data: any) => {
    setinvestmentData(data);
    setIsInvestModalOpen(true);
  };
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
    // fetchData();
  };
  const formatValue = (value: any) => {
    return isNaN(value) ? "-" : `${Number(value).toFixed(2)}`;
  };

  const getTop5HoldingsByWeight = (fundholdings: any) => {
    if (!fundholdings || typeof fundholdings !== "object") {
      return [];
    }
    const holdingsArray = Object.values(fundholdings);
    const sortedHoldings = holdingsArray.sort(
      (a: any, b: any) => parseFloat(b.Weighting) - parseFloat(a.Weighting)
    );

    const top5Holdings = sortedHoldings.slice(0, 5);
    const top5Names = top5Holdings.map((holding: any) => holding.Name);

    return top5Names.join(", ");
  };

  // useEffect(() => {
  //     const FundStack = Object.values(assets);
  //     console.log('FundStack', FundStack.length);
  // }, [assets])

  const handleSearch = (query: string) => {
    console.log("search term", query);
    setRecentSearches((prevSearches) => [
      query,
      ...prevSearches.filter((search) => search !== query),
    ]);
    setSearchTerm(query); // Update the search term state
    setInputData((prev) => ({ ...prev, query })); // Update inputData with the new query
  };

  return (
    <>
      <div className="bg-white">
        <Box sx={{ backgroundColor: "#F3F8FF", py: 4, px: 2 }}>
          {/* Breadcrumb Section (Top) */}
          <Breadcrumbs separator=">" aria-label="breadcrumb" className="pl-10">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="">
              Asset Comparison
            </Link>
          </Breadcrumbs>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mt: 2, mx: "auto" }}>
            <Typography
              variant="h4"
              className="pl-10"
              sx={{ fontWeight: "300" }}
            >
              Asset Comparison
            </Typography>
          </Box>
        </Box>

        {/* Mutual Fund Comparison Subtitle */}
        <Box sx={{ mt: 2, mx: "auto", py: 1, px: 2 }}>
          <Typography
            variant="h5"
            className="pl-10"
            gutterBottom
            sx={{ fontWeight: "600", color: colors.textGrey }}
          >
            Mutual Fund Comparison
          </Typography>
        </Box>

        {/* Comparison Boxes Section */}
        <Box sx={{ my: 3, px: 2 }}>
          <Grid container spacing={1} justifyContent="flex-end">
            {[1, 2, 3].map((boxNumber) => (
              <Grid item key={boxNumber} xs={6} md={3}>
                <Paper sx={{ p: 2, height: "100%" }}>
                  {/* Placeholder for Fund Image */}
                  <Box
                    sx={{
                      mb: 2,
                      height: 150,
                      backgroundColor: colors.white,
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      border: assets[boxNumber] ? "none" : "2px dashed #999",
                      position: "relative",
                    }}
                  >
                    {assets[boxNumber] ? (
                      <>
                        {/* <img src={assets[boxNumber.fundName]} alt={`Asset ${boxNumber}`} style={{ height: '100%' }} /> */}
                        <span className="flex w-12 h-12 items-center justify-center bg-lightGrey p-4 rounded-[100%] mr-4 text-primary font-semibold">
                          {getInitials(
                            assets[boxNumber]?.fund?.schemeName || ""
                          )}
                        </span>

                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "600", textWrap: "wrap" }}
                        >
                          {titleSlicer(
                            assets[boxNumber]?.fund?.schemeName || ""
                          )}
                        </Typography>
                        <IconButton
                          onClick={() => handleRemoveFund(boxNumber)}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            backgroundColor: "orange",
                            color: "white",
                            width: 24,
                            height: 24,
                            "&:hover": {
                              backgroundColor: "darkorange",
                            },
                          }}
                        >
                          <CloseIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                      </>
                    ) : (
                      <AddIcon sx={{ fontSize: 48, color: "#999" }} />
                    )}
                  </Box>
                  {/* Add Fund/Invest Now Button */}
                  {assets[boxNumber] ? (
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: colors.primary,
                        color: colors.white,
                        border: `2px solid ${colors.primary}`,
                        opacity: checkMF === 1 ? 1 : 0.5,
                        cursor: checkMF === 1 ? "pointer" : "not-allowed",
                        "&:hover": {
                          backgroundColor: colors.white,
                          color: colors.primary,
                          border: `2px solid ${colors.primary}`,
                        },
                      }}
                      fullWidth
                      onClick={
                        checkMF === 1
                          ? () =>
                              openInvestModal(
                                assets[boxNumber]?.fundData?.fundInfoByID
                              )
                          : () => {}
                      }
                    >
                      Invest Now
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: colors.white,
                        color: colors.primary,
                        border: `2px solid ${colors.primary}`,
                        "&:hover": {
                          backgroundColor: colors.white,
                          color: colors.primary,
                          border: `2px solid ${colors.primary}`,
                        },
                      }}
                      fullWidth
                      onClick={() => handleAddFund(boxNumber)}
                    >
                      Add Fund
                    </Button>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
          {loading && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
                color: "white",
              }}
            >
              <CircularProgress />
              <Typography sx={{ mt: 2 }}>Loading assets...</Typography>
            </Box>
          )}
          <FundSelectorModal
            open={isModalOpen}
            onClose={handleCloseModal}
            recentSearches={recentSearches}
            fundData={MFRowData}
            onSearch={handleSearch}
            setSelectedFund={setAssets}
            selectedFund={assets}
          />
        </Box>

        {/* Fund Overview Section */}

        <Box sx={{ backgroundColor: colors.white, width: "100%" }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fund-overview-content"
              id="fund-overview-header"
              sx={{ backgroundColor: colors.lightBg }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: colors.primary, fontWeight: "600", ml: 5 }}
              >
                Fund Overview
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: colors.white, padding: "0" }}
            >
              <TableContainer sx={{ padding: "0" }}>
                <Table>
                  <TableBody>
                    {/* <TableRow>
                                            <TableCell sx={{ fontWeight: '600', width: '20%', paddingLeft: '4%', backgroundColor: 'rgba(255, 249, 239, 1)', color: colors.textGrey }}>VRO Rating</TableCell>
                                            {[1, 2, 3].map((boxNumber) => (
                                                <TableCell align='center' className='border-[1px] border-lightGrey' key={boxNumber} sx={{ width: '20%' }}>{fundData[boxNumber]?.vroRating || '-'}</TableCell>
                                            ))}
                                        </TableRow> */}
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Category
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundInfoByID
                            ?.schemeType || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* <TableRow>
                                            <TableCell sx={{ fontWeight: '600', width: '20%', paddingLeft: '4%', backgroundColor: 'rgba(255, 249, 239, 1)', color: colors.textGrey }}>Money Bank</TableCell>
                                            {[1, 2, 3].map((boxNumber) => (
                                                <TableCell align='center' className='border-[1px] border-lightGrey' key={boxNumber} sx={{ width: '20%' }}>{fundData[boxNumber]?.moneyBank || '-'}</TableCell>
                                            ))}
                                        </TableRow> */}
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Fund Age
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {DateFormat.durationCounter(
                            assets[boxNumber]?.fundData?.fundINFO
                              ?.hitoricalNavs[0]?.d
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Fund Size (in Cr)
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fund?.fundSize || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Returns Section */}
        <Box sx={{ backgroundColor: colors.white, width: "100%" }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fund-overview-content"
              id="fund-overview-header"
              sx={{ backgroundColor: colors.lightBg }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: colors.primary, fontWeight: "600", ml: 5 }}
              >
                Absolute Returns
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: colors.white, padding: "0" }}
            >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        3 Months
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "DP-Return3Mth"
                          ]
                            ? `${Number(
                                assets[boxNumber]?.fundData?.fundINFO?.returns[
                                  "DP-Return3Mth"
                                ]
                              ).toFixed(2)} %`
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        6 Months
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "DP-Return3Mth"
                          ]
                            ? `${Number(
                                assets[boxNumber]?.fundData?.fundINFO?.returns[
                                  "DP-Return6Mth"
                                ]
                              ).toFixed(2)} %`
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        1 Year
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "DP-Return1Yr"
                          ]
                            ? `${Number(
                                assets[boxNumber]?.fundData?.fundINFO?.returns[
                                  "DP-Return1Yr"
                                ]
                              ).toFixed(2)} %`
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        3 Year
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "DP-Return5Yr"
                          ]
                            ? `${Number(
                                assets[boxNumber]?.fundData?.fundINFO?.returns[
                                  "DP-Return3Yr"
                                ]
                              ).toFixed(2)} %`
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        5 Years
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "DP-Return5Yr"
                          ]
                            ? `${Number(
                                assets[boxNumber]?.fundData?.fundINFO?.returns[
                                  "DP-Return5Yr"
                                ]
                              ).toFixed(2)} %`
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Risk Measures Section */}
        <Box sx={{ backgroundColor: colors.white, width: "100%" }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fund-overview-content"
              id="fund-overview-header"
              sx={{ backgroundColor: colors.lightBg }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: colors.primary, fontWeight: "600", ml: 5 }}
              >
                Risk Measures
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: colors.white, padding: "0" }}
            >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Deviation (1 Year)
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundInfoByID?.ratios[0]
                            ?.standardDeviation1Year
                            ? formatValue(
                                assets[boxNumber]?.fundData?.fundInfoByID
                                  ?.ratios[0]?.standardDeviation1Year
                              )
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Sharpe (1 Year)
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundInfoByID?.ratios[0]
                            ?.sharpeRatio1Year
                            ? formatValue(
                                assets[boxNumber]?.fundData?.fundInfoByID
                                  ?.ratios[0]?.sharpeRatio1Year
                              )
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Beta (1 Year)
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundInfoByID?.ratios[0]
                            ?.beta1Year
                            ? formatValue(
                                assets[boxNumber]?.fundData?.fundInfoByID
                                  ?.ratios[0]?.beta1Year
                              )
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Sortino (1 Year)
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundInfoByID?.ratios[0]
                            ?.sortinoRatio1Year
                            ? formatValue(
                                assets[boxNumber]?.fundData?.fundInfoByID
                                  ?.ratios[0]?.sortinoRatio1Year
                              )
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Alpha (1 Year)
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundInfoByID?.ratios[0]
                            ?.alpha1Year
                            ? formatValue(
                                assets[boxNumber]?.fundData?.fundInfoByID
                                  ?.ratios[0]?.alpha1Year
                              )
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Portfolio Section */}
        <Box
          sx={{
            backgroundColor: colors.white,
            width: "100%",
            marginBottom: "2rem",
          }}
        >
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="portfolio-content"
              id="portfolio-header"
              sx={{ backgroundColor: colors.lightBg }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: colors.primary, fontWeight: "600", ml: 5 }}
              >
                Portfolio
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: colors.white, padding: "0" }}
            >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Top 3 Sectors
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {fundData[boxNumber]?.vroRating || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Top 3 Holdings
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets
                            ? getTop5HoldingsByWeight(
                                assets[boxNumber]?.fundData?.fundINFO
                                  .fundholdings
                              )
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        P/E Ratio
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "PSRP-PBRatioTTMLong"
                          ] || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        P/B Ratio
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "PSRP-PERatioTTMLong"
                          ] || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Equity
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {fundData[boxNumber]?.vroRating || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Debt
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {fundData[boxNumber]?.vroRating || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          width: "20%",
                          paddingLeft: "4%",
                          backgroundColor: "rgba(255, 249, 239, 1)",
                          color: colors.textGrey,
                        }}
                      >
                        Yield to Maturity
                      </TableCell>
                      {[1, 2, 3].map((boxNumber) => (
                        <TableCell
                          align="center"
                          className="border-[1px] border-lightGrey"
                          key={boxNumber}
                          sx={{ width: "20%" }}
                        >
                          {assets[boxNumber]?.fundData?.fundINFO?.returns[
                            "PSRP-YieldtoMaturityLong"
                          ] || "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* <div>
                    <h1>Asset Comparison</h1>
                    <pre>{JSON.stringify(assets, null, 2)}</pre>
                </div> */}
      </div>
      {investmentData && (
        <InvestModal
          open={isInvestModalOpen}
          onClose={closeInvestModal}
          fundInfo={investmentData}
        />
      )}
    </>
  );
};

export default AssetComparison;
