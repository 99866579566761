import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Fade,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { InfoOutlined, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { colors } from "../../constants/colors";
import { Dots } from "react-activity";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import SortingComponent from "../ShortingFN/ShortingComponent";
import { useUserData } from "../../hooks/useUserData";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Row = ({
  row,
  navigateDetails,
  setIsinWishilist,
  isinWishilist,
  openInvestModal,
  selectedFunds,
  SelectedAssets,
}: {
  row: any;
  navigateDetails: (isin: string, id: number) => void;
  isinWishilist: any;
  setIsinWishilist: any;
  openInvestModal: any;
  fetchData: () => void;
  selectedFunds: any;
  SelectedAssets: any;
}) => {
  const labelId = `enhanced-table-checkbox-${row.id}`;
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);

  const openMenuMD = Boolean(anchorMD);

  const modalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    isinWishilist: number,
    id: number
  ) => {
    setAnchorMD(event.currentTarget);
    let data = { status: isinWishilist, id: id };
    setIsinWishilist(data);
  };

  const dispatch = useDispatch<any>();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const isPopoverOpen = Boolean(popoverAnchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchorEl(event.currentTarget); // Correct event type
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null); // Reset
  };

  // Function to get the truncated text
  const truncateText = (text: string | any[], length: number) => {
    if (text.length > length) {
      return `${text.slice(0, length)}...`;
    }
    return text;
  };

  const Watchlist = async () => {
    if (!isinWishilist) return;

    if (isinWishilist.status === 1) {
      const data = { itemId: isinWishilist.id, itemType: 2 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 0 });
        toast.success("Removed from watchlist");
      } else {
        toast.error("Failed to remove from watchlist");
      }
    } else if (isinWishilist.status === 0) {
      const data = { itemId: isinWishilist.id, itemType: 2 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 1 });
        toast.success("Added to watchlist");
      } else {
        toast.error("Failed to add to watchlist");
      }
    }
  };

  const StringRemover = (title: string) => {
    let titleLength = title.length;
    return titleLength > 6 ? title.slice(0, titleLength - 6) : title;
  };

  const isSelected = (fundId: any) => {
    const selected = Object.values(SelectedAssets).find(
      (f: any) => f?.fund?.id === fundId
    );
    return !!selected;
  };
  const user = useUserData();
  const checkMF = user?.preferences?.mutualFunds;
  console.log(user?.preferences?.mutualFunds, "MF Details page");

  return (
    <>
      <TableRow
        key={row.id}
        sx={{ borderRadius: "50px!important" }}
        className=" border-[1px] border-lightGrey hover:border-primary hover:border-[1px] hover:bg-lightBg cursor-pointer" 
      >
        <TableCell padding="checkbox" sx={{ border: "none",fontSize:{
                    xs:"14px",
                    sm:"16px"
                  } }}>
          <Checkbox
            color="primary"
            checked={isSelected(row.id)}
            onChange={() => selectedFunds(row)}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }}>
                    <a
            href={`/mf-details/${row.isin}/${row.id}`}
            target="_self"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {toCamelCase(row.schemeName)}
          </a>
          {/* <div className="flex items-center gap-x-2 cursor-pointer" onClick={() => navigateDetails(row.isin, row.id)}>
            <span>
              {row.schemeName}
            </span>
          </div> */}
        </TableCell>
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {StringRemover(row.fundSize)}
        </TableCell>
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          Rs. {formatIndianCurrency(row.currentNAV)}
        </TableCell>
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {row.returnRate1Yrs
            ? `${Number(row.returnRate1Yrs).toFixed(2)}  % `
            : "-"}
        </TableCell>
        <TableCell align="center" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {row.expenseRatio ? `${Number(row.expenseRatio).toFixed(2)} %` : "-"}
        </TableCell>

        <TableCell align="right" sx={{ minWidth: 200, border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }}>
          <Tooltip title="" arrow>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100px",
              }}
            >
              {truncateText(row.exitLoad, 15)}
            </span>
          </Tooltip>

          <IconButton
            onClick={handlePopoverOpen}
            size="small"
            sx={{ padding: "0 5px" }}
          >
            <InfoOutlined fontSize="small" />
          </IconButton>

          <Popover
            open={isPopoverOpen}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            disableScrollLock={true}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <Box p={2} sx={{ maxWidth: 300 }}>
              {row.exitLoad}
            </Box>
          </Popover>
        </TableCell>

        {/* <TableCell align="center" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {row.mfRank}
        </TableCell> */}
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }}>
          {row.riskLevel}
        </TableCell>
        <TableCell align="right" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }}>
          <IconButton
            onClick={(e) => {
              modalMenuClick(e, row?.isInWishlist, row?.id);
            }}
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableCell
        className="tableCells"
        colSpan={12}
        sx={{
          padding: "5px",
          "&.MuiTableCell-root": {
            borderBottom: "none",
          },
        }}
      ></TableCell>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: "180px",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem button onClick={handleClose} sx={{ marginLeft: "-25px" }}>
            <MenuItem className="text-xs sm:text-sm" onClick={Watchlist}>
              {isinWishilist?.status === 1
                ? "Remove from watchlist"
                : "Add to Watchlist"}
            </MenuItem>
          </ListItem>
          <Divider
            sx={{
              backgroundColor: "#fff",
            }}
          />
          <ListItem
            button
            sx={{ marginLeft: "-25px",opacity: checkMF === 1 ? 1 : 0.5,
              cursor: checkMF === 1 ? "pointer" : "not-allowed", }}
              onClick={checkMF === 1 ? openInvestModal : () => {}}
          >
            <MenuItem sx={{opacity: checkMF === 1 ? 1 : 0.5,
              cursor: checkMF === 1 ? "pointer" : "not-allowed"}}>Invest</MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
    </>
  );
};

const MFTable = ({
  MFData,
  loader,
  openInvestModal,
  setIsinWishilist,
  isinWishilist,
  findMutualFund,
  selectedFunds,
  SelectedAssets,
}: {
  MFData: any[];
  loader: boolean;
  openInvestModal: any;
  setIsinWishilist: any;
  isinWishilist: any;
  findMutualFund: any;
  selectedFunds: any;
  SelectedAssets: any;
}) => {
    const [sortConfig, setSortConfig] = useState<{
      key: string;
      direction: string | null;
    }>({
      key: "schemeName",  // Set the default key to "assetName" or any other column you want
      direction: "ascending",  // Set the default direction to ascending
    });
  
  
    const getSortedData = useCallback(() => {
      if (!sortConfig.key) return MFData;
      const sortedData = [...MFData];
    
      const compareStrings = (a: string, b: string) => {
        if (a < b) return sortConfig.direction === "ascending" ? -1 : 1;
        if (a > b) return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      };
    
      const compareNumbers = (a: any, b: any) => {
        // Convert both to floats
        a = parseFloat(a?.toString());
        b = parseFloat(b?.toString());
        
        return sortConfig.direction === "ascending" ? a - b : b - a;
      };
    
      const compareDates = (a: string, b: string) => {
        if (!a || !b) return 0; // You can handle this differently based on requirements
        const [dayA, monthA, yearA] = a.split("-").map(Number);
        const [dayB, monthB, yearB] = b.split("-").map(Number);
    
        if (
          isNaN(dayA) ||
          isNaN(monthA) ||
          isNaN(yearA) ||
          isNaN(dayB) ||
          isNaN(monthB) ||
          isNaN(yearB)
        ) {
          return 0;
        }
    
        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);
    
        return sortConfig.direction === "ascending"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      };
    
      sortedData.sort((a, b) => {
        const schemeA = a.schemes?.[0] || {};
        const schemeB = b.schemes?.[0] || {};
    
        switch (sortConfig.key) {
          case "schemeName":
            return compareStrings(a?.schemeName.toUpperCase(), b?.schemeName.toUpperCase());
          case "inceptionDate":
            return compareDates(schemeA.inceptionDate, schemeB.inceptionDate);
          case "fundSize":
            return compareStrings(a.fundSize.toUpperCase(), b.fundSize.toUpperCase());
          case "currentNAV":
            return compareNumbers(a.currentNAV, b.currentNAV); // Directly access currentNAV
          case "returnRate1Yrs":
            return compareNumbers(a.returnRate1Yrs, b.returnRate1Yrs); // Directly access returnRate1Yrs
          case "expenseRatio":
            return compareStrings(a.expenseRatio.toUpperCase(), b.expenseRatio.toUpperCase());
          case "riskLevel":
            return compareStrings(a.riskLevel.toUpperCase(), b.riskLevel.toUpperCase());
          default:
            return 0;
        }
      });
    
      return sortedData;
    }, [MFData, sortConfig]);
    
    
  
    const handleSort = (key: string) => {
      let direction = "ascending";
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };
  
    const sortedData = getSortedData();

  const navigate = useNavigate();

  const navigateDetails = (isin: string, id: number) => {
    navigate(`/mf-details/${isin}/${id}`);
  };
  if (loader) {
    return <div>Loading...</div>; // Display loading state while fetching data
  }

  if (MFData.length === 0) {
    return <div>No data available</div>;
  }
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", border: "none" }}
      >
        <Table aria-label="collapsible table">
          <TableHead
            sx={{
              backgroundColor: colors.lightBg,
              marginBottom: "50px",
              border: "none",
            }}
          >
            <TableRow sx={{ border: "none" }}>
              <TableCell
                align="left"
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
              ></TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
              >
                <div className={`flex items-center w-auto justify-start`}>
                  <>Asset Name</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("schemeName")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("schemeName")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Month End AUM (in Cr)</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("fundSize")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("fundSize")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Current NAV</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("currentNAV")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("currentNAV")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>CAGR (1Y)</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("returnRate1Yrs")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("returnRate1Yrs")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Expense Ratio</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("expenseRatio")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("expenseRatio")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="center"
              >
                Exit Load
              </TableCell>
              {/* <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                MF Rank
              </TableCell> */}
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Risk Level</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("riskLevel")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("riskLevel")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex flex-col justify-center items-center">
                <Dots />
              </div>
            </div>
          ) : (
            <TableBody className="" sx={{}}>
              <TableRow sx={{ height: "5%", border: "none" }}>
                <TableCell
                  colSpan={8}
                  align="center"
                  sx={{ border: "none" }}
                ></TableCell>
              </TableRow>

              {(sortedData && sortedData?.length > 0) ? (
                sortedData?.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    navigateDetails={navigateDetails}
                    setIsinWishilist={setIsinWishilist}
                    isinWishilist={isinWishilist}
                    openInvestModal={openInvestModal}
                    fetchData={findMutualFund}
                    selectedFunds={selectedFunds}
                    SelectedAssets={SelectedAssets}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No similar plans available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default MFTable;
